const initialDamagedFieldValues = {
    mepar: '',
    area: '',
    plants: '',
};

const initialNewDamageReportValues = {
    damageNumber: '',
    policyNumber: '',
    damageDate: new Date(),
    area: '',
    dangerType: '',
    inspectionAddress: '',
    inspectionAddressZip: '',
    expertId: '',
    blocks: [initialDamagedFieldValues],
};

export { initialDamagedFieldValues, initialNewDamageReportValues };
