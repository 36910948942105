// eslint-disable-next-line import/prefer-default-export
export const MAP_LAYER_NAMES = {
    OSM: 'OSM',
    BING: 'BING',
    FROST: 'FROST',
    MEPAR: 'MEPAR',
    FIELDS: 'FIELDS',
    CROP: 'CROP',
    ZONE: 'ZONE',
    SAMPLING: 'SAMPLING',
    DAMAGE_CHRONOLOGY: 'DAMAGE_CHRONOLOGY',
    FROST_DAMAGE_CHRONOLOGY: 'FROST_DAMAGE_CHRONOLOGY',
    MONITORING: 'MONITORING',
    DAMAGE_MAP: 'DAMAGE_MAP',
    CHANGE_MAP: 'CHANGE_MAP',
    DEEP_MAP: 'DEEP_MAP',
    KARNAVI: 'KARNAVI',
    RADAR: 'RADAR',
    RADAR_MEPAR: 'RADAR_MEPAR',
    YIELD_MAP: 'YIELD_MAP',
    NDVI: 'NDVI',
    FROST_RISK: 'FROST_RISK',
    CITY: 'CITY',
};
