import { Grid } from '@mui/material';
import clsx from 'clsx';
import { useMap } from 'es-map-widget';
import React, { useCallback, useState } from 'react';
import { CONTROLLER_KEYS, CONTROLLER_LABELS, MAP_LAYER_NAMES } from '../../../constants';
import { useLayout } from '../../../providers/LayoutContext';
import DamageChronologyController from '../components/DamageChronologyController';
import FrostDataController from '../components/FrostDataController';
import { useMenu } from '../contexts/MenuContext';
import MapControllerMenuItem from '../menu/MapControllerMenuItem';
import SubMenuWrapper from '../menu/SubMenuWrapper';

const allFrostItems = [
    CONTROLLER_KEYS.FROST_DAMAGE_LEVEL,
    CONTROLLER_KEYS.DEEP_MAP,
    CONTROLLER_KEYS.FROST_RISK,
];

const getItemsState = (localState, key) => {
    if (!localState[key] || localState[key].length === 0) {
        return [];
    }
    return localState[key];
};

const RiskFactorController = () => {
    const map = useMap();
    const { checkedItems, handleItemCheck } = useMenu();
    const { matchesLgUp } = useLayout();
    const [selectedOption, setSelectedOption] = useState(CONTROLLER_KEYS.FROST);
    const [selectedFrostItems, setSelectedFrostItemsInternal] = useState(
        getItemsState(checkedItems, CONTROLLER_KEYS.FROST)
    );
    const [selectedChronologyItems, setSelectedChronologyItemsInternal] = useState(
        getItemsState(checkedItems, CONTROLLER_KEYS.DAMAGE_CHRONOLOGY)
    );

    const setSelectedFrostItems = useCallback(
        (newItems) => {
            map.setLayerData(MAP_LAYER_NAMES.FROST, {
                selectedCrops: newItems,
            });
            map.setLayerVisibility(
                MAP_LAYER_NAMES.FROST,
                newItems.includes(CONTROLLER_KEYS.FROST_DAMAGE_LEVEL)
            );
            map.setLayerVisibility(
                MAP_LAYER_NAMES.DEEP_MAP,
                newItems.includes(CONTROLLER_KEYS.DEEP_MAP)
            );
            map.setLayerVisibility(
                MAP_LAYER_NAMES.FROST_RISK,
                newItems.includes(CONTROLLER_KEYS.FROST_RISK)
            );
            map.setLayerData(MAP_LAYER_NAMES.FROST_RISK, {
                isLayerVisible: newItems.includes(CONTROLLER_KEYS.FROST_RISK),
            });
            setSelectedFrostItemsInternal(newItems);
        },
        [setSelectedFrostItemsInternal]
    );
    const setSelectedChronologyItems = useCallback(
        (newItems) => {
            const isDamageChronologyVisible = newItems.includes(
                CONTROLLER_KEYS.DAMAGE_CHRONOLOGY_SUM
            );
            const isFrostDamageChronologyVisible = newItems.includes(
                CONTROLLER_KEYS.FROST_DAMAGE_CHRONOLOGY
            );

            map.setLayerData(MAP_LAYER_NAMES.DAMAGE_CHRONOLOGY, {
                isLayerVisible: isDamageChronologyVisible,
            });
            map.setLayerVisibility(MAP_LAYER_NAMES.DAMAGE_CHRONOLOGY, isDamageChronologyVisible);
            map.setLayerData(MAP_LAYER_NAMES.FROST_DAMAGE_CHRONOLOGY, {
                isLayerVisible: isFrostDamageChronologyVisible,
            });
            map.setLayerVisibility(
                MAP_LAYER_NAMES.FROST_DAMAGE_CHRONOLOGY,
                isFrostDamageChronologyVisible
            );
            setSelectedChronologyItemsInternal(newItems);
        },
        [setSelectedChronologyItemsInternal]
    );

    return (
        <Grid container>
            <Grid
                item
                xs={12}
                lg={6}
                className={clsx('dropdown-level-1', !matchesLgUp && 'mobile')}
            >
                <MapControllerMenuItem
                    isActive={selectedOption === CONTROLLER_KEYS.FROST}
                    onClick={() => setSelectedOption(CONTROLLER_KEYS.FROST)}
                    text={CONTROLLER_LABELS.FROST}
                    checkbox={{
                        checked: selectedFrostItems.length > 0,
                        onChange: (event) => {
                            const isChecked = event.currentTarget.checked;
                            if (isChecked) {
                                setSelectedFrostItems(allFrostItems);
                                allFrostItems
                                    .filter((e) => !checkedItems[CONTROLLER_KEYS.FROST].includes(e))
                                    .forEach((c) => handleItemCheck(c, CONTROLLER_KEYS.FROST));
                                setSelectedOption(CONTROLLER_KEYS.FROST);
                            } else {
                                checkedItems[CONTROLLER_KEYS.FROST].forEach((c) =>
                                    handleItemCheck(c, CONTROLLER_KEYS.FROST)
                                );
                                setSelectedFrostItems([]);
                            }
                        },
                    }}
                    hasSubMenu
                />
                <MapControllerMenuItem
                    isActive={selectedOption === CONTROLLER_KEYS.DAMAGE_CHRONOLOGY}
                    onClick={() => setSelectedOption(CONTROLLER_KEYS.DAMAGE_CHRONOLOGY)}
                    text={CONTROLLER_LABELS.DAMAGE_CHRONOLOGY}
                    hasSubMenu
                />
            </Grid>
            <Grid
                item
                xs={12}
                lg={6}
                className={clsx('dropdown-level-2', !matchesLgUp && 'mobile')}
            >
                <SubMenuWrapper isVisible={selectedOption === CONTROLLER_KEYS.FROST}>
                    <FrostDataController
                        selectedItems={selectedFrostItems}
                        setSelectedItems={setSelectedFrostItems}
                    />
                </SubMenuWrapper>
                <SubMenuWrapper isVisible={selectedOption === CONTROLLER_KEYS.DAMAGE_CHRONOLOGY}>
                    <DamageChronologyController
                        selectedItems={selectedChronologyItems}
                        setSelectedItems={setSelectedChronologyItems}
                    />
                </SubMenuWrapper>
            </Grid>
        </Grid>
    );
};

export default RiskFactorController;
