import { DeleteRounded } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useMap } from 'es-map-widget';
import React from 'react';
import { DRAWING_STATES, OVERVIEW_STATES, TOOLTIP_TEXTS } from '../../../constants';
import { useAppControl } from '../../../providers/AppContext';

const PointDeleteButton = ({ disabled }) => {
    const map = useMap();
    const { setDrawingState, setOverviewState, samplingData } = useAppControl();

    const handleClick = () => {
        setDrawingState(DRAWING_STATES.POINT_DELETE);
        setOverviewState(OVERVIEW_STATES.HIDDEN);
        map.drawDelete(samplingData.points);
    };

    return (
        <Tooltip title={TOOLTIP_TEXTS.delete} placement="right">
            <span>
                <IconButton onClick={handleClick} disabled={disabled}>
                    <DeleteRounded />
                </IconButton>
            </span>
        </Tooltip>
    );
};

export default PointDeleteButton;
