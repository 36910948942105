import { SaveRounded } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useMap } from 'es-map-widget';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
    DRAWING_STATES,
    MAP_LAYER_NAMES,
    OVERVIEW_STATES,
    TOOLTIP_TEXTS,
} from '../../../constants';
import { api } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import { useAppControl } from '../../../providers/AppContext';

const PointSaveButton = () => {
    const map = useMap();
    const { drawingState, setDrawingState, setOverviewState, setSamplingData } = useAppControl();
    const { notifySuccess, notifyError } = useNotify();
    const [searchParams] = useSearchParams();
    const damagePlotId = searchParams.get('damagePlotId');

    const handleClick = () => {
        setOverviewState(OVERVIEW_STATES.OPEN);
        const points = map.drawPointsSave();

        let lastId = points.reduce(
            (max, currentPoint) =>
                Math.max(
                    max,
                    parseInt(currentPoint.getGeometry().getProperties().sequenceId, 10) || 0
                ),
            0
        );

        const newPoints = points.map((p) => {
            const props = { ...p.getGeometry().getProperties() };
            const point = {
                sequenceId: props.sequenceId || lastId + 1,
                geom: {
                    type: 'Point',
                    coordinates: p.getGeometry().getCoordinates(),
                },
                isGenerated: props.isGenerated || true,
                isUsed: props.isUsed || false,
                comment: props.comment || '',
                yield: parseInt(props.yield, 10) || null,
                name: props.name || '',
                sampledAt: props.sampledAt || new Date(),
            };

            if (!props.sequenceId) {
                lastId += 1;
            }

            return point;
        });

        api.damagePlots
            .saveSampling({
                damagePlotId: parseInt(damagePlotId, 10),
                sampling: newPoints,
            })
            .then((res) => {
                notifySuccess('Sikeres mentés');
                setDrawingState(DRAWING_STATES.POINT_SETUP);
                map.setLayerVisibility(MAP_LAYER_NAMES.SAMPLING, true);
                map.setLayerData(MAP_LAYER_NAMES.SAMPLING, {
                    geoms: res.data,
                });
                setSamplingData((p) => ({
                    ...p,
                    points: res.data,
                }));
                map.drawCancel();
            })
            .catch((err) => {
                notifyError(err);
                setSamplingData({
                    points: [],
                    zones: [],
                });
                console.log(err);
            });
    };
    return (
        <Tooltip title={TOOLTIP_TEXTS.save} placement="right">
            <span>
                <IconButton
                    onClick={handleClick}
                    disabled={drawingState === DRAWING_STATES.POINT_YIELD}
                >
                    <SaveRounded />
                </IconButton>
            </span>
        </Tooltip>
    );
};

export default PointSaveButton;
