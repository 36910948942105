import { Fill, Stroke, Style } from 'es-map-widget';
import { useEffect } from 'react';
import { ZONE_CATEGORY_COLORS } from '../../constants';

export default function ZoneDataprovider({ extent, zoom, layerData, setSource }) {
    const addStyleToZones = (zonesWithoutStyle) =>
        zonesWithoutStyle.map((e) => ({
            ...e,
            style: new Style({
                fill: new Fill({
                    color: ZONE_CATEGORY_COLORS[e.category],
                }),
                stroke: new Stroke({
                    color: [0, 0, 0, 0],
                    width: 1,
                }),
            }),
        }));

    useEffect(() => {
        if (!layerData.geoms || layerData.geoms.length === 0) {
            setSource(null);
            return;
        }

        const controller = new AbortController();

        setSource(addStyleToZones(layerData.geoms));

        // eslint-disable-next-line consistent-return
        return () => {
            controller.abort();
        };
    }, [extent, layerData, addStyleToZones, setSource, zoom]);

    return null;
}
