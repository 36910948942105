import { format } from 'date-fns';
import { Fill, Stroke, Style } from 'es-map-widget';
import { useCallback, useEffect } from 'react';
import { api } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';

export default function RadarDataprovider({ extent, layerData, setSource }) {
    const { notifyError } = useNotify();

    const getData = useCallback(
        (controller, params) =>
            api.layers.getRadarLayer(params, { signal: controller.signal }).then((res) => res.data),
        [api]
    );
    const addStyle = (geoms) =>
        geoms.map((e) => ({
            ...e,
            style: new Style({
                stroke: new Stroke({
                    color: '#ff0000',
                    width: 3,
                }),
                fill: new Fill({
                    color: [255, 0, 0, 0.25],
                }),
            }),
        }));

    useEffect(() => {
        if (!layerData.isLayerVisible || !layerData.date) {
            setSource(null);
            return;
        }
        const controller = new AbortController();

        getData(controller, {
            extent,
            radarDate: format(new Date(layerData.date), 'yyyy-MM-dd'),
        })
            .then((res) => setSource(addStyle(res)))
            .catch((err) => {
                if (err.code !== 'ERR_CANCELED') {
                    notifyError(err);
                }
            });

        // eslint-disable-next-line consistent-return
        return () => {
            controller.abort();
        };
    }, [getData, layerData, extent, setSource, addStyle]);
    return null;
}
