import * as Yup from 'yup';

const reportValidationSchema = Yup.object({
    damageNumber: Yup.string().required('Kötelező'),
    policyNumber: Yup.string().required('Kötelező'),
    damageDate: Yup.date().required('Kötelező'),
    area: Yup.string().required('Kötelező'),
    dangerType: Yup.string().required('Kötelező'),
    inspectionAddress: Yup.string().required('Kötelező'),
    inspectionAddressZip: Yup.number()
        .typeError('Csak számokat tartalmazhat')
        .required('Kötelező')
        .positive()
        .integer(),
    blocks: Yup.array().of(
        Yup.object().shape({
            mepar: Yup.string().required('Kötelező'),
            area: Yup.number()
                .typeError('Csak számokat tartalmazhat')
                .required('Kötelező')
                .positive(),
            plants: Yup.string().required('Kötelező'),
        })
    ),
});

// eslint-disable-next-line import/prefer-default-export
export { reportValidationSchema };
