import { Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { StaticDatePicker } from '@mui/x-date-pickers';
import { format, getDate, getMonth } from 'date-fns';
import { useMap } from 'es-map-widget';
import React, { useCallback, useEffect, useState } from 'react';
import { BASE_COLORS } from '../../../constants';
import { MAP_LAYER_NAMES } from '../../../constants/mapLayerNames';
import { api as API, useApi } from '../../../hooks/useApi';
import { useAppControl } from '../../../providers/AppContext';
import { useRadarDates } from '../contexts/RadarDateProvider';
import MapControllerMenuItem from '../menu/MapControllerMenuItem';
import useNotify from '../../../hooks/useNotify';

const RadarDataController = ({ isLayerVisible }) => {
    const map = useMap();
    const { year } = useAppControl();
    const { notifyError } = useNotify();
    const { radarMonth, setRadarMonth, radarData, setRadarData } = useRadarDates();
    const [minDate, setMinDate] = useState(new Date(year, 0, 1));
    const [maxDate, setMaxDate] = useState(new Date(year, 11, 31));
    const [isMeparLayerVisible, setIsMeparLayerVisible] = useState(radarData.isMeparLayerVisible);
    const [countLoading, setCountLoading] = useState(false);

    const countRadarGeoms = async () => {
        setCountLoading(true);
        API.layers
            .getMeparCountForRadarDay({
                radarDate: format(radarData.date, 'yyyy-MM-dd'),
                year,
            })
            .then((res) => {
                setRadarData((prevValue) => ({
                    ...prevValue,
                    count: res.data.count,
                }));
                setCountLoading(false);
            })
            .catch((err) => {
                notifyError(err);
                setCountLoading(false);
                setRadarData((prevValue) => ({
                    ...prevValue,
                    count: null,
                }));
            });
    };

    const setFieldRadarData = useCallback(
        (newValue) => {
            map.setLayerData(MAP_LAYER_NAMES.RADAR, {
                isLayerVisible,
                date: newValue.date,
            });
            map.setLayerData(MAP_LAYER_NAMES.RADAR_MEPAR, {
                date: newValue.date,
            });
            setRadarData({
                isLayerVisible,
                isMeparLayerVisible: newValue.isMeparLayerVisible,
                date: newValue.date,
                count: null,
            });
        },
        [map, isLayerVisible, setRadarData]
    );

    const [dates, loading, error] = useApi(
        (api, params) =>
            api.layers.getRadarDates(
                {
                    year,
                    month: radarMonth,
                },
                { ...params }
            ),
        [year, radarMonth, isLayerVisible],
        (x) => x,
        !isLayerVisible
    );

    const createDate = useCallback(
        (date) => {
            return new Date(
                year,
                getMonth(date) || radarMonth,
                date?.getDate() || radarData.date.getDate()
            );
        },
        [radarMonth, radarData.date, year]
    );

    useEffect(() => {
        setMinDate(new Date(year, 0, 1));
        setMaxDate(new Date(year, 11, 31));
        setRadarData((prevData) => ({
            ...prevData,
            date: new Date(year, getMonth(prevData.date), getDate(prevData.date)),
        }));
    }, [setRadarData, year, setMinDate, setMaxDate]);

    return (
        <Grid container alignItems="flex-start">
            <Grid item sx={{ mb: 1 }}>
                <MapControllerMenuItem
                    text="MePAR-ok megjelenítése"
                    checkbox={{
                        checked: isMeparLayerVisible,
                        onChange: () => {
                            setIsMeparLayerVisible((p) => !p);
                            map.setLayerVisibility(
                                MAP_LAYER_NAMES.RADAR_MEPAR,
                                !isMeparLayerVisible
                            );
                            map.setLayerData(MAP_LAYER_NAMES.RADAR_MEPAR, {
                                isLayerVisible: !isMeparLayerVisible,
                                date: createDate(radarData.date),
                            });
                            setRadarData((prevData) => ({
                                ...prevData,
                                isMeparLayerVisible: !isMeparLayerVisible,
                            }));
                        },
                        disabled: !isLayerVisible,
                    }}
                />
            </Grid>
            <Grid item container justifyContent="center">
                <StaticDatePicker
                    views={['day']}
                    inputFormat="yyyy-MM-dd"
                    displayStaticWrapperAs="desktop"
                    disableFuture
                    minDate={minDate}
                    maxDate={maxDate}
                    value={radarData.date}
                    loading={loading && !error}
                    onChange={(date) => {
                        setFieldRadarData({
                            ...radarData,
                            date,
                        });
                    }}
                    shouldDisableDate={(date) =>
                        !(dates || []).includes(format(createDate(date), 'yyyy-MM-dd'))
                    }
                    onMonthChange={(m) => setRadarMonth(getMonth(m))}
                    renderInput={(params) => <TextField {...params} />}
                    sx={{
                        backgroundColor: BASE_COLORS.PRIMARY_MAIN,
                    }}
                    disabled={!isLayerVisible}
                />
            </Grid>
            <Grid item container justifyContent="flex-start">
                {radarData.count ? (
                    <Typography variant="body1" sx={{ fontSize: '14px' }}>
                        Érintett blokkok: {radarData.count}
                    </Typography>
                ) : (
                    <Button
                        variant="outlined"
                        size="small"
                        sx={{
                            border: '1px solid rgba(255, 255, 255, 0.85)',
                            color: 'rgba(255, 255, 255, 0.85)',
                            '&:hover': {
                                border: '1px solid white',
                                color: 'white',
                            },
                        }}
                        onClick={() => countRadarGeoms()}
                        fullWidth
                        disabled={!(dates || []).includes(format(radarData.date, 'yyyy-MM-dd'))}
                    >
                        {countLoading ? (
                            <CircularProgress color="secondary" size={30} sx={{ m: 1 }} />
                        ) : (
                            'Érintett blokkok számítása'
                        )}
                    </Button>
                )}
            </Grid>
        </Grid>
    );
};

export default RadarDataController;
