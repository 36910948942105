import { Grid } from '@mui/material';
import clsx from 'clsx';
import { useMap } from 'es-map-widget';
import React, { useCallback, useState } from 'react';
import { CONTROLLER_KEYS, CONTROLLER_LABELS, crops, MAP_LAYER_NAMES } from '../../../constants';
import { useLayout } from '../../../providers/LayoutContext';
import CropDataController from '../components/CropDataController';
import { useMenu } from '../contexts/MenuContext';
import MapControllerMenuItem from '../menu/MapControllerMenuItem';
import SubMenuWrapper from '../menu/SubMenuWrapper';

const allCrops = [
    CONTROLLER_KEYS.SPIKE,
    CONTROLLER_KEYS.CORN,
    CONTROLLER_KEYS.COLE_SEED,
    CONTROLLER_KEYS.SUNFLOWER,
];

const getCropState = (localState) => {
    if (!localState[CONTROLLER_KEYS.CROP] || localState[CONTROLLER_KEYS.CROP].length === 0) {
        return [];
    }
    return localState[CONTROLLER_KEYS.CROP];
};

const PlantController = () => {
    const map = useMap();
    const { checkedItems, handleItemCheck } = useMenu();
    const { matchesLgUp } = useLayout();
    const [selectedOption, setSelectedOption] = useState(CONTROLLER_KEYS.CROP);
    const [selectedCrops, setSelectedCropsInternal] = useState(getCropState(checkedItems));

    const setSelectedCrops = useCallback(
        (newCrops) => {
            map.setLayerData(MAP_LAYER_NAMES.CROP, {
                isLayerVisible: newCrops.length > 0,
                selectedCrops: newCrops.map((e) => crops[e]),
            });
            map.setLayerVisibility(MAP_LAYER_NAMES.CROP, newCrops.length > 0);
            setSelectedCropsInternal(newCrops);
        },
        [setSelectedCropsInternal, map, crops]
    );
    return (
        <Grid container>
            <Grid
                item
                xs={12}
                lg={6}
                className={clsx('dropdown-level-1', !matchesLgUp && 'mobile')}
            >
                <MapControllerMenuItem
                    isActive={selectedOption === CONTROLLER_KEYS.CROP}
                    onClick={() => setSelectedOption(CONTROLLER_KEYS.CROP)}
                    text={CONTROLLER_LABELS.CROP}
                    checkbox={{
                        checked: selectedCrops.length === allCrops.length,
                        indeterminate: selectedCrops.length > 0 && selectedCrops.length < 4,
                        onChange: (event) => {
                            const isChecked = event.currentTarget.checked;
                            if (isChecked) {
                                setSelectedCrops(allCrops);
                                allCrops
                                    .filter((e) => !checkedItems[CONTROLLER_KEYS.CROP].includes(e))
                                    .forEach((c) => handleItemCheck(c, CONTROLLER_KEYS.CROP));
                            } else {
                                checkedItems[CONTROLLER_KEYS.CROP].forEach((c) =>
                                    handleItemCheck(c, CONTROLLER_KEYS.CROP)
                                );
                                setSelectedCrops([]);
                            }
                        },
                    }}
                    hasSubMenu
                />
            </Grid>
            <Grid
                item
                xs={12}
                lg={6}
                className={clsx('dropdown-level-2', !matchesLgUp && 'mobile')}
            >
                <SubMenuWrapper isVisible={selectedOption === CONTROLLER_KEYS.CROP}>
                    <CropDataController
                        selectedCrops={selectedCrops}
                        setSelectedCrops={setSelectedCrops}
                    />
                </SubMenuWrapper>
            </Grid>
        </Grid>
    );
};

export default PlantController;
