import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MapContextProvider } from 'es-map-widget';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { WmsDateContextProvider } from '../components/mapcontroller/contexts/WmsDateProvider';
import { MAP_OPTIONS } from '../constants';
import { ModalContextProvider } from '../hooks/useModal';
import theme from '../theme';
import { AdminContextProvider } from './AdminContext';
import { AppContextProvider } from './AppContext';
import { AuthContextProvider } from './AuthProvider';
import { DrawContextProvider } from './DrawContextProvider';
import { LayoutContextProvider } from './LayoutContext';
import { MapFunctionsProvider } from './MapFunctionsProvider';

const Providers = ({ children }) => {
    return (
        <ThemeProvider theme={theme}>
            <AuthContextProvider>
                <AppContextProvider>
                    <MapContextProvider viewOptions={MAP_OPTIONS}>
                        <WmsDateContextProvider>
                            <MapFunctionsProvider>
                                <DrawContextProvider>
                                    <LayoutContextProvider>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <SnackbarProvider maxSnack={3}>
                                                <AdminContextProvider>
                                                    <ModalContextProvider>
                                                        {children}
                                                    </ModalContextProvider>
                                                </AdminContextProvider>
                                            </SnackbarProvider>
                                        </LocalizationProvider>
                                    </LayoutContextProvider>
                                </DrawContextProvider>
                            </MapFunctionsProvider>
                        </WmsDateContextProvider>
                    </MapContextProvider>
                </AppContextProvider>
            </AuthContextProvider>
        </ThemeProvider>
    );
};

export default Providers;
