import { Grid, TableCell, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useApi } from '../../../../hooks/useApi';
import { formatNumberToDecimal } from '../../../../utils/formatter';
import DashboardTable from '../DashboardTable';

const Radar = () => {
    const [result, loading, error] = useApi((api, objWithSignal) =>
        api.dashboards.getPolicyRadarDashboard(objWithSignal)
    );

    const [filteredMeparBlocks, setFilteredMeparBlocks] = useState([]);
    const [filteredInsuredAreas, setFilteredInsuredAreas] = useState([]);

    useEffect(() => {
        if (!result || !result?.counts) {
            return;
        }

        setFilteredMeparBlocks(result.counts);
    }, [result, setFilteredMeparBlocks]);

    useEffect(() => {
        if (!result || !result?.areas) {
            return;
        }

        setFilteredInsuredAreas(result.areas);
    }, [result, setFilteredInsuredAreas]);

    return (
        <Grid container item spacing={2}>
            <DashboardTable
                title="Vihareseménnyel érintett biztosított növények és azok területmérete (ha)"
                gridProps={{ xs: 12, lg: 6 }}
                headerRows={['Növény', 'Ha']}
                loading={loading}
                error={error}
                filterable
                toFilter={result?.areas || []}
                setFiltered={setFilteredInsuredAreas}
            >
                {filteredInsuredAreas.map(({ category, area }) => (
                    <TableRow>
                        <TableCell>{category}</TableCell>
                        <TableCell align="right">{formatNumberToDecimal(area, 'ha')}</TableCell>
                    </TableRow>
                ))}
            </DashboardTable>
            <DashboardTable
                title="Vihareseménnyel érintett biztosított táblák MePAR blokkjainak száma (db)"
                gridProps={{ xs: 12, lg: 6 }}
                headerRows={['Növény', 'Ha']}
                loading={loading}
                error={error}
                filterable
                toFilter={result?.counts || []}
                setFiltered={setFilteredMeparBlocks}
            >
                {filteredMeparBlocks.map(({ category, count }) => (
                    <TableRow>
                        <TableCell>{category}</TableCell>
                        <TableCell align="right">{formatNumberToDecimal(count, 'db', 0)}</TableCell>
                    </TableRow>
                ))}
            </DashboardTable>
        </Grid>
    );
};

export default Radar;
