import { Style } from 'es-map-widget';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { api } from '../../hooks/useApi';
import { styles } from '../mapwrapper/MapWrapperStyles';

export default function FrostDamageChronologyDataprovider({ extent, layerData, setSource }) {
    const { id } = useParams();
    const getFrostLayer = useCallback(
        async (controller, params) =>
            api.layers
                .getFrostDamageChronology(params, { signal: controller.signal })
                .then((res) => res.data),
        [api]
    );

    const addStyleToFrosts = (zonesWithoutStyle) =>
        zonesWithoutStyle.map((e) => ({
            ...e,
            style: new Style({
                ...styles.frostDamage(e.value),
            }),
        }));

    useEffect(() => {
        if (!layerData.isLayerVisible) {
            setSource(null);
            return;
        }

        const controller = new AbortController();

        getFrostLayer(controller, {
            extent,
        })
            .then((newFrostLayer) => setSource(addStyleToFrosts(newFrostLayer)))
            .catch((err) => {
                if (err.code !== 'ERR_CANCELED') {
                    console.error(err);
                }
            });

        // eslint-disable-next-line consistent-return
        return () => {
            controller.abort();
        };
    }, [getFrostLayer, extent, layerData, id, addStyleToFrosts, setSource]);
    return null;
}
