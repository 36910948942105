import React from 'react';
import { OVERVIEW_TYPES } from '../../../constants';
import { useLayout } from '../../../providers/LayoutContext';
import DamagedFieldBlock from './DamagedFieldBlock';
import FieldBlock from './FieldBlock';
import InsuredFieldBlock from './InsuredFieldBlock';
import MeparBlock from './MeparBlock';
import NoDataBlock from './NoDataBlock';
import PolicyBlock from './PolicyBlock';
import ReportBlock from './ReportBlock';

const DataChildBlock = () => {
    const { selectedProduct } = useLayout();

    const type = selectedProduct?.type;

    if (type === OVERVIEW_TYPES.MEPAR) {
        return <MeparBlock />;
    }

    if (type === OVERVIEW_TYPES.FIELD) {
        return <FieldBlock />;
    }

    if (type === OVERVIEW_TYPES.FIELD_DAMAGED) {
        return <DamagedFieldBlock />;
    }

    if (type === OVERVIEW_TYPES.FIELD_INSURED) {
        return <InsuredFieldBlock />;
    }

    if (type === OVERVIEW_TYPES.REPORT) {
        return <ReportBlock />;
    }

    if (type === OVERVIEW_TYPES.POLICY) {
        return <PolicyBlock />;
    }

    return <NoDataBlock />;
};

export default DataChildBlock;
