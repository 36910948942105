import { Alert, Grid } from '@mui/material';
import React from 'react';

const HomePage = () => {
    return (
        <Grid item container xs={12}>
            <Grid
                item
                xs={12}
                sx={{
                    '&>img': {
                        maxWidth: '250px',
                    },
                }}
            >
                <img src="/envirosense_logo.jpg" alt="Envirosense logó" />
            </Grid>
            <Grid item xs={12} sx={{ mb: 3 }}>
                <div>
                    <b>Kapcsolat</b>
                </div>
                <div>Szanka Gábor</div>
                <div>
                    Tel: <a href="tel:+36301643425">+36 30 164 34 25</a>
                </div>
                <div>
                    Email: <a href="mailto:georisk@envimap.hu">georisk@envimap.hu</a>
                </div>
            </Grid>
            <Grid item xs={12}>
                <Alert severity="info">
                    Adatok megjelenítéséhez kezdjen el keresni a keresősávban.
                </Alert>
            </Grid>
        </Grid>
    );
};

export default HomePage;
