import { Button } from '@mui/material';
import { useMap } from 'es-map-widget';
import { useSnackbar } from 'notistack';
import { React, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { MAP_LAYER_NAMES, OVERVIEW_STATES } from '../constants';
import { useAppControl } from '../providers/AppContext';
import { useAuth } from '../providers/AuthProvider';
import { useLayout } from '../providers/LayoutContext';

const useNotify = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { logoutUser } = useAuth();
    const navigate = useNavigate();
    const map = useMap();
    const { setOverviewState } = useAppControl();
    const { setSelectedProduct } = useLayout();
    const closeButton = (key) => (
        <Button color="inherit" onClick={() => closeSnackbar(key)}>
            Bezárás
        </Button>
    );

    const notifySuccess = useCallback(
        (message, persist = false) => {
            enqueueSnackbar(message, {
                variant: 'success',
                persist,
                preventDuplicate: true,
                action: persist ? (key) => closeButton(key) : undefined,
            });
        },
        [enqueueSnackbar, closeSnackbar]
    );
    const notifyInfo = useCallback(
        (message, persist = false) => {
            enqueueSnackbar(message, {
                variant: 'info',
                persist,
                preventDuplicate: true,
                action: persist ? (key) => closeButton(key) : undefined,
            });
        },
        [enqueueSnackbar, closeSnackbar]
    );
    const notifyError = useCallback(
        (error) => {
            let stringMessage =
                error?.response?.data?.message || error?.message || 'Valami hiba történt';
            if (stringMessage === 'Authentication token is invalid.') {
                logoutUser();
                setOverviewState(OVERVIEW_STATES.CLOSED);
                setSelectedProduct(null);
                map.drawCancel();
                map.setLayerData(MAP_LAYER_NAMES.MEPAR, { codes: [], connecting: true });
                map.setLayerData(MAP_LAYER_NAMES.FIELDS, { geoms: [] });
                map.setLayerData(MAP_LAYER_NAMES.SAMPLING, { geoms: [] });
                map.setLayerData(MAP_LAYER_NAMES.ZONE, { geoms: [] });
                map.setLayerVisibility(MAP_LAYER_NAMES.MEPAR, false);
                map.setLayerVisibility(MAP_LAYER_NAMES.FIELDS, false);
                map.setLayerVisibility(MAP_LAYER_NAMES.SAMPLING, false);
                map.setLayerVisibility(MAP_LAYER_NAMES.ZONE, false);
                navigate('/');
                stringMessage = 'Lejárt az autentikációs token.';
            }

            enqueueSnackbar(stringMessage, {
                variant: 'error',
                persist: true,
                action: (key) => {
                    return (
                        <Button
                            variant="text"
                            style={{ color: 'white' }}
                            onClick={() => closeSnackbar(key)}
                        >
                            Close
                        </Button>
                    );
                },
            });
        },
        [enqueueSnackbar, closeSnackbar]
    );

    return {
        notifySuccess,
        notifyError,
        notifyInfo,
    };
};

export default useNotify;
