import React, { useCallback, useContext, useMemo, useState } from 'react';
import { MODAL_TYPE } from '../constants';
import ConfirmModal from '../modals/ConfirmModal';
import NewDamageReportModal from '../modals/NewDamageReportModal';
import SamplingModificationModal from '../modals/SamplingModificationModal';

const ModalContext = React.createContext();

export function Modals() {
    return (
        <ModalContext.Consumer>
            {(context) => {
                const allProps = {
                    ...context.props,
                    currentlyShowingModal: context.currentlyShowingModal,
                    showModal: context.showModal,
                    MODAL_TYPE,
                };
                return (
                    <div>
                        {context.currentlyShowingModal === MODAL_TYPE.NEW_DAMAGE_REPORT && (
                            <NewDamageReportModal {...allProps} />
                        )}
                        {context.currentlyShowingModal ===
                            MODAL_TYPE.SAMPLING_MODIFICATION_MODAL && (
                            <SamplingModificationModal {...allProps} />
                        )}
                        {context.currentlyShowingModal === MODAL_TYPE.CONFIRM_MODAL && (
                            <ConfirmModal {...allProps} />
                        )}
                    </div>
                );
            }}
        </ModalContext.Consumer>
    );
}

export function ModalContextProvider({ children }) {
    const [modalState, setModalState] = useState({
        currentlyShowingModal: false,
        props: {},
    });

    const showModal = useCallback(
        (currentlyShowingModal, newprops = {}) => {
            setModalState({
                currentlyShowingModal,
                props: newprops,
            });
        },
        [setModalState]
    );

    const modalStateMemo = useMemo(() => ({ ...modalState, showModal }), [modalState, showModal]);

    return (
        <ModalContext.Provider value={modalStateMemo}>
            {children}
            <Modals />
        </ModalContext.Provider>
    );
}

ModalContext.displayName = 'ModalContext';

export function useModals() {
    return useContext(ModalContext);
}
