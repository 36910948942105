/* eslint-disable no-nested-ternary */
import { CloudRounded, SquareFootRounded, SsidChartRounded } from '@mui/icons-material';
import { Alert, Box, CircularProgress, Grid, Tab, Tabs, Tooltip } from '@mui/material';
import chroma from 'chroma-js';
import { Stroke, Style, useMap } from 'es-map-widget';
import React, { useEffect, useState } from 'react';
import { MAP_LAYER_NAMES } from '../../../../../constants';
import { styles } from '../../../../mapwrapper/MapWrapperStyles';
import DamagePlotAreas from './DamagePlotAreas';
import RadarDates from './RadarDates';
import YieldCalculation from './YieldCalculation';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} {...other}>
            {value === index && children}
        </div>
    );
}

const DamagedFieldTabs = ({ calculatedYield, areas, areasLoading, areasError, setReloadFlag }) => {
    const map = useMap();
    const [value, setValue] = useState(1);
    const [data, setData] = useState(null);

    useEffect(() => {
        if (!areas) {
            return;
        }
        const damagePlotAreas = areas.map((e) => {
            const color = chroma.random();
            return {
                ...e,
                style: new Style({
                    ...styles.field,
                    stroke: new Stroke({
                        color,
                        width: 3,
                    }),
                }),
                color,
            };
        });

        setData(damagePlotAreas);

        map.setLayerVisibility(MAP_LAYER_NAMES.FIELDS, true);
        map.setLayerData(MAP_LAYER_NAMES.FIELDS, {
            geoms: damagePlotAreas,
        });
    }, [map, areas]);

    const handleChange = (_event, newValue) => {
        setValue(newValue);
    };

    if (areasError) {
        return (
            <Grid item>
                <Alert severity="error">Hiba történt a kérés teljesítése során</Alert>
            </Grid>
        );
    }

    if (areasLoading) {
        return (
            <Grid item xs={12} textAlign="center">
                <CircularProgress />
            </Grid>
        );
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    <Tab
                        icon={
                            <Tooltip title="Hozamszámítás" placement="top">
                                <SsidChartRounded />
                            </Tooltip>
                        }
                    />
                    <Tab
                        icon={
                            <Tooltip title="Táblahatár mérés" placement="top">
                                <SquareFootRounded />
                            </Tooltip>
                        }
                    />
                    <Tab
                        icon={
                            <Tooltip title="Időjárás kompozit" placement="top">
                                <CloudRounded />
                            </Tooltip>
                        }
                    />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <YieldCalculation calculatedYield={calculatedYield} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Grid container sx={{ p: 1 }}>
                    <DamagePlotAreas
                        areasLoading={areasLoading}
                        error={areasError}
                        data={data}
                        setReloadFlag={setReloadFlag}
                    />
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Grid container sx={{ p: 1 }}>
                    <RadarDates />
                </Grid>
            </TabPanel>
        </Box>
    );
};

export default DamagedFieldTabs;
