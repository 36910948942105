import { Grid } from '@mui/material';
import { getYear } from 'date-fns';
import { useMap } from 'es-map-widget';
import React, { useEffect } from 'react';
import { MAP_LAYER_NAMES } from '../../constants';
import { DRAWING_STATES } from '../../constants/drawingStates';
import { useAppControl } from '../../providers/AppContext';
import { useDrawContext } from '../../providers/DrawContextProvider';
import PointAddButton from './buttons/PointAddButton';
import PointAddYieldButton from './buttons/PointAddYieldButton';
import PointCancelDeleteButton from './buttons/PointCancelDeleteButton';
import PointCancelSetupButton from './buttons/PointCancelSetupButton';
import PointDeleteButton from './buttons/PointDeleteButton';
import PointSaveButton from './buttons/PointSaveButton';
import PointUndoButton from './buttons/PointUndoButton';
import ZoneGenerationButton from './buttons/ZoneGenerationButton';

const currentYear = getYear(new Date());

const PointDrawControls = () => {
    const map = useMap();
    const { year, drawingState } = useAppControl();
    const { isLoading, isGeneratingSamples, hasAreaDrawn } = useDrawContext();
    const disabled = isGeneratingSamples || isLoading || !hasAreaDrawn;

    useEffect(() => {
        if (
            drawingState === DRAWING_STATES.POINT_YIELD ||
            drawingState === DRAWING_STATES.POINT_SETUP
        ) {
            map.setLayerVisibility(MAP_LAYER_NAMES.ZONE, true);
            map.setLayerVisibility(MAP_LAYER_NAMES.SAMPLING, true);
        }
    }, [map, drawingState]);

    return (
        <Grid item xs={12} container direction="column">
            {drawingState === DRAWING_STATES.POINT_SETUP && (
                <>
                    <ZoneGenerationButton disabled={disabled || year !== currentYear} />
                    <PointAddButton disabled={disabled} />
                    <PointDeleteButton disabled={disabled} />
                    <PointAddYieldButton disabled={disabled} />
                    <PointCancelSetupButton disabled={isLoading} />
                </>
            )}
            {(drawingState === DRAWING_STATES.POINT ||
                drawingState === DRAWING_STATES.POINT_YIELD ||
                drawingState === DRAWING_STATES.POINT_DELETE) && (
                <>
                    <PointSaveButton />
                    <PointUndoButton />
                    <PointCancelDeleteButton />
                </>
            )}
        </Grid>
    );
};

export default PointDrawControls;
