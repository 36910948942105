import { useEffect, useMemo } from 'react';
import { LOGGED_IN_USER_KEY } from '../../config';

const ChangeMapDataprovider = ({ layerData, setSource }) => {
    const user = useMemo(() => JSON.parse(localStorage.getItem(LOGGED_IN_USER_KEY)), []);

    const { isLayerVisible, layerStringCode, layerStringDate } = layerData;

    useEffect(() => {
        if (isLayerVisible === false || layerStringCode === '' || layerStringDate === '') {
            setSource(null);
            return;
        }

        setSource({
            url: `/api/layers/wms/${layerStringCode}?token=${user.token}`,
            params: {
                LAYERS: `${layerStringCode}:${layerStringDate}`,
            },
        });
    }, [isLayerVisible, layerStringCode, layerStringDate, user]);

    // this is a HOC
    return null;
};

export default ChangeMapDataprovider;
