import { Grid, Paper } from '@mui/material';
import React from 'react';
import ContentArea from '../components/ContentArea';

const DesktopLayout = ({ children }) => {
    return (
        <Grid container sx={{ height: '100%' }} spacing={2}>
            <Grid item container xs={12} md={3} alignContent="flex-start" sx={{ height: '100%' }}>
                <Paper
                    elevation={0}
                    sx={{ height: '100%', overflowY: 'auto', pr: 1, backgroundColor: '#fafbfd' }}
                >
                    {children}
                </Paper>
            </Grid>
            <Grid container item xs={12} md={9}>
                <ContentArea />
            </Grid>
        </Grid>
    );
};

export default DesktopLayout;
