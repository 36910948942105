import { Grid } from '@mui/material';
import clsx from 'clsx';
import { useMap } from 'es-map-widget';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CONTROLLER_KEYS, CONTROLLER_LABELS } from '../../../constants';
import { MAP_LAYER_NAMES } from '../../../constants/mapLayerNames';
import { useLayout } from '../../../providers/LayoutContext';
import generateLayerString from '../../../utils/generateLayerString';
import FieldWmsDataController from '../components/FieldWmsDataController';
import { useMenu } from '../contexts/MenuContext';
import { useWmsDates } from '../contexts/WmsDateProvider';
import MapControllerMenuItem from '../menu/MapControllerMenuItem';
import SubMenuWrapper from '../menu/SubMenuWrapper';

const MonitoringController = () => {
    const map = useMap();
    const { wmsData } = useWmsDates();
    const { checkedItems, handleItemCheck } = useMenu();
    const { matchesLgUp } = useLayout();
    const [selectedOption, setSelectedOption] = useState(CONTROLLER_KEYS.FIELD_WMS_MENU);
    const [isDamageMapVisible, setIsDamageMapVisible] = useState(false);
    const [searchParams] = useSearchParams();
    const damagePlotId = searchParams.get('damagePlotId');

    const [isChangeMapVisible, setIsChangeMapVisible] = useState(
        checkedItems[CONTROLLER_KEYS.MONITORING].includes(CONTROLLER_KEYS.CHANGE_MAP)
    );
    const [isMonitoringLayerVisible, setIsMonitoringLayerVisible] = useState(
        checkedItems[CONTROLLER_KEYS.MONITORING].includes(CONTROLLER_KEYS.FIELD_WMS)
    );

    return (
        <Grid container>
            <Grid
                item
                xs={12}
                lg={6}
                className={clsx('dropdown-level-1', !matchesLgUp && 'mobile')}
            >
                <MapControllerMenuItem
                    isActive={selectedOption === CONTROLLER_KEYS.FIELD_WMS_MENU}
                    onClick={() => setSelectedOption(CONTROLLER_KEYS.FIELD_WMS_MENU)}
                    text={CONTROLLER_LABELS.FIELD_WMS_MENU}
                    checkbox={{
                        checked: isMonitoringLayerVisible,
                        onChange: (event) => {
                            setIsMonitoringLayerVisible((p) => !p);
                            setIsDamageMapVisible(false);
                            setIsChangeMapVisible(false);
                            map.setLayerVisibility(MAP_LAYER_NAMES.NDVI, !isMonitoringLayerVisible);
                            map.setLayerVisibility(MAP_LAYER_NAMES.DAMAGE_MAP, false);
                            map.setLayerVisibility(MAP_LAYER_NAMES.CHANGE_MAP, false);
                            map.setLayerData(MAP_LAYER_NAMES.NDVI, {
                                isLayerVisible: !isMonitoringLayerVisible,
                                ...wmsData,
                            });
                            checkedItems[CONTROLLER_KEYS.MONITORING].forEach((key) =>
                                handleItemCheck(key, CONTROLLER_KEYS.MONITORING)
                            );
                            if (event.target.checked) {
                                handleItemCheck(
                                    CONTROLLER_KEYS.FIELD_WMS,
                                    CONTROLLER_KEYS.MONITORING
                                );
                            }
                        },
                    }}
                    hasSubMenu
                />
                <MapControllerMenuItem
                    text={CONTROLLER_LABELS.CHANGE_MAP}
                    checkbox={{
                        checked: isChangeMapVisible,
                        onChange: (event) => {
                            setIsChangeMapVisible((pV) => !pV);
                            setIsMonitoringLayerVisible(false);
                            setIsDamageMapVisible(false);
                            map.setLayerVisibility(MAP_LAYER_NAMES.CHANGE_MAP, !isChangeMapVisible);
                            map.setLayerVisibility(MAP_LAYER_NAMES.NDVI, false);
                            map.setLayerVisibility(MAP_LAYER_NAMES.DAMAGE_MAP, false);
                            map.setLayerData(MAP_LAYER_NAMES.CHANGE_MAP, {
                                isLayerVisible: !isChangeMapVisible,
                                ...generateLayerString({
                                    ...wmsData,
                                    type: CONTROLLER_KEYS.CHANGE_MAP,
                                }),
                            });
                            checkedItems[CONTROLLER_KEYS.MONITORING].forEach((key) =>
                                handleItemCheck(key, CONTROLLER_KEYS.MONITORING)
                            );
                            if (event.target.checked) {
                                handleItemCheck(
                                    CONTROLLER_KEYS.CHANGE_MAP,
                                    CONTROLLER_KEYS.MONITORING
                                );
                            }
                        },
                        disabled: damagePlotId === null || !wmsData.changeMap,
                    }}
                />
                <MapControllerMenuItem
                    text={CONTROLLER_LABELS.DAMAGE_MAP}
                    checkbox={{
                        checked: isDamageMapVisible,
                        onChange: (event) => {
                            setIsChangeMapVisible(false);
                            setIsMonitoringLayerVisible(false);
                            setIsDamageMapVisible((p) => !p);
                            map.setLayerVisibility(MAP_LAYER_NAMES.DAMAGE_MAP, !isDamageMapVisible);
                            map.setLayerVisibility(MAP_LAYER_NAMES.NDVI, false);
                            map.setLayerVisibility(MAP_LAYER_NAMES.CHANGE_MAP, false);

                            map.setLayerData(MAP_LAYER_NAMES.DAMAGE_MAP, {
                                isLayerVisible: !isDamageMapVisible,
                                ...generateLayerString({
                                    ...wmsData,
                                    type: CONTROLLER_KEYS.DAMAGE_MAP,
                                }),
                            });
                            checkedItems[CONTROLLER_KEYS.MONITORING].forEach((key) =>
                                handleItemCheck(key, CONTROLLER_KEYS.MONITORING)
                            );
                            if (event.target.checked) {
                                handleItemCheck(
                                    CONTROLLER_KEYS.DAMAGE_MAP,
                                    CONTROLLER_KEYS.MONITORING
                                );
                            }
                        },
                        disabled: damagePlotId === null || !wmsData.damageMap,
                    }}
                />
            </Grid>
            <Grid
                item
                xs={12}
                lg={6}
                className={clsx('dropdown-level-2', !matchesLgUp && 'mobile')}
            >
                <SubMenuWrapper isVisible={selectedOption === CONTROLLER_KEYS.FIELD_WMS_MENU}>
                    <FieldWmsDataController isLayerVisible={isMonitoringLayerVisible} />
                </SubMenuWrapper>
            </Grid>
        </Grid>
    );
};

export default MonitoringController;
