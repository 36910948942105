import { useCallback, useEffect, useState } from 'react';
import { LOGGED_IN_USER_KEY } from '../config';
import { Api } from '../services/APIService';
import useNotify from './useNotify';

const api = new Api({
    baseURL: '/api',
    securityWorker: (token) => (token ? { headers: { Authorization: `Bearer ${token}` } } : {}),
    headers: {
        // Set default content type
        put: { 'Content-Type': 'application/json' },
        post: { 'Content-Type': 'application/json' },
        patch: { 'Content-Type': 'application/json' },
    },
});

// Load token from localStorage
const user = JSON.parse(localStorage.getItem(LOGGED_IN_USER_KEY));
if (user) {
    api.setSecurityData(user.token);
}

/**
 * Hook to get data from the server. API calls are
 * @param {Function} call The function to call in the form of (api, objWithSignal) => api.x.y(params, objWithSignal) or (api, objWithSignal) => api.x.y(objWithSignal) if there are no params
 * @param {Array} extraDeps Extra dependencies to run the call again if these change
 * @param {Function} mappingFn A function to map the returned data
 * @param {Boolean} stop A boolean variable whether to stop the function or not
 * @returns {Array} [result, loading, error, setResult]
 */
const useApi = (call, extraDeps = [], mappingFn = (x) => x, stop = false) => {
    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const { notifyError } = useNotify();

    const handleError = useCallback(
        (err) => {
            setError(err);
            notifyError(err);
        },
        [setError, notifyError]
    );

    useEffect(() => {
        const controller = new AbortController();
        setLoading(true);

        if (stop) {
            setLoading(false);
            return () => controller.abort();
        }

        call(api, {
            signal: controller.signal,
        })
            .then((res) => {
                setResult(mappingFn(res.data));
                setError(null);
                setLoading(false);
            })
            .catch((err) => {
                if (!controller.signal.aborted) {
                    handleError(err);
                    setLoading(false);
                    setResult(null);
                }
            });

        return () => {
            setLoading(false);
            controller.abort();
        };
    }, [api, setResult, setLoading, handleError, ...extraDeps]);

    return [result, loading, error, setResult];
};

export { api, useApi };
