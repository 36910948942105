import { FormGroup } from '@mui/material';
import React from 'react';
import { CONTROLLER_KEYS, CONTROLLER_LABELS } from '../../../constants';
import { useMenu } from '../contexts/MenuContext';
import MapControllerMenuItem from '../menu/MapControllerMenuItem';

const CropDataController = ({ selectedCrops, setSelectedCrops }) => {
    const { handleItemCheck } = useMenu();
    return (
        <FormGroup>
            <MapControllerMenuItem
                text={CONTROLLER_LABELS.SPIKE}
                checkbox={{
                    checked: selectedCrops.includes(CONTROLLER_KEYS.SPIKE),
                    onChange: (event) => {
                        const isChecked = event.currentTarget.checked;
                        setSelectedCrops(
                            isChecked
                                ? [...selectedCrops, CONTROLLER_KEYS.SPIKE]
                                : selectedCrops.filter((e) => e !== CONTROLLER_KEYS.SPIKE)
                        );
                        handleItemCheck(CONTROLLER_KEYS.SPIKE, CONTROLLER_KEYS.CROP);
                    },
                }}
            />
            <MapControllerMenuItem
                text={CONTROLLER_LABELS.COLE_SEED}
                checkbox={{
                    checked: selectedCrops.includes(CONTROLLER_KEYS.COLE_SEED),
                    onChange: (event) => {
                        const isChecked = event.currentTarget.checked;
                        setSelectedCrops(
                            isChecked
                                ? [...selectedCrops, CONTROLLER_KEYS.COLE_SEED]
                                : selectedCrops.filter((e) => e !== CONTROLLER_KEYS.COLE_SEED)
                        );
                        handleItemCheck(CONTROLLER_KEYS.COLE_SEED, CONTROLLER_KEYS.CROP);
                    },
                }}
            />
            <MapControllerMenuItem
                text={CONTROLLER_LABELS.CORN}
                checkbox={{
                    checked: selectedCrops.includes(CONTROLLER_KEYS.CORN),
                    onChange: (event) => {
                        const isChecked = event.currentTarget.checked;
                        setSelectedCrops(
                            isChecked
                                ? [...selectedCrops, CONTROLLER_KEYS.CORN]
                                : selectedCrops.filter((e) => e !== CONTROLLER_KEYS.CORN)
                        );
                        handleItemCheck(CONTROLLER_KEYS.CORN, CONTROLLER_KEYS.CROP);
                    },
                }}
            />
            <MapControllerMenuItem
                text={CONTROLLER_LABELS.SUNFLOWER}
                checkbox={{
                    checked: selectedCrops.includes(CONTROLLER_KEYS.SUNFLOWER),
                    onChange: (event) => {
                        const isChecked = event.currentTarget.checked;
                        setSelectedCrops(
                            isChecked
                                ? [...selectedCrops, CONTROLLER_KEYS.SUNFLOWER]
                                : selectedCrops.filter((e) => e !== CONTROLLER_KEYS.SUNFLOWER)
                        );
                        handleItemCheck(CONTROLLER_KEYS.SUNFLOWER, CONTROLLER_KEYS.CROP);
                    },
                }}
            />
        </FormGroup>
    );
};

export default CropDataController;
