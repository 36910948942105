import { InfoRounded, NoteAddRounded } from '@mui/icons-material';
import { Divider, Grid, IconButton, Paper, Tooltip } from '@mui/material';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { MODAL_TYPE } from '../constants';
import { DRAWING_STATES } from '../constants/drawingStates';
import { TOOLTIP_TEXTS } from '../constants/texts';
import { useModals } from '../hooks/useModal';
import { useAppControl } from '../providers/AppContext';
import { useLayout } from '../providers/LayoutContext';
import DrawControls from './components/DrawControls';
import styles from './DrawControllerWrapper.module.scss';

const DrawController = ({ legendFromBottom, onToggleLegend, legendOpen }) => {
    const { drawingState } = useAppControl();
    const { showModal } = useModals();
    const { matchesLgUp } = useLayout();
    const [searchParams] = useSearchParams();

    return (
        <Paper
            className={styles.controller}
            sx={{ bottom: legendFromBottom, left: matchesLgUp ? 32 : 8 }}
        >
            <Grid container direction="column">
                <Tooltip title={TOOLTIP_TEXTS.newDamageReport} placement="right">
                    <span>
                        <IconButton
                            onClick={() => showModal(MODAL_TYPE.NEW_DAMAGE_REPORT)}
                            disabled={drawingState !== DRAWING_STATES.NONE}
                        >
                            <NoteAddRounded />
                        </IconButton>
                    </span>
                </Tooltip>
                {searchParams.get('damagePlotId') !== null && <DrawControls />}
                <Divider />
                <Tooltip title={TOOLTIP_TEXTS.legend} placement={!legendOpen ? 'right' : 'bottom'}>
                    <IconButton onClick={() => onToggleLegend(!legendOpen)}>
                        <InfoRounded />
                    </IconButton>
                </Tooltip>
            </Grid>
        </Paper>
    );
};

export default DrawController;
