import { AppBar } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DRAWING_STATES, OVERVIEW_STATES } from '../../constants';
import { useAppControl } from '../../providers/AppContext';
import { useAuth } from '../../providers/AuthProvider';
import { useLayout } from '../../providers/LayoutContext';
import { useMapFunctions } from '../../providers/MapFunctionsProvider';
import NavbarDesktop from './NavbarDesktop';
import NavbarMobile from './NavbarMobile';

const Navbar = ({ isOnDashboard }) => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const { setDrawingState, setOverviewState } = useAppControl();
    const { matchesLgUp, setSelectedProduct } = useLayout();
    const { resetMap } = useMapFunctions();
    const handleClick = () => {
        try {
            resetMap();
        } catch (error) {
            console.log(error);
        }
        setOverviewState(OVERVIEW_STATES.CLOSED);
        setDrawingState(DRAWING_STATES.NONE);
        setSelectedProduct(null);
        navigate('/');
    };

    return (
        <AppBar
            position="fixed"
            color="primary"
            enableColorOnDark
            sx={{ zIndex: (theme) => matchesLgUp && theme.zIndex.drawer + 1 }}
        >
            {matchesLgUp ? (
                <NavbarDesktop handleClick={handleClick} user={user} />
            ) : (
                <NavbarMobile handleClick={handleClick} user={user} isOnDashboard={isOnDashboard} />
            )}
        </AppBar>
    );
};

export default Navbar;
