import { FormControl, FormGroup, Grid, RadioGroup } from '@mui/material';
import React from 'react';
import { CONTROLLER_KEYS, CONTROLLER_LABELS } from '../../../constants';
import SubMenuRadio from '../menu/SubMenuRadio';

const YieldDataController = ({ value, setValue, defaultValue, isLayerVisible }) => {
    return (
        <Grid container justifyContent="flex-start" alignItems="flex-start">
            <Grid item>
                <FormGroup>
                    <FormControl>
                        <RadioGroup
                            defaultValue={defaultValue}
                            name="map-controller-radio-group"
                            value={value}
                            onChange={(event) => setValue(event.target.value)}
                        >
                            <SubMenuRadio
                                value={CONTROLLER_KEYS.RAPE_SEED}
                                label={CONTROLLER_LABELS.RAPE_SEED}
                                disabled={!isLayerVisible}
                            />
                            <SubMenuRadio
                                value={CONTROLLER_KEYS.SPIKE}
                                label={CONTROLLER_LABELS.SPIKE}
                                disabled={!isLayerVisible}
                            />
                            <SubMenuRadio
                                value={CONTROLLER_KEYS.CORN}
                                label={CONTROLLER_LABELS.CORN}
                                disabled={!isLayerVisible}
                            />
                            <SubMenuRadio
                                value={CONTROLLER_KEYS.SUNFLOWER}
                                label={CONTROLLER_LABELS.SUNFLOWER}
                                disabled={!isLayerVisible}
                            />
                        </RadioGroup>
                    </FormControl>
                </FormGroup>
            </Grid>
        </Grid>
    );
};

export default YieldDataController;
