import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
    Button,
    CircularProgress,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { object, ref, string } from 'yup';
import { api } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';

const passwordValidationSchema = object({
    oldPassword: string().required('A mező kitöltése kötelező'),
    newPassword: string()
        .notOneOf([ref('oldPassword'), null], 'Az új jelszó nem egyezhet meg a régivel.')
        .required('A mező kitöltése kötelező'),
    newPasswordConfirmation: string()
        .equals([ref('newPassword'), null], 'A megadott jelszavak nem egyeznek')
        .required('A mező kitöltése kötelező'),
});

const PasswordChangeForm = () => {
    const { notifySuccess, notifyError } = useNotify();
    const [loading, setLoading] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showNewPasswordConfirmation, setShowNewPasswordConfirmation] = useState(false);

    const initialValues = {
        oldPassword: '',
        newPassword: '',
        newPasswordConfirmation: '',
    };

    const submitPasswordChangeForm = ({ oldPassword, newPassword }, actions) => {
        setLoading(true);
        api.users
            .changePassword({ oldPassword, newPassword })
            .then(() => {
                notifySuccess('Sikeres jelszómódosítás');
                setLoading(false);
                actions.resetForm(initialValues);
            })
            .catch((err) => {
                notifyError(err);
                setLoading(false);
                console.log(err);
            });
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={submitPasswordChangeForm}
            validationSchema={passwordValidationSchema}
        >
            {({ values, handleChange, errors }) => (
                <Form style={{ padding: '16px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h5">Jelszó megváltoztatása</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name="oldPassword"
                                label="Jelenlegi jelszó"
                                type={showOldPassword ? 'text' : 'password'}
                                value={values.password}
                                onChange={handleChange}
                                error={!!errors.oldPassword}
                                helperText={errors.oldPassword}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowOldPassword(!showOldPassword)}
                                                edge="end"
                                            >
                                                {showOldPassword ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name="newPassword"
                                label="Új jelszó"
                                type={showNewPassword ? 'text' : 'password'}
                                value={values.passwordNew}
                                onChange={handleChange}
                                error={!!errors.newPassword}
                                helperText={errors.newPassword}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowNewPassword(!showNewPassword)}
                                                edge="end"
                                            >
                                                {showNewPassword ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name="newPasswordConfirmation"
                                label="Új jelszó mégegyszer"
                                type={showNewPasswordConfirmation ? 'text' : 'password'}
                                value={values.passwordNewConfirmation}
                                onChange={handleChange}
                                error={!!errors.newPasswordConfirmation}
                                helperText={errors.newPasswordConfirmation}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() =>
                                                    setShowNewPasswordConfirmation(
                                                        !showNewPasswordConfirmation
                                                    )
                                                }
                                                edge="end"
                                            >
                                                {showNewPasswordConfirmation ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} textAlign="right">
                            <Button type="submit" fullWidth variant="contained">
                                {!loading ? (
                                    'Mentés'
                                ) : (
                                    <CircularProgress color="secondary" size={25} />
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default PasswordChangeForm;
