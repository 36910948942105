import { Grid } from '@mui/material';
import React from 'react';

const ContentArea = ({ children }) => {
    return (
        <Grid container item xs={12}>
            {children}
        </Grid>
    );
};

export default ContentArea;
