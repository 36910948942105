import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material';
import React from 'react';
import { MODAL_TYPE } from '../constants';

const ConfirmModal = ({ showModal, onClick, title, body }) => {
    const handleClose = () => {
        showModal(MODAL_TYPE.NONE);
    };

    return (
        <Dialog open onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>{title}</DialogTitle>
            {body && (
                <DialogContent>
                    <Typography variant="body2">{body}</Typography>
                </DialogContent>
            )}
            <DialogActions>
                <Button onClick={handleClose}>Mégse</Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        onClick();
                        handleClose();
                    }}
                >
                    Megerősít
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmModal;
