import { useEffect } from 'react';

// TODO: this will be the DamagePlotDataprovider rename it when fields arrive
export default function FieldDataprovider({ extent, layerData, setSource }) {
    useEffect(() => {
        if (!layerData.geoms || layerData.geoms.length === 0) {
            setSource(null);
            return;
        }

        setSource(layerData.geoms);
    }, [extent, layerData.geoms, setSource]);
    return null;
}
