import {
    CancelRounded,
    CheckBoxOutlineBlankRounded,
    SaveRounded,
    UndoRounded,
} from '@mui/icons-material';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { useMap } from 'es-map-widget';
import React, { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { MODAL_TYPE, OVERVIEW_STATES, TOOLTIP_TEXTS } from '../../constants';
import { DRAWING_STATES } from '../../constants/drawingStates';
import { api } from '../../hooks/useApi';
import { useModals } from '../../hooks/useModal';
import useNotify from '../../hooks/useNotify';
import { useAppControl } from '../../providers/AppContext';
import { useLayout } from '../../providers/LayoutContext';
import { useDrawContext } from '../../providers/DrawContextProvider';

const PolygonDrawControls = () => {
    const map = useMap();
    const { notifyError, notifySuccess } = useNotify();
    const { drawingState, setDrawingState, overviewState, setOverviewState } = useAppControl();
    const { selectedProduct } = useLayout();
    const { showModal } = useModals();
    const { isLoading } = useDrawContext();
    const [searchParams, setSearchParams] = useSearchParams();
    const plotIdParam = searchParams.get('plotId');
    const damagePlotId = searchParams.get('damagePlotId');

    const editDamagePlot = useCallback(
        async ({ id, plotId, area, geom }) =>
            api.damagePlots.saveDamagePlotArea(id, plotId, { area, geom }).then((res) => res.data),
        [api]
    );

    const resetOverview = useCallback(() => {
        setOverviewState(OVERVIEW_STATES.OPEN);
        setSearchParams({ damagePlotId });
        setDrawingState(DRAWING_STATES.NONE);
        map.drawCancel();
    }, [map, damagePlotId, setOverviewState, setSearchParams, setDrawingState]);

    const saveDamagePlot = async ({ id, area, geom }) =>
        api.damagePlots.createDamagePlotArea(id, { area, geom }).then((res) => res.data);

    const saveFunction = useCallback(() => {
        const [feature] = map.drawSave();
        if (plotIdParam !== null) {
            editDamagePlot({
                id: damagePlotId,
                plotId: plotIdParam,
                area: feature.getGeometry().getArea() / 10000,
                geom: {
                    type: 'MultiPolygon',
                    coordinates: feature.getGeometry().getCoordinates(),
                },
            })
                .then(() => {
                    notifySuccess('Sikeres mentés');
                    resetOverview();
                })
                .catch((err) => {
                    resetOverview();
                    notifyError(err);
                    console.log(err);
                });
        } else {
            saveDamagePlot({
                id: damagePlotId,
                area: feature.getGeometry().getArea() / 10000,
                geom: {
                    type: 'MultiPolygon',
                    coordinates: [feature.getGeometry().getCoordinates()],
                },
            })
                .then(() => {
                    resetOverview();
                    notifySuccess('Sikeres mentés');
                })
                .catch((err) => {
                    notifyError(err);
                    resetOverview();
                    console.log(err);
                });
        }
    }, [drawingState, plotIdParam, damagePlotId, selectedProduct, setOverviewState]);

    const saveMeparArea = useCallback(() => {
        api.damagePlots
            .saveMeparAsArea(damagePlotId)
            .then(() => {
                resetOverview();
                notifySuccess('Sikeres mentés');
            })
            .catch((err) => {
                resetOverview();
                notifyError(err);
                console.log(err);
            });
    }, [saveDamagePlot, damagePlotId, selectedProduct, resetOverview, notifyError, notifySuccess]);

    return (
        <Grid item container direction="column">
            <Tooltip title={TOOLTIP_TEXTS.saveMepar} placement="right">
                <IconButton
                    onClick={() =>
                        showModal(MODAL_TYPE.CONFIRM_MODAL, {
                            onClick: () => saveMeparArea(),
                            title: 'Terület mentése',
                            body: 'A korábban megrajzolt területek elvesznek. Biztosan az egész MePAR blokkot szeretné károsodottként megjelölni?',
                        })
                    }
                    disabled={drawingState === DRAWING_STATES.POLYGON_EDIT || isLoading}
                >
                    <CheckBoxOutlineBlankRounded />
                </IconButton>
            </Tooltip>
            <Tooltip title={TOOLTIP_TEXTS.save} placement="right">
                <IconButton onClick={saveFunction} disabled={isLoading}>
                    <SaveRounded />
                </IconButton>
            </Tooltip>
            <Tooltip title={TOOLTIP_TEXTS.undo} placement="right">
                <IconButton
                    onClick={() => {
                        map.drawPolygonUndo();
                    }}
                    disabled={drawingState === DRAWING_STATES.POLYGON_EDIT || isLoading}
                >
                    <UndoRounded />
                </IconButton>
            </Tooltip>
            <Tooltip title={TOOLTIP_TEXTS.cancel} placement="right">
                <IconButton
                    onClick={() => {
                        map.drawCancel();
                        setOverviewState(OVERVIEW_STATES.OPEN);
                        setDrawingState(DRAWING_STATES.NONE);
                        if (overviewState === OVERVIEW_STATES.CLOSED) {
                            setSearchParams({});
                        } else {
                            setSearchParams({
                                damagePlotId,
                            });
                        }
                    }}
                >
                    <CancelRounded />
                </IconButton>
            </Tooltip>
        </Grid>
    );
};

export default PolygonDrawControls;
