const ADMIN_MENU_KEYS = {
    POLICIES: 'POLICIES',
    DAMAGE_REPORTS: 'DAMAGE_REPORTS',
    DAMAGE_EXPERTS: 'DAMAGE_EXPERTS',
    NONE: false,
};

const ADMIN_MENU_LABELS = {
    [ADMIN_MENU_KEYS.POLICIES]: 'Kötvények',
    [ADMIN_MENU_KEYS.DAMAGE_REPORTS]: 'Káresetek',
    [ADMIN_MENU_KEYS.DAMAGE_EXPERTS]: 'Kárszakértők',
};

export { ADMIN_MENU_KEYS, ADMIN_MENU_LABELS };
