/* eslint-disable react/no-array-index-key */
import { Alert, CircularProgress, Grid, TableCell, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useApi } from '../../../../hooks/useApi';
import { formatNumberToDecimal } from '../../../../utils/formatter';
import DashboardTable from '../DashboardTable';
import { useAppControl } from '../../../../providers/AppContext';

const Suggestions = () => {
    const { year } = useAppControl();
    const [result, loading, error] = useApi(
        (api, objWithSignal) => api.dashboards.getDamagePayoutDashboard({ year }, objWithSignal),
        [year]
    );

    const [filtered, setFiltered] = useState([]);

    useEffect(() => {
        if (!result) {
            return;
        }

        setFiltered(result);
    }, [result, setFiltered]);

    if (error) {
        return (
            <Grid item>
                <Alert severity="error">Hiba történt a kérés teljesítése során</Alert>
            </Grid>
        );
    }

    if (loading) {
        return (
            <Grid item>
                <CircularProgress />
            </Grid>
        );
    }

    return (
        <Grid container item spacing={2}>
            <DashboardTable
                title={`${year} - Kártérítési javaslatok növénybontásban`}
                gridProps={{ xs: 12, lg: 6 }}
                headerRows={['Növény', 'Ft']}
                loading={loading}
                error={error}
                tableSx={{ maxHeight: 'calc(100vh - 310px)' }}
                filterable
                toFilter={result}
                setFiltered={setFiltered}
            >
                {filtered &&
                    filtered.map(({ category, payout }, i) => (
                        <TableRow key={i}>
                            <TableCell>{category}</TableCell>
                            <TableCell align="right">
                                {formatNumberToDecimal(payout, 'Ft')}
                            </TableCell>
                        </TableRow>
                    ))}
            </DashboardTable>
        </Grid>
    );
};

export default Suggestions;
