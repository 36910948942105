const formatNumberToDecimal = (value, unit = '', decimalNumber = 2) => {
    let parsedNum = value;
    if (!value) {
        return `0${unit ? ` ${unit}` : ''}`;
    }
    if (typeof parsedNum !== 'number') {
        parsedNum = parseFloat(value);
        // cannot be parsed to float
        if (Number.isNaN(parsedNum)) {
            return 'Helytelen számformátum';
        }
    }
    // TODO: extend this with case when we have no decimal numbers
    const re = `\\d(?=(\\d{${3}})+\\.)`;
    return `${parsedNum.toFixed(decimalNumber).replace(new RegExp(re, 'g'), '$& ')}${
        unit ? ` ${unit}` : ''
    }`;
};

// eslint-disable-next-line import/prefer-default-export
export { formatNumberToDecimal };
