import { CircularProgress, Grid } from '@mui/material';
import React from 'react';

const NoDataBlock = () => {
    return (
        <Grid item xs={12}>
            <CircularProgress />
        </Grid>
    );
};

export default NoDataBlock;
