import { CloseRounded, ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';
import { useMap } from 'es-map-widget';
import React, { forwardRef, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { MAP_LAYER_NAMES } from '../../constants';
import { DRAWING_STATES } from '../../constants/drawingStates';
import { OVERVIEW_STATES } from '../../constants/overviewStates';
import { useAppControl } from '../../providers/AppContext';
import { useLayout } from '../../providers/LayoutContext';
import DataBlock from './components/DataBlock';

const getOverviewHeight = (state) => {
    switch (state) {
        case OVERVIEW_STATES.OPEN_FULLSCREEN:
            return 'calc(100% - 100px)';
        case OVERVIEW_STATES.OPEN:
            return 'calc((100% - 144px) / 2.5)';
        case OVERVIEW_STATES.HIDDEN:
            return '0px';
        default:
            return '0';
    }
};

const DataOverview = (_, ref) => {
    const map = useMap();
    const { overviewState, setOverviewState, drawingState, setDrawingState } = useAppControl();
    const { selectedProduct, setSelectedProduct } = useLayout();
    const [searchParams, setSearchParams] = useSearchParams();

    const handleExpandLess = () => {
        setOverviewState((prevState) =>
            prevState === OVERVIEW_STATES.OPEN ? OVERVIEW_STATES.HIDDEN : OVERVIEW_STATES.OPEN
        );
    };

    const handleExpandMore = () => {
        setOverviewState((prevState) =>
            prevState === OVERVIEW_STATES.HIDDEN
                ? OVERVIEW_STATES.OPEN
                : OVERVIEW_STATES.OPEN_FULLSCREEN
        );
    };

    const handleClose = () => {
        setOverviewState(OVERVIEW_STATES.CLOSED);
        setSelectedProduct(null);
        setSearchParams({});
        setDrawingState(DRAWING_STATES.NONE);
        map.setLayerData(MAP_LAYER_NAMES.FIELDS, { geoms: [] });
        map.setLayerData(MAP_LAYER_NAMES.SAMPLING, { geoms: [] });
        map.setLayerData(MAP_LAYER_NAMES.ZONE, { geoms: [] });
        map.drawCancel();
    };

    useEffect(() => {
        if (
            (!!selectedProduct &&
                Object.values(DRAWING_STATES)
                    .filter((e) => e !== DRAWING_STATES.NONE)
                    .includes(drawingState)) ||
            (drawingState === DRAWING_STATES.NONE && searchParams.get('damagePlotId') === null)
        ) {
            return;
        }

        setOverviewState(OVERVIEW_STATES.OPEN);
    }, [selectedProduct, setOverviewState, searchParams]);

    return (
        overviewState !== OVERVIEW_STATES.CLOSED && (
            <Grid
                container
                ref={ref}
                spacing={0}
                sx={{
                    display: !overviewState ? 'none' : 'block',
                    position: 'absolute',
                    zIndex: 1080,
                    width: 'calc(100% - 16px)',
                    marginLeft: '16px',
                    height: getOverviewHeight(overviewState),
                    bottom: 15,
                }}
            >
                <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                    sx={{
                        width: '100%',
                        backgroundColor: '#F0F4F7',
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px',
                        height: '28px',
                        '& button:last-child': {
                            marginRight: '10px',
                        },
                    }}
                >
                    <IconButton
                        size="small"
                        disabled={overviewState === OVERVIEW_STATES.HIDDEN}
                        onClick={handleExpandLess}
                    >
                        <ExpandMoreRounded fontSize="10px" />
                    </IconButton>
                    <IconButton
                        size="small"
                        disabled={overviewState === OVERVIEW_STATES.OPEN_FULLSCREEN}
                        onClick={handleExpandMore}
                    >
                        <ExpandLessRounded fontSize="10px" />
                    </IconButton>
                    <IconButton size="small" onClick={handleClose}>
                        <CloseRounded fontSize="10px" />
                    </IconButton>
                </Grid>
                <DataBlock />
            </Grid>
        )
    );
};

export default forwardRef(DataOverview);
