import { CONTROLLER_KEYS, CONTROLLER_LABELS, ROLE_GROUPS } from '../../../constants';
import KarnaviController from '../maincontrollers/KarnaviController';
import MonitoringController from '../maincontrollers/MonitoringController';
import PlantController from '../maincontrollers/PlantController';
import RadarController from '../maincontrollers/RadarController';
import CitiesController from '../maincontrollers/CitiesController';
import RiskFactorController from '../maincontrollers/RiskFactorController';
import YieldController from '../maincontrollers/YieldController';

const menuItems = [
    {
        key: CONTROLLER_KEYS.MONITORING,
        label: CONTROLLER_LABELS.MONITORING,
        menuKey: CONTROLLER_KEYS.MONITORING,
        component: MonitoringController,
        permissions: [],
    },
    {
        key: CONTROLLER_KEYS.YIELD,
        label: CONTROLLER_LABELS.YIELD_MAP,
        menuKey: [CONTROLLER_KEYS.YIELD_REFERENCE, CONTROLLER_KEYS.YIELD_CALCULATED],
        component: YieldController,
        permissions: [],
    },
    {
        key: CONTROLLER_KEYS.RISK_FACTOR,
        label: CONTROLLER_LABELS.RISK_FACTOR,
        menuKey: [CONTROLLER_KEYS.FROST, CONTROLLER_KEYS.DAMAGE_CHRONOLOGY],
        component: RiskFactorController,
        permissions: [...ROLE_GROUPS.FROST_PRODUCTS],
    },
    {
        key: CONTROLLER_KEYS.PLANT,
        label: CONTROLLER_LABELS.PLANT,
        menuKey: [CONTROLLER_KEYS.PLANT, CONTROLLER_KEYS.CROP],
        component: PlantController,
        permissions: [],
    },
    {
        key: CONTROLLER_KEYS.RADAR,
        label: CONTROLLER_LABELS.RADAR_MENU,
        menuKey: CONTROLLER_KEYS.RADAR,
        component: RadarController,
        permissions: [...ROLE_GROUPS.ADMIN_PRODUCTS],
    },
    {
        key: CONTROLLER_KEYS.KARNAVI_PHOTOS,
        label: CONTROLLER_LABELS.KARNAVI_PHOTOS_MENU,
        menuKey: CONTROLLER_KEYS.KARNAVI_PHOTOS,
        component: KarnaviController,
        permissions: [],
    },
    {
        key: CONTROLLER_KEYS.CITIES,
        label: CONTROLLER_LABELS.CITIES,
        menuKey: CONTROLLER_KEYS.CITIES,
        component: CitiesController,
        permissions: [],
    },
];

export default menuItems;
