import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import LinkButton from '../components/atom/LinkButton';

const AccessDenied = () => {
    const navigate = useNavigate();
    return (
        <Grid
            sx={{ height: '100%' }}
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
        >
            <Grid item textAlign="center">
                <Typography variant="h6">
                    Nincs jogosultságod ennek az oldalnak a megtekintéséhez.
                </Typography>
            </Grid>
            <Grid item>
                <LinkButton onClick={() => navigate('/')}>Kezdőlap</LinkButton>
            </Grid>
        </Grid>
    );
};

export default AccessDenied;
