import { ClearRounded, SearchRounded } from '@mui/icons-material';
import {
    Alert,
    CircularProgress,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import useDebounce from '../../../hooks/useDebounce';

const DashboardTableBody = ({ loading, error, children }) => {
    if (loading) {
        return (
            <TableRow>
                <TableCell colSpan={2} align="center">
                    <CircularProgress />
                </TableCell>
            </TableRow>
        );
    }

    if (error) {
        return (
            <TableRow>
                <TableCell colSpan={2} align="center">
                    <Alert severity="error">Hiba történt az adatok betöltése során</Alert>
                </TableCell>
            </TableRow>
        );
    }

    if (!children || children?.length === 0) {
        return (
            <TableRow>
                <TableCell colSpan={2}>Nincs adat</TableCell>
            </TableRow>
        );
    }

    return children;
};

const DashboardTable = ({
    title,
    children,
    gridProps,
    toFilter,
    setFiltered,
    filterable = false,
    tableSx = {},
    loading,
    headerRows,
    error,
}) => {
    const [searchValue, setSearchValue] = useState('');
    const [value, setValue] = useState('');

    useEffect(() => {
        setSearchValue(value);
    }, [value, setSearchValue]);

    useDebounce(() => setValue(searchValue), [searchValue, setValue], 300);

    useEffect(() => {
        if (!toFilter) {
            return;
        }
        if (!value) {
            setFiltered(toFilter);
            return;
        }
        setFiltered(toFilter.filter((e) => e.category.toLowerCase().includes(value.toLowerCase())));
    }, [value, setFiltered]);

    return (
        <Grid item {...gridProps}>
            <Typography variant="h5" sx={{ mb: 1.5 }}>
                {title}
            </Typography>
            {filterable && (
                <TextField
                    label="Keresés a táblázatban"
                    size="small"
                    fullWidth
                    value={searchValue}
                    onChange={(event) => setSearchValue(event.target.value)}
                    InputProps={{
                        sx: { fontSize: '14px' },
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchRounded fontSize="small" />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setSearchValue('')}>
                                    <ClearRounded fontSize="small" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    InputLabelProps={{ sx: { fontSize: '14px' } }}
                    sx={{ mb: 1.5 }}
                />
            )}
            <TableContainer component={Paper} sx={tableSx}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {headerRows.map((rowName, idx) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <TableCell key={idx} align={`${idx !== 0 ? 'right' : 'left'}`}>
                                    {rowName}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <DashboardTableBody loading={loading} error={error}>
                            {children}
                        </DashboardTableBody>
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
};

export default DashboardTable;
