import { FormControl, FormGroup, Grid, RadioGroup, TextField } from '@mui/material';
import { StaticDatePicker } from '@mui/x-date-pickers';
import { format, getDate, getMonth } from 'date-fns';
import { useMap } from 'es-map-widget';
import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { BASE_COLORS, CONTROLLER_KEYS, CONTROLLER_LABELS } from '../../../constants';
import { MAP_LAYER_NAMES } from '../../../constants/mapLayerNames';
import { useApi } from '../../../hooks/useApi';
import { useAppControl } from '../../../providers/AppContext';
import generateLayerString from '../../../utils/generateLayerString';
import { useWmsDates } from '../contexts/WmsDateProvider';
import SubMenuRadio from '../menu/SubMenuRadio';

const FieldWmsDataController = ({ isLayerVisible }) => {
    const map = useMap();
    const { wmsData, setWmsData, wmsMonth, setWmsMonth, setWmsDownload } = useWmsDates();
    const { year } = useAppControl();

    const [minDate, _setMinDate] = useState(new Date(year, 0, 1));
    const [maxDate, _setMaxDate] = useState(new Date(year, 11, 31));
    const [_monitoringDates, _setMonitoringDates] = useState([]);
    const [searchParams] = useSearchParams();
    const [selectedData, setSelectedData] = useState(null);
    const damagePlotId = searchParams.get('damagePlotId');

    const setFieldWmsData = useCallback(
        (newValue) => {
            const { layerStringCode, layerStringDate } = generateLayerString({
                type: newValue.type,
                meparCode: wmsData.meparCode,
                damagePlotId,
                damageId: wmsData.damageId,
                date: newValue.date,
                changeMap: wmsData.changeMap,
                damageMap: wmsData.damageMap,
            });
            setWmsData((pV) => ({
                ...pV,
                isLayerVisible,
                date: newValue.date,
                type: newValue.type,
                layerStringCode,
                layerStringDate,
            }));
            map.setLayerVisibility(MAP_LAYER_NAMES.NDVI, isLayerVisible);
            map.setLayerData(MAP_LAYER_NAMES.NDVI, {
                isLayerVisible,
                date: newValue.date,
                layerStringCode,
                layerStringDate,
            });
        },
        [map, setWmsData, wmsData, isLayerVisible, damagePlotId]
    );

    const [dates, loading, error] = useApi(
        (api, params) =>
            api.layers.getMapWmsDates(
                {
                    meparCode: wmsData.meparCode,
                    year,
                    month: wmsMonth + 1,
                    damageId: wmsData.damageId,
                    damagePlotId: wmsData.damagePlotId,
                },
                { ...params }
            ),
        [wmsMonth, year, wmsData.meparCode, wmsData.damageId, wmsData.damagePlotId, isLayerVisible],
        (x) => x,
        !isLayerVisible
    );

    const createDate = useCallback(
        (date) => {
            return new Date(
                year,
                getMonth(date) || wmsMonth,
                date?.getDate() || wmsData.date.getDate()
            );
        },
        [wmsMonth, wmsData.date, year]
    );

    useEffect(() => {
        // setMinDate(new Date(year, 0, 1));
        // setMaxDate(new Date(year, 11, 31));
        setWmsData((prevData) => ({
            ...prevData,
            date: new Date(year, getMonth(prevData.date), getDate(prevData.date)),
        }));
    }, [setWmsData, year]);

    useEffect(() => {
        if (!dates) {
            return;
        }

        setSelectedData(
            dates.find((date) => date.date === format(wmsData.date, 'yyyy-MM-dd')) || null
        );
    }, [dates, wmsData]);

    useEffect(() => {
        if (!selectedData || !isLayerVisible) {
            setWmsDownload({
                ndvi: false,
                visible: false,
                heterogeneity: false,
            });
            return;
        }

        setWmsDownload({
            ndvi: selectedData?.ndvi || false,
            visible: selectedData?.visible || false,
            heterogeneity: selectedData?.heterogeneity || false,
        });
    }, [selectedData, setWmsData, isLayerVisible]);

    return (
        <Grid container justifyContent="flex-start" alignItems="flex-start">
            <Grid item sx={{ mb: 1 }}>
                <FormGroup>
                    <FormControl>
                        <RadioGroup
                            defaultValue={CONTROLLER_KEYS.ALERT_NDVI}
                            name="map-controller-radio-group"
                            value={wmsData.type}
                            onChange={(event) => {
                                setFieldWmsData({
                                    isLayerVisible: !isLayerVisible,
                                    date: wmsData.date,
                                    type: event.target.value,
                                    download: true,
                                });
                            }}
                        >
                            <SubMenuRadio
                                value={CONTROLLER_KEYS.NDVI}
                                label={CONTROLLER_LABELS.NDVI}
                                disabled={!isLayerVisible || !selectedData?.ndvi}
                            />
                            <SubMenuRadio
                                value={CONTROLLER_KEYS.VISIBLE_IMAGE}
                                label={CONTROLLER_LABELS.VISIBLE_IMAGE}
                                disabled={!isLayerVisible || !selectedData?.visible}
                            />
                            <SubMenuRadio
                                value={CONTROLLER_KEYS.FIELD_WMS}
                                label={CONTROLLER_LABELS.FIELD_WMS}
                                disabled={!isLayerVisible || !selectedData?.heterogeneity}
                            />
                        </RadioGroup>
                    </FormControl>
                </FormGroup>
            </Grid>
            <Grid item container justifyContent="center">
                <StaticDatePicker
                    views={['day']}
                    inputFormat="yyyy-MM-dd"
                    displayStaticWrapperAs="desktop"
                    // disableFuture
                    minDate={minDate}
                    maxDate={maxDate}
                    value={wmsData.date}
                    loading={loading && !error}
                    onChange={(date) => {
                        setFieldWmsData({
                            ...wmsData,
                            date,
                        });
                    }}
                    shouldDisableDate={(date) => {
                        return !(dates || [])
                            .map((e) =>
                                Object.entries(e).some(([key, value]) => key !== 'date' && !!value)
                                    ? e.date
                                    : false
                            )
                            .filter((e) => e)
                            .includes(format(createDate(date), 'yyyy-MM-dd'));
                    }}
                    onMonthChange={(m) => setWmsMonth(getMonth(m))}
                    renderInput={(params) => <TextField {...params} />}
                    sx={{
                        backgroundColor: BASE_COLORS.PRIMARY_MAIN,
                    }}
                    disabled={!isLayerVisible}
                />
            </Grid>
        </Grid>
    );
};

export default FieldWmsDataController;
