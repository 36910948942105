import { Grid, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import TabPanel from './TabPanel';
import DamageSummary from './reports/DamageSummary';
import DamagedPlants from './reports/DamagedPlants';
import Damages from './reports/Damages';
import Suggestions from './reports/Suggestions';

const AdminDamageReports = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const tabIndex = searchParams.get('tabIndex');
    const [tabValue, setTabValue] = useState(tabIndex ? parseInt(tabIndex, 10) : 0);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
        setSearchParams({ ...searchParams, tabIndex: newValue });
    };
    useEffect(() => {
        if (searchParams?.tabIndex) {
            return;
        }
        setSearchParams({ ...searchParams, tabIndex: 0 });
    }, []);

    return (
        <Grid container sx={{ flexGrow: 1, height: '100%' }} alignContent="flex-start">
            <Grid item sx={{ borderBottom: 1, borderColor: 'divider' }} xs={12}>
                <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                >
                    <Tab label="Áttekintő" />
                    <Tab label="Károsodott növények" />
                    <Tab label="Kártérítési javaslatok" />
                    <Tab label="Károk" />
                </Tabs>
            </Grid>
            <Grid item xs={12}>
                <TabPanel value={tabValue} index={0}>
                    <DamageSummary />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <DamagedPlants />
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                    <Suggestions />
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                    <Damages />
                </TabPanel>
            </Grid>
        </Grid>
    );
};

export default AdminDamageReports;
