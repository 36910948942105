import { FileDownloadRounded, InfoRounded } from '@mui/icons-material';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import React from 'react';

const MeparBlock = () => {
    return (
        <Grid container item xs={6} direction="column" gap={1} sx={{ p: 1 }}>
            <Grid item xs={12}>
                <Typography variant="h5">Letölthető térképek (2022.01.01.)</Typography>
            </Grid>
            <Grid item container justifyContent="space-between">
                <Grid item alignSelf="center">
                    NDVI/látható kép
                    <Tooltip title="NDVI" placement="right">
                        <IconButton>
                            <InfoRounded />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <IconButton>
                        <FileDownloadRounded />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid item container justifyContent="space-between">
                <Grid item alignSelf="center">
                    Változás térkép
                    <Tooltip title="Változás térkép" placement="right">
                        <IconButton>
                            <InfoRounded />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <IconButton>
                        <FileDownloadRounded />
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default MeparBlock;
