/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import axios from 'axios';
export var ContentType;
(function (ContentType) {
    ContentType['Json'] = 'application/json';
    ContentType['FormData'] = 'multipart/form-data';
    ContentType['UrlEncoded'] = 'application/x-www-form-urlencoded';
    ContentType['Text'] = 'text/plain';
})(ContentType || (ContentType = {}));
export class HttpClient {
    instance;
    securityData = null;
    securityWorker;
    secure;
    format;
    constructor({ securityWorker, secure, format, ...axiosConfig } = {}) {
        this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '/api' });
        this.secure = secure;
        this.format = format;
        this.securityWorker = securityWorker;
    }
    setSecurityData = (data) => {
        this.securityData = data;
    };
    mergeRequestParams(params1, params2) {
        const method = params1.method || (params2 && params2.method);
        return {
            ...this.instance.defaults,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...((method && this.instance.defaults.headers[method.toLowerCase()]) || {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {}),
            },
        };
    }
    stringifyFormItem(formItem) {
        if (typeof formItem === 'object' && formItem !== null) {
            return JSON.stringify(formItem);
        } else {
            return `${formItem}`;
        }
    }
    createFormData(input) {
        return Object.keys(input || {}).reduce((formData, key) => {
            const property = input[key];
            const propertyContent = property instanceof Array ? property : [property];
            for (const formItem of propertyContent) {
                const isFileType = formItem instanceof Blob || formItem instanceof File;
                formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
            }
            return formData;
        }, new FormData());
    }
    request = async ({ secure, path, type, query, format, body, ...params }) => {
        const secureParams =
            ((typeof secure === 'boolean' ? secure : this.secure) &&
                this.securityWorker &&
                (await this.securityWorker(this.securityData))) ||
            {};
        const requestParams = this.mergeRequestParams(params, secureParams);
        const responseFormat = format || this.format || undefined;
        if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
            body = this.createFormData(body);
        }
        if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
            body = JSON.stringify(body);
        }
        return this.instance.request({
            ...requestParams,
            headers: {
                ...(requestParams.headers || {}),
                ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
            },
            params: query,
            responseType: responseFormat,
            data: body,
            url: path,
        });
    };
}
/**
 * @title Georisk API
 * @version 1.5.2
 * @baseUrl /api
 */
export class Api extends HttpClient {
    users = {
        /**
         * No description
         *
         * @tags Users
         * @name LoginUser
         * @summary Get user data and token for user identified by username and password
         * @request POST:/users/login
         * @response `200` `UserWithToken`
         * @response `default` `Error`
         */
        loginUser: (data, params = {}) =>
            this.request({
                path: `/users/login`,
                method: 'POST',
                body: data,
                ...params,
            }),
        /**
         * No description
         *
         * @tags Users
         * @name GetCurrentUser
         * @summary Get data for current user
         * @request GET:/users/me
         * @secure
         * @response `200` `User`
         * @response `default` `Error`
         */
        getCurrentUser: (params = {}) =>
            this.request({
                path: `/users/me`,
                method: 'GET',
                secure: true,
                ...params,
            }),
        /**
         * No description
         *
         * @tags Users
         * @name UpdateCurrentUser
         * @summary Update data for current user
         * @request PATCH:/users/me
         * @secure
         * @response `200` `User`
         * @response `default` `Error`
         */
        updateCurrentUser: (data, params = {}) =>
            this.request({
                path: `/users/me`,
                method: 'PATCH',
                body: data,
                secure: true,
                ...params,
            }),
        /**
         * No description
         *
         * @tags Users
         * @name ChangePassword
         * @summary Change password for the current user
         * @request PATCH:/users/me/password
         * @secure
         * @response `200` `User`
         * @response `default` `Error`
         */
        changePassword: (data, params = {}) =>
            this.request({
                path: `/users/me/password`,
                method: 'PATCH',
                body: data,
                secure: true,
                ...params,
            }),
        /**
         * No description
         *
         * @tags Users
         * @name RegisterUser
         * @summary Register user with username, email, name and password
         * @request POST:/users/register
         * @response `200` `UserWithToken`
         * @response `default` `Error`
         */
        registerUser: (data, params = {}) =>
            this.request({
                path: `/users/register`,
                method: 'POST',
                body: data,
                ...params,
            }),
        /**
 * No description
 *
 * @tags Users
 * @name ListUsers
 * @summary Get a paginated list of users, and the total number of them
 * @request GET:/users
 * @secure
 * @response `200` `{
    total: number,
    users: (User)[],

}`
 * @response `default` `Error`
 */
        listUsers: (query, params = {}) =>
            this.request({
                path: `/users`,
                method: 'GET',
                query: query,
                secure: true,
                ...params,
            }),
    };
    search = {
        /**
         * @description Szabadszavas kereső az alábbi entitásokra: damages, damageBlocks, policies, policyBlocks, blocks.
         *
         * @tags Search
         * @name SearchAll
         * @summary searchController/search
         * @request GET:/search
         * @secure
         * @response `200` `(SearchItem)[]`
         * @response `default` `Error`
         */
        searchAll: (query, params = {}) =>
            this.request({
                path: `/search`,
                method: 'GET',
                query: query,
                secure: true,
                ...params,
            }),
    };
    alert = {
        /**
         * @description Vissazadja az authentikált userhez tartozó riasztásokat.
         *
         * @tags Alert
         * @name GetAlerts
         * @summary alertController/getAlerts
         * @request GET:/alert
         * @secure
         * @response `200` `(Alert)[]`
         * @response `default` `Error`
         */
        getAlerts: (params = {}) =>
            this.request({
                path: `/alert`,
                method: 'GET',
                secure: true,
                ...params,
            }),
    };
    blocks = {
        /**
 * @description Egy MePAR blokk adatait adja vissza a hozzátartozó kötvény-, kár- és termékadatokkal.
 *
 * @tags Block
 * @name GetBlock
 * @summary blockController/getBlock
 * @request GET:/blocks/{meparCode}
 * @secure
 * @response `200` `(Mepar & {
    damages?: (Damage)[],
    policies?: (Policy)[],

})`
 * @response `default` `Error`
 */
        getBlock: ({ meparCode, ...query }, params = {}) =>
            this.request({
                path: `/blocks/${meparCode}`,
                method: 'GET',
                query: query,
                secure: true,
                ...params,
            }),
    };
    damages = {
        /**
 * @description Kárszám alapján visszaad egy kárt a hozzá tartozó károsodott területekkel, fotóadatokkal és hozamadatokkal.
 *
 * @tags Damages
 * @name GetDamage
 * @summary damageController/getDamage
 * @request GET:/damages/{damageNumber}
 * @secure
 * @response `200` `(Damage & {
    policyYields?: (PolicyYields)[],
    damagePhotos?: (DamagePhoto)[],
    damagePlots?: (DamagePlotWithJoins)[],
    expert?: (User | null | (User & null)),

})`
 * @response `default` `Error`
 */
        getDamage: ({ damageNumber, ...query }, params = {}) =>
            this.request({
                path: `/damages/${damageNumber}`,
                method: 'GET',
                query: query,
                secure: true,
                ...params,
            }),
        /**
         * @description Kárnavigátorból érkező káradatokat upsert-eli a Damage táblába, a kárhoz tartozó DamagePlotokat is update-eli, majd a DamagePlot-hoz tartozó Mepar-t is.
         *
         * @tags Karnavigator
         * @name UpdateDamages
         * @summary damageController/updateDamages
         * @request POST:/damages
         * @secure
         * @response `200` `Status`
         * @response `default` `Error`
         */
        updateDamages: (data, params = {}) =>
            this.request({
                path: `/damages`,
                method: 'POST',
                body: data,
                secure: true,
                ...params,
            }),
    };
    createDamage = {
        /**
         * @description Kár létrehozása. Ha már létezik olyan kárszám, ami a kérésben van beadva, akkor az módosításra kerül.
         *
         * @tags Damages
         * @name CreateDamage
         * @summary damageController/createDamage
         * @request POST:/createDamage
         * @secure
         * @response `200` `Status`
         * @response `default` `Error`
         */
        createDamage: (data, params = {}) =>
            this.request({
                path: `/createDamage`,
                method: 'POST',
                body: data,
                secure: true,
                ...params,
            }),
    };
    createDamageFromExcel = {
        /**
         * @description Károk létrehozása excel file-ból. Ha már létezik olyan kárszám, ami a kérésben van beadva, akkor az módosításra kerül.
         *
         * @tags Damages
         * @name CreateDamageFromExcel
         * @summary damageController/createDamageFromExcel
         * @request POST:/createDamageFromExcel
         * @secure
         * @response `200` `Status`
         * @response `default` `Error`
         */
        createDamageFromExcel: (data, params = {}) =>
            this.request({
                path: `/createDamageFromExcel`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.FormData,
                ...params,
            }),
    };
    damagePlots = {
        /**
         * @description Mintavételi pontok mentése a rögzített mintavételi értékekkel.
         *
         * @tags DamagePlots
         * @name SaveSampling
         * @summary damageController/saveSampling
         * @request POST:/damagePlots/sampling
         * @secure
         * @response `200` `(Sampling)[]`
         * @response `default` `Error`
         */
        saveSampling: (data, params = {}) =>
            this.request({
                path: `/damagePlots/sampling`,
                method: 'POST',
                body: data,
                secure: true,
                ...params,
            }),
        /**
 * @description A károsodott táblára vonatkozó hozamszámítást indítja el.
 *
 * @tags DamagePlots
 * @name CalculateYield
 * @summary damageController/calculateYield
 * @request POST:/damagePlots/calculateYield/{id}
 * @secure
 * @response `200` `{
  \** A kiszámított hozam t/ha-ban *\
    calculatedYield?: number,

}`
 * @response `default` `Error`
 */
        calculateYield: (id, params = {}) =>
            this.request({
                path: `/damagePlots/calculateYield/${id}`,
                method: 'POST',
                secure: true,
                ...params,
            }),
        /**
         * @description Mintavételi zónák generálását indítja el egy károsodott táblára.
         *
         * @tags DamagePlots
         * @name GenerateZones
         * @summary damageController/generateZones
         * @request POST:/damagePlots/generateZones/{id}
         * @secure
         * @response `200` `Status`
         * @response `default` `Error`
         */
        generateZones: (id, params = {}) =>
            this.request({
                path: `/damagePlots/generateZones/${id}`,
                method: 'POST',
                secure: true,
                ...params,
            }),
        /**
 * @description Egy károsodott tábla adatait adja vissza. Opcionálisan a berajzolt táblahatárral.
 *
 * @tags DamagePlots
 * @name GetDamagePlot
 * @summary damageController/getDamagePlot
 * @request GET:/damagePlots/{id}
 * @secure
 * @response `200` `(DamagePlotWithJoins & {
    sampling?: (Sampling)[],
    samplingZones?: (SamplingZone)[],
    changeMap?: ChangeMap,
    damageMap?: DamageMap,

})`
 * @response `default` `Error`
 */
        getDamagePlot: (id, params = {}) =>
            this.request({
                path: `/damagePlots/${id}`,
                method: 'GET',
                secure: true,
                ...params,
            }),
        /**
         * @description Egy károsodott táblahoz visszaadja a berajzolt területeket.
         *
         * @tags DamagePlots
         * @name GetDamagePlotAreas
         * @summary damageController/getDamagePlotAreas
         * @request GET:/damagePlots/{id}/areas
         * @secure
         * @response `200` `(DamagePlotArea)[]`
         * @response `default` `Error`
         */
        getDamagePlotAreas: (id, params = {}) =>
            this.request({
                path: `/damagePlots/${id}/areas`,
                method: 'GET',
                secure: true,
                ...params,
            }),
        /**
         * @description Egy károsodott táblához berajzolt területet update-eli.
         *
         * @tags DamagePlots
         * @name SaveDamagePlotArea
         * @summary damageController/saveDamagePlotArea
         * @request POST:/damagePlots/{damagePlotId}/areas/save/{id}
         * @secure
         * @response `200` `DamagePlotArea`
         * @response `default` `Error`
         */
        saveDamagePlotArea: (damagePlotId, id, data, params = {}) =>
            this.request({
                path: `/damagePlots/${damagePlotId}/areas/save/${id}`,
                method: 'POST',
                body: data,
                secure: true,
                ...params,
            }),
        /**
         * @description Egy károsodott táblához hozzáad egy új területet.
         *
         * @tags DamagePlots
         * @name CreateDamagePlotArea
         * @summary damageController/saveDamagePlotArea
         * @request POST:/damagePlots/{damagePlotId}/areas/save/
         * @secure
         * @response `200` `DamagePlotArea`
         * @response `default` `Error`
         */
        createDamagePlotArea: (damagePlotId, data, params = {}) =>
            this.request({
                path: `/damagePlots/${damagePlotId}/areas/save/`,
                method: 'POST',
                body: data,
                secure: true,
                ...params,
            }),
        /**
         * @description Egy károsodott táblához az egész MePAR-t beállítja táblahatárnak. Ha voltak korábban berajzolt területek, akkor törli őket a db-ből.
         *
         * @tags DamagePlots
         * @name SaveMeparAsArea
         * @summary damageController/saveMeparAsArea
         * @request POST:/damagePlots/{damagePlotId}/areas/saveMeparAsArea
         * @secure
         * @response `200` `DamagePlotArea`
         * @response `default` `Error`
         */
        saveMeparAsArea: (damagePlotId, params = {}) =>
            this.request({
                path: `/damagePlots/${damagePlotId}/areas/saveMeparAsArea`,
                method: 'POST',
                secure: true,
                ...params,
            }),
        /**
 * @description Egy károsodott táblához berajzolt területet töröl.
 *
 * @tags DamagePlots
 * @name RemoveDamagePlotArea
 * @summary damageController/removeDamagePlotArea
 * @request POST:/damagePlots/{damagePlotId}/areas/remove/{id}
 * @secure
 * @response `200` `{
  \** A törölt mintavételi pont sorszáma/törölt táblahatár id-ja *\
    removed?: number,

}`
 * @response `default` `Error`
 */
        removeDamagePlotArea: (damagePlotId, id, params = {}) =>
            this.request({
                path: `/damagePlots/${damagePlotId}/areas/remove/${id}`,
                method: 'POST',
                secure: true,
                ...params,
            }),
    };
    layers = {
        /**
         * @description Egy közigazgatási egység adatainak és geom-jának lekérése
         *
         * @tags Layers
         * @name GetGeometry
         * @summary layerController/getGeometry
         * @request GET:/layers/geometry/{id}
         * @secure
         * @response `200` `Geometry`
         * @response `default` `Error`
         */
        getGeometry: (id, params = {}) =>
            this.request({
                path: `/layers/geometry/${id}`,
                method: 'GET',
                secure: true,
                ...params,
            }),
        /**
 * No description
 *
 * @tags Layers
 * @name GetCropMapLayer
 * @summary Extent alapú szűrés a vetéstérképhez.
 * @request GET:/layers/cropMap
 * @secure
 * @response `200` `({
    id?: number,
    crop?: number,
  \** geometry in geoJSON format *\
    geom?: Geom,
  \**
   * @format date
   * @example "2023-01-01"
   *\
    date?: string,
    area?: number,

})[]`
 * @response `default` `Error`
 */
        getCropMapLayer: (query, params = {}) =>
            this.request({
                path: `/layers/cropMap`,
                method: 'GET',
                query: query,
                secure: true,
                ...params,
            }),
        /**
 * No description
 *
 * @tags Layers
 * @name GetDamageChronology
 * @summary Get damage chronology layer data for maps with extents
 * @request GET:/layers/damageChronology
 * @secure
 * @response `200` `({
    id?: number,
    value?: number,
  \** geometry in geoJSON format *\
    geom?: Geom,
  \**
   * @format date-time
   * @example "2023-01-19T11:55:01Z"
   *\
    date?: string,

})[]`
 * @response `default` `Error`
 */
        getDamageChronology: (query, params = {}) =>
            this.request({
                path: `/layers/damageChronology`,
                method: 'GET',
                query: query,
                secure: true,
                ...params,
            }),
        /**
 * No description
 *
 * @tags Layers
 * @name GetFrostDamageChronology
 * @summary Get frost damage chronology layer data for maps with extents
 * @request GET:/layers/frostDamageChronology
 * @secure
 * @response `200` `({
    id?: number,
    value?: number,
  \** geometry in geoJSON format *\
    geom?: Geom,
  \**
   * @format date-time
   * @example "2023-01-19T11:55:01Z"
   *\
    date?: string,

})[]`
 * @response `default` `Error`
 */
        getFrostDamageChronology: (query, params = {}) =>
            this.request({
                path: `/layers/frostDamageChronology`,
                method: 'GET',
                query: query,
                secure: true,
                ...params,
            }),
        /**
 * No description
 *
 * @tags Layers
 * @name ListMeparsForFrost
 * @summary List all Mepars
 * @request GET:/layers/frost/mepars
 * @secure
 * @response `200` `({
    id?: number,
    code?: string,
  \** @example 1.78571428571 *\
    frost?: number | null,
    layerName?: string,
  \** geometry in geoJSON format *\
    geom?: Geom,

})[]`
 * @response `default` `Error`
 */
        listMeparsForFrost: (query, params = {}) =>
            this.request({
                path: `/layers/frost/mepars`,
                method: 'GET',
                query: query,
                secure: true,
                ...params,
            }),
        /**
 * No description
 *
 * @tags Layers
 * @name ListMepars
 * @summary List all Mepars
 * @request GET:/layers/mepars
 * @secure
 * @response `200` `({
    id?: number,
    code?: string,
  \** @example 1.78571428571 *\
    frost?: number | null,
    layerName?: string,
  \** geometry in geoJSON format *\
    geom?: Geom,

})[]`
 * @response `default` `Error`
 */
        listMepars: (query, params = {}) =>
            this.request({
                path: `/layers/mepars`,
                method: 'GET',
                query: query,
                secure: true,
                ...params,
            }),
        /**
 * No description
 *
 * @tags Layers
 * @name ListCities
 * @summary List all Mepars
 * @request GET:/layers/cities
 * @secure
 * @response `200` `({
    id?: number,
    layerName?: string,
  \** geometry in geoJSON format *\
    geom?: Geom,

})[]`
 * @response `default` `Error`
 */
        listCities: (query, params = {}) =>
            this.request({
                path: `/layers/cities`,
                method: 'GET',
                query: query,
                secure: true,
                ...params,
            }),
        /**
 * No description
 *
 * @tags Layers
 * @name GetRadarLayer
 * @summary Errőforrás allokáció felmérése radar adatok segítségével
 * @request GET:/layers/radar
 * @secure
 * @response `200` `({
    id?: number,
  \** geometry in geoJSON format *\
    geom?: Geom,
  \**
   * @format date-time
   * @example "2023-01-19T11:55:01Z"
   *\
    dateRadar?: string,
    idDay?: number,
    year?: number | null,

})[]`
 * @response `default` `Error`
 */
        getRadarLayer: (query, params = {}) =>
            this.request({
                path: `/layers/radar`,
                method: 'GET',
                query: query,
                secure: true,
                ...params,
            }),
        /**
         * No description
         *
         * @tags Layers
         * @name GetRadarDates
         * @summary Azoknak a napoknak a listája egy hónapra, amikre van erőforrás allokációs adat
         * @request GET:/layers/radar/dates
         * @secure
         * @response `200` `(string)[]`
         * @response `default` `Error`
         */
        getRadarDates: (query, params = {}) =>
            this.request({
                path: `/layers/radar/dates`,
                method: 'GET',
                query: query,
                secure: true,
                ...params,
            }),
        /**
 * No description
 *
 * @tags Layers
 * @name ListMeparsForRadarDay
 * @summary Errőforrás allokáció felmérése radar adatok segítségével
 * @request GET:/layers/radar/mepars
 * @secure
 * @response `200` `({
    id?: number,
    code?: string,
  \** @example 1.78571428571 *\
    frost?: number | null,
    layerName?: string,
  \** geometry in geoJSON format *\
    geom?: Geom,

})[]`
 * @response `default` `Error`
 */
        listMeparsForRadarDay: (query, params = {}) =>
            this.request({
                path: `/layers/radar/mepars`,
                method: 'GET',
                query: query,
                secure: true,
                ...params,
            }),
        /**
 * No description
 *
 * @tags Layers
 * @name GetMeparCountForRadarDay
 * @summary Errőforrás allokáció felmérése radar adatok segítségével
 * @request GET:/layers/radar/mepars/count
 * @secure
 * @response `200` `{
    count: number,

}`
 * @response `default` `Error`
 */
        getMeparCountForRadarDay: (query, params = {}) =>
            this.request({
                path: `/layers/radar/mepars/count`,
                method: 'GET',
                query: query,
                secure: true,
                ...params,
            }),
        /**
         * No description
         *
         * @tags Layers
         * @name GetRadarDatesForMepar
         * @summary Radar dátumok a káreset dátumához képest +/- 1 hónapra
         * @request GET:/layers/radar/{meparCode}/dates
         * @secure
         * @response `200` `(string)[]`
         * @response `default` `Error`
         */
        getRadarDatesForMepar: ({ meparCode, ...query }, params = {}) =>
            this.request({
                path: `/layers/radar/${meparCode}/dates`,
                method: 'GET',
                query: query,
                secure: true,
                ...params,
            }),
        /**
 * No description
 *
 * @tags Layers
 * @name GetYieldsLayer
 * @summary Extent alapú szűrés a vetéstérképhez.
 * @request GET:/layers/yields
 * @secure
 * @response `200` `({
    id?: number,
  \** geometry in geoJSON format *\
    geom?: Geom,
    objectType?: string,
    code?: string,
    type?: string,
    plant?: string,
    yield?: number,
    year?: number,

})[]`
 * @response `default` `Error`
 */
        getYieldsLayer: (query, params = {}) =>
            this.request({
                path: `/layers/yields`,
                method: 'GET',
                query: query,
                secure: true,
                ...params,
            }),
        /**
         * No description
         *
         * @tags Layers
         * @name GetMapTiles
         * @summary Tileos rétegek lekérése
         * @request GET:/layers/tiles/{type}/{z}/{x}/{y}/image.png
         * @secure
         * @response `200` `File`
         * @response `default` `Error`
         */
        getMapTiles: (type, x, y, z, params = {}) =>
            this.request({
                path: `/layers/tiles/${type}/${z}/${x}/${y}/image.png`,
                method: 'GET',
                secure: true,
                ...params,
            }),
        /**
         * No description
         *
         * @tags Layers
         * @name GetMapWms
         * @summary Get WMS data for maps
         * @request GET:/layers/wms/{meparCode}
         * @secure
         * @response `200` `File`
         * @response `default` `Error`
         */
        getMapWms: ({ meparCode, ...query }, params = {}) =>
            this.request({
                path: `/layers/wms/${meparCode}`,
                method: 'GET',
                query: query,
                secure: true,
                ...params,
            }),
        /**
         * No description
         *
         * @tags Layers
         * @name GetMapWmsDates
         * @summary Get WMS data for maps
         * @request GET:/layers/wms/dates/{meparCode}
         * @secure
         * @response `200` `(WmsDate)[]`
         * @response `default` `Error`
         */
        getMapWmsDates: ({ meparCode, ...query }, params = {}) =>
            this.request({
                path: `/layers/wms/dates/${meparCode}`,
                method: 'GET',
                query: query,
                secure: true,
                ...params,
            }),
    };
    policies = {
        /**
         * @description Kötvényszám alapján visszaadja egy kötvény adatait
         *
         * @tags Policies
         * @name GetPolicy
         * @summary policiController/getPolicy
         * @request GET:/policies/{policyNumber}
         * @secure
         * @response `200` `PolicyForSideBar`
         * @response `default` `Error`
         */
        getPolicy: ({ policyNumber, ...query }, params = {}) =>
            this.request({
                path: `/policies/${policyNumber}`,
                method: 'GET',
                query: query,
                secure: true,
                ...params,
            }),
        /**
         * @description Visszaadja a farmer role-lal rendelkező felhasználóhoz tartozó összes kötvényt
         *
         * @tags Policies
         * @name ListPoliciesForUser
         * @summary policyController/getPolicy
         * @request GET:/policies
         * @secure
         * @response `200` `(PolicyForSideBar)[]`
         * @response `default` `Error`
         */
        listPoliciesForUser: (params = {}) =>
            this.request({
                path: `/policies`,
                method: 'GET',
                secure: true,
                ...params,
            }),
        /**
         * @description Generali-tól érkező kötvényadatokból upsert-eli a kötvények adatait, és a kötvényekhez tartozó egyéb táblákat. Hiba esetén emailt küld a hibákról.
         *
         * @tags Puccini
         * @name UpdatePolicies
         * @summary policyController/updatePolicies
         * @request POST:/policies
         * @secure
         * @response `200` `"Policy import started."`
         * @response `default` `Error`
         */
        updatePolicies: (data, params = {}) =>
            this.request({
                path: `/policies`,
                method: 'POST',
                body: data,
                secure: true,
                ...params,
            }),
    };
    products = {
        /**
         * @description MePAR kód alapján kikeresi az ahhoz elérhető, főleg geoszerveren elérhető adatokat.
         *
         * @tags Products
         * @name GetProducts
         * @summary productController/getProducts
         * @request GET:/products
         * @secure
         * @response `200` `(Product)[]`
         * @response `default` `Error`
         */
        getProducts: (query, params = {}) =>
            this.request({
                path: `/products`,
                method: 'GET',
                query: query,
                secure: true,
                ...params,
            }),
    };
    downloads = {
        /**
         * @description Egy károsodott tábla mintavételi tervének letöltése.
         *
         * @tags Downloads
         * @name DownloadSampling
         * @summary damageController/downloadSampling
         * @request GET:/downloads/sampling/{damagePlotId}
         * @response `200` `File`
         * @response `default` `Error`
         */
        downloadSampling: (damagePlotId, params = {}) =>
            this.request({
                path: `/downloads/sampling/${damagePlotId}`,
                method: 'GET',
                ...params,
            }),
    };
    dashboards = {
        /**
 * @description A kötvényekről ad statisztikai adatokat
 *
 * @tags Dashboard
 * @name GetPolicyAreaDashboard
 * @summary dashboardController/getPolicyAreaDashboard
 * @request GET:/dashboards/policies/area
 * @secure
 * @response `200` `{
    categories: (GroupedArea)[],
    plants: (GroupedArea)[],

}`
 * @response `default` `Error`
 */
        getPolicyAreaDashboard: (query, params = {}) =>
            this.request({
                path: `/dashboards/policies/area`,
                method: 'GET',
                query: query,
                secure: true,
                ...params,
            }),
        /**
 * @description A kötvényekről ad statisztikai adatokat
 *
 * @tags Dashboard
 * @name GetPolicyAlertingDashboard
 * @summary dashboardController/getPolicyAlertingDashboard
 * @request GET:/dashboards/policies/alerting
 * @secure
 * @response `200` `{
    farmerCount: number,
    plants: (GroupedArea)[],

}`
 * @response `default` `Error`
 */
        getPolicyAlertingDashboard: (query, params = {}) =>
            this.request({
                path: `/dashboards/policies/alerting`,
                method: 'GET',
                query: query,
                secure: true,
                ...params,
            }),
        /**
 * @description A kötvényekről ad statisztikai adatokat
 *
 * @tags Dashboard
 * @name GetPolicyRadarDashboard
 * @summary dashboardController/getPolicyRadarDashboard
 * @request GET:/dashboards/policies/radar
 * @secure
 * @response `200` `{
    areas: (GroupedArea)[],
    counts: (GroupedCount)[],

}`
 * @response `default` `Error`
 */
        getPolicyRadarDashboard: (params = {}) =>
            this.request({
                path: `/dashboards/policies/radar`,
                method: 'GET',
                secure: true,
                ...params,
            }),
        /**
 * @description A káraktákról ad statisztikai adatokat
 *
 * @tags Dashboard
 * @name GetDamageOverviewDashboard
 * @summary dashboardController/getDamageOverviewDashboard
 * @request GET:/dashboards/damages/overview
 * @secure
 * @response `200` `{
    sum: number,
    categories?: ClosedDamages,
    plants?: (GroupedInspectionData)[],

}`
 * @response `default` `Error`
 */
        getDamageOverviewDashboard: (query, params = {}) =>
            this.request({
                path: `/dashboards/damages/overview`,
                method: 'GET',
                query: query,
                secure: true,
                ...params,
            }),
        /**
         * @description A káraktákról ad statisztikai adatokat
         *
         * @tags Dashboard
         * @name GetDamagePlantsDashboard
         * @summary dashboardController/getDamagePlantsDashboard
         * @request GET:/dashboards/damages/plants
         * @secure
         * @response `200` `(GroupedDamageCount)[]`
         * @response `default` `Error`
         */
        getDamagePlantsDashboard: (query, params = {}) =>
            this.request({
                path: `/dashboards/damages/plants`,
                method: 'GET',
                query: query,
                secure: true,
                ...params,
            }),
        /**
         * @description A káraktákról ad statisztikai adatokat
         *
         * @tags Dashboard
         * @name GetDamagePayoutDashboard
         * @summary dashboardController/getDamagePayoutDashboard
         * @request GET:/dashboards/damages/payout
         * @secure
         * @response `200` `(GroupedPayout)[]`
         * @response `default` `Error`
         */
        getDamagePayoutDashboard: (query, params = {}) =>
            this.request({
                path: `/dashboards/damages/payout`,
                method: 'GET',
                query: query,
                secure: true,
                ...params,
            }),
        /**
         * @description A káraktákról ad statisztikai adatokat
         *
         * @tags Dashboard
         * @name GetDamageTypesDashboard
         * @summary dashboardController/getDamageTypesDashboard
         * @request GET:/dashboards/damages/types
         * @secure
         * @response `200` `(GroupedDamageCount)[]`
         * @response `default` `Error`
         */
        getDamageTypesDashboard: (query, params = {}) =>
            this.request({
                path: `/dashboards/damages/types`,
                method: 'GET',
                query: query,
                secure: true,
                ...params,
            }),
        /**
 * @description A kárszakértőkről ad statisztikai adatokat
 *
 * @tags Dashboard
 * @name GetExpertActivityDashboard
 * @summary dashboardController/getExpertActivityDashboard
 * @request GET:/dashboards/experts/activities
 * @secure
 * @response `200` `{
    activities?: ({
    company?: string,
    user?: string,
    last_week?: number,
    last_month?: number,
    last_year?: number,

})[],
    damages?: ({
    company?: string,
    user?: string,
    damageViewed?: number,

})[],

}`
 * @response `default` `Error`
 */
        getExpertActivityDashboard: (query, params = {}) =>
            this.request({
                path: `/dashboards/experts/activities`,
                method: 'GET',
                query: query,
                secure: true,
                ...params,
            }),
        /**
 * @description A kárszakértőkről ad statisztikai adatokat
 *
 * @tags Dashboard
 * @name GetExpertDamagesDashboard
 * @summary dashboardController/getExpertDamagesDashboard
 * @request GET:/dashboards/experts/damages
 * @secure
 * @response `200` `{
    experts: (GroupedExpertCount)[],
    samplings: (GroupedSampling)[],
    tables: (GroupedDamageData)[],

}`
 * @response `default` `Error`
 */
        getExpertDamagesDashboard: (query, params = {}) =>
            this.request({
                path: `/dashboards/experts/damages`,
                method: 'GET',
                query: query,
                secure: true,
                ...params,
            }),
    };
    currentUser = {
        /**
         * @description Visszadja az aktuális felhasználót. A BearerJWTPassport middleware knex-szel kéri le db-ből a usert, ebből adja vissza a 'name', 'email', 'role', 'id', 'companyId', 'welcomeScreen', 'userName', mezőket.
         *
         * @tags Users
         * @name GetCurrentLegacyUser
         * @summary userController/getCurrentUser
         * @request GET:/currentUser
         * @secure
         * @response `200` `LegacyUser`
         * @response `400` `Error`
         * @response `401` `Error`
         * @response `500` `Error`
         */
        getCurrentLegacyUser: (params = {}) =>
            this.request({
                path: `/currentUser`,
                method: 'GET',
                secure: true,
                ...params,
            }),
        /**
         * @description Módosítja az aktuális felhasználó nevét és emailcímét. A BearerJWTPassport middleware knex-szel kéri le db-ből a usert.
         *
         * @tags Users
         * @name UpdateCurrentLegacyUser
         * @summary userController/updateCurrentUser
         * @request POST:/currentUser
         * @secure
         * @response `200` `LegacyUserUpdate`
         * @response `400` `Error`
         * @response `401` `Error`
         */
        updateCurrentLegacyUser: (data, params = {}) =>
            this.request({
                path: `/currentUser`,
                method: 'POST',
                body: data,
                secure: true,
                ...params,
            }),
    };
    currentUserPassword = {
        /**
         * @description Módosítja az aktuális felhasználó jelszavát. A BearerJWTPassport middleware knex-szel kéri le db-ből a usert. Jelenleg üres objectet ad vissza.
         *
         * @tags Users
         * @name UpdateCurrentUserPassword
         * @summary userController/updateCurrentUserPassword
         * @request POST:/currentUserPassword
         * @secure
         * @response `200` `Status`
         * @response `default` `Error`
         */
        updateCurrentUserPassword: (data, params = {}) =>
            this.request({
                path: `/currentUserPassword`,
                method: 'POST',
                body: data,
                secure: true,
                ...params,
            }),
    };
    admin = {
        /**
         * @description Ha van megadva id param, akkor az annak megfelelő user-t adja vissza. Ha nincs, akkor az összes user közül a paraméterek alapján keres.
         *
         * @tags Admin
         * @name GetUsers
         * @summary baseAdminController/getEntities
         * @request GET:/admin/users
         * @secure
         * @response `200` `(LegacyUserForAdmin)[]`
         * @response `default` `Error`
         */
        getUsers: (query, params = {}) =>
            this.request({
                path: `/admin/users`,
                method: 'GET',
                query: query,
                secure: true,
                ...params,
            }),
        /**
         * @description Módosít egy usert majd visszaadja a módosítottat
         *
         * @tags Admin
         * @name GetUser
         * @summary baseAdminController/getEntity
         * @request GET:/admin/users/{id}
         * @secure
         * @response `200` `LegacyUserForAdmin`
         * @response `default` `Error`
         */
        getUser: (id, params = {}) =>
            this.request({
                path: `/admin/users/${id}`,
                method: 'GET',
                secure: true,
                ...params,
            }),
        /**
         * @description Módosít egy usert majd visszaadja a módosítottat
         *
         * @tags Admin
         * @name UpdateUser
         * @summary baseAdminController/updateEntity
         * @request PUT:/admin/users/{id}
         * @secure
         * @response `200` `LegacyUserForAdmin`
         * @response `default` `Error`
         */
        updateUser: (id, data, params = {}) =>
            this.request({
                path: `/admin/users/${id}`,
                method: 'PUT',
                body: data,
                secure: true,
                ...params,
            }),
        /**
         * @description Ha van megadva id param, akkor az annak megfelelő alert-t adja vissza. Ha nincs, akkor az összes alert közül a phrase paraméterben megadott MePAR-kód alapján keres.
         *
         * @tags Admin
         * @name GetAdminAlerts
         * @summary baseAdminController/getEntities
         * @request GET:/admin/alerts
         * @secure
         * @response `200` `(Alert)[]`
         * @response `default` `Error`
         */
        getAdminAlerts: (query, params = {}) =>
            this.request({
                path: `/admin/alerts`,
                method: 'GET',
                query: query,
                secure: true,
                ...params,
            }),
        /**
         * @description Módosít egy alertet, hozzáadja a módosítást végző user id-t mint supervisor, és validáltra állítja, majd visszaadja a módosított alertet.
         *
         * @tags Admin
         * @name GetAlert
         * @summary baseAdminController/getEntity
         * @request GET:/admin/alerts/{id}
         * @secure
         * @response `200` `Alert`
         * @response `default` `Error`
         */
        getAlert: (id, params = {}) =>
            this.request({
                path: `/admin/alerts/${id}`,
                method: 'GET',
                secure: true,
                ...params,
            }),
        /**
         * @description Módosít egy alertet, hozzáadja a módosítást végző user id-t mint supervisor, és validáltra állítja, majd visszaadja a módosított alertet.
         *
         * @tags Admin
         * @name UpdateAlert
         * @summary baseAdminController/updateEntity
         * @request PUT:/admin/alerts/{id}
         * @secure
         * @response `200` `Alert`
         * @response `default` `Error`
         */
        updateAlert: (id, data, params = {}) =>
            this.request({
                path: `/admin/alerts/${id}`,
                method: 'PUT',
                body: data,
                secure: true,
                ...params,
            }),
        /**
         * @description Ha van megadva id param, akkor az annak megfelelő képet adja vissza. Ha nincs, akkor az összes kép közül a phrase paraméterben megadott MePAR-kód alapján keres.
         *
         * @tags Admin
         * @name GetImages
         * @summary baseAdminController/getEntities
         * @request GET:/admin/images
         * @secure
         * @response `200` `(AdminImage)[]`
         * @response `default` `Error`
         */
        getImages: (query, params = {}) =>
            this.request({
                path: `/admin/images`,
                method: 'GET',
                query: query,
                secure: true,
                ...params,
            }),
        /**
         * @description Módosít egy képet, hozzáadja a módosítást végző user id-t mint operatorUserId, és validáltra állítja, majd visszaadja a módosított képet.
         *
         * @tags Admin
         * @name GetImage
         * @summary baseAdminController/getEntity
         * @request GET:/admin/images/{id}
         * @secure
         * @response `200` `AdminImage`
         * @response `default` `Error`
         */
        getImage: (id, params = {}) =>
            this.request({
                path: `/admin/images/${id}`,
                method: 'GET',
                secure: true,
                ...params,
            }),
        /**
         * @description Módosít egy képet, hozzáadja a módosítást végző user id-t mint operatorUserId, és validáltra állítja, majd visszaadja a módosított képet.
         *
         * @tags Admin
         * @name UpdateImage
         * @summary baseAdminController/updateEntity
         * @request PUT:/admin/images/{id}
         * @secure
         * @response `200` `AdminImage`
         * @response `default` `Error`
         */
        updateImage: (id, data, params = {}) =>
            this.request({
                path: `/admin/images/${id}`,
                method: 'PUT',
                body: data,
                secure: true,
                ...params,
            }),
        /**
         * @description Ha van megadva id param, akkor az annak megfelelő károsodott táblát adja vissza. Ha nincs, akkor az összes károsodott tábla közül a phrase paraméterben megadott MePAR-kód alapján keres.
         *
         * @tags Admin
         * @name GetDamagePlots
         * @summary baseAdminController/getEntities
         * @request GET:/admin/damagePlots
         * @secure
         * @response `200` `(DamagePlotWithDamage)[]`
         * @response `default` `Error`
         */
        getDamagePlots: (query, params = {}) =>
            this.request({
                path: `/admin/damagePlots`,
                method: 'GET',
                query: query,
                secure: true,
                ...params,
            }),
        /**
         * @description Módosít egy károsodott táblát, hozzáadja a módosítást végző user id-t mint supervisorUserId, és validáltra állítja, majd visszaadja a módosított károsodott táblát.
         *
         * @tags Admin
         * @name GetAdminDamagePlot
         * @summary baseAdminController/getEntity
         * @request GET:/admin/damagePlots/{id}
         * @secure
         * @response `200` `DamagePlotWithDamage`
         * @response `default` `Error`
         */
        getAdminDamagePlot: (id, params = {}) =>
            this.request({
                path: `/admin/damagePlots/${id}`,
                method: 'GET',
                secure: true,
                ...params,
            }),
        /**
         * @description Módosít egy károsodott táblát, hozzáadja a módosítást végző user id-t mint supervisorUserId, és validáltra állítja, majd visszaadja a módosított károsodott táblát.
         *
         * @tags Admin
         * @name UpdateDamagePlot
         * @summary baseAdminController/updateEntity
         * @request PUT:/admin/damagePlots/{id}
         * @secure
         * @response `200` `DamagePlotWithDamage`
         * @response `default` `Error`
         */
        updateDamagePlot: (id, data, params = {}) =>
            this.request({
                path: `/admin/damagePlots/${id}`,
                method: 'PUT',
                body: data,
                secure: true,
                ...params,
            }),
        /**
         * @description A beküldött userId lista alapján kikeresi a usereket, és sms-t és/vagy emailt küld az adott usereknek a sikeres feliratkozásról.
         *
         * @tags Admin
         * @name SignupNotification
         * @summary userAdminController/sendSignupNotifications
         * @request POST:/admin/sendSignupNotifications
         * @secure
         * @response `200` `Status`
         * @response `default` `Error`
         */
        signupNotification: (data, params = {}) =>
            this.request({
                path: `/admin/sendSignupNotifications`,
                method: 'POST',
                body: data,
                secure: true,
                ...params,
            }),
        /**
         * @description A beküldött userId lista alapján kikeresi a usereket, és sms-t és/vagy emailt küld az adott usereknek a területükön érvényben lévő riasztásról.
         *
         * @tags Admin
         * @name AlertNotification
         * @summary userAdminController/sendAlertNotifications
         * @request POST:/admin/sendAlertNotifications
         * @secure
         * @response `200` `Status`
         * @response `default` `Error`
         */
        alertNotification: (data, params = {}) =>
            this.request({
                path: `/admin/sendAlertNotifications`,
                method: 'POST',
                body: data,
                secure: true,
                ...params,
            }),
    };
    inspectionDocuments = {
        /**
         * @description A beküldött Damage listából a Damage-ekhez tartozó ellenőrzések adatait upsert-eli a DamageInspection táblába, majd a DamageInspection-höz tartozó dokumentumokat a DamageInspectionDocument táblába.
         *
         * @tags Karnavigator
         * @name UpdateInspectionDocuments
         * @summary damageController/updateInspectionDocuments
         * @request POST:/inspectionDocuments
         * @secure
         * @response `200` `Status`
         * @response `default` `Error`
         */
        updateInspectionDocuments: (data, params = {}) =>
            this.request({
                path: `/inspectionDocuments`,
                method: 'POST',
                body: data,
                secure: true,
                ...params,
            }),
    };
    damagephotos = {
        /**
         * @description Kárnavigátorból egy adott kárhoz érkező file-okból adatbázisba menti a fotók adatait.
         *
         * @tags Karnavigator
         * @name SaveDamagePhotos
         * @summary damageController/saveDamagePhotos
         * @request POST:/damagephotos
         * @secure
         * @response `200` `PhotoResult`
         * @response `default` `Error`
         */
        saveDamagePhotos: (data, params = {}) =>
            this.request({
                path: `/damagephotos`,
                method: 'POST',
                body: data,
                secure: true,
                ...params,
            }),
    };
    samplings = {
        /**
         * @description A beküldött Damage listából a Damage-ekhez tartozó mintavételi adatokat insert-eli a Sampling táblába. Jelenleg üres objectet ad vissza.
         *
         * @tags Karnavigator
         * @name UpdateSamplings
         * @summary damageController/updateSamplings
         * @request POST:/samplings
         * @secure
         * @response `200` `Status`
         * @response `default` `Error`
         */
        updateSamplings: (data, params = {}) =>
            this.request({
                path: `/samplings`,
                method: 'POST',
                body: data,
                secure: true,
                ...params,
            }),
    };
    damageStats = {
        /**
         * @description DamageNumber alapján kikeresi a kárt, majd visszaad belőle statisztikai adatokat.
         *
         * @tags Karnavigator
         * @name GetDamageStats
         * @summary damageController/getDamageStats
         * @request GET:/damageStats/{id}
         * @secure
         * @response `200` `DamageStats`
         * @response `default` `Error`
         */
        getDamageStats: (id, params = {}) =>
            this.request({
                path: `/damageStats/${id}`,
                method: 'GET',
                secure: true,
                ...params,
            }),
    };
    logs = {
        /**
         * @description A Log táblába ment egy rekordot. A frontenden történő műveletekről érkezik adat. Tulajdonképpen user interakció adatgyűjtés. Jelenleg a válasz üres object.
         *
         * @tags Log
         * @name SaveLog
         * @summary logController/saveLog
         * @request POST:/logs/{type}
         * @secure
         * @response `200` `Status`
         * @response `default` `Error`
         */
        saveLog: (type, params = {}) =>
            this.request({
                path: `/logs/${type}`,
                method: 'POST',
                secure: true,
                ...params,
            }),
    };
    sms = {
        /**
         * @description Az sms küldő szolgáltatás ide küldi a választ az sms kézbesítésről, erről a Message táblába kerül egy rekord.
         *
         * @tags Sms
         * @name SeemeCallback
         * @summary smsController/seemeCallback
         * @request GET:/sms/callback
         * @secure
         * @response `200` `Status`
         * @response `default` `Error`
         */
        seemeCallback: (query, params = {}) =>
            this.request({
                path: `/sms/callback`,
                method: 'GET',
                query: query,
                secure: true,
                ...params,
            }),
    };
    healthCheck = {
        /**
         * @description Ellenőrzi, hogy az adatbázis, a VM és a geoserver fut-e.
         *
         * @tags Health
         * @name HealthCheck
         * @summary serviceController/healthCheck
         * @request GET:/healthCheck
         * @response `200` `Status`
         * @response `default` `Error`
         */
        healthCheck: (params = {}) =>
            this.request({
                path: `/healthCheck`,
                method: 'GET',
                ...params,
            }),
    };
}
