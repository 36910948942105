import { CloseRounded } from '@mui/icons-material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { OVERVIEW_STATES } from '../../constants';
import { useAppControl } from '../../providers/AppContext';
import { useLayout } from '../../providers/LayoutContext';
import { useMapFunctions } from '../../providers/MapFunctionsProvider';
import LinkButton from './LinkButton';

const HomeButton = () => {
    const navigate = useNavigate();
    const { setOverviewState } = useAppControl();
    const { setSelectedProduct } = useLayout();
    const { resetMap } = useMapFunctions();

    return (
        <LinkButton
            onClick={() => {
                setOverviewState(OVERVIEW_STATES.CLOSED);
                setSelectedProduct(null);
                resetMap();
                navigate('/');
            }}
            endIcon={<CloseRounded />}
        >
            Bezárás
        </LinkButton>
    );
};

export default HomeButton;
