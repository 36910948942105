import {
    Alert,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import React from 'react';
import { useLayout } from '../../../providers/LayoutContext';

const ReportBlock = () => {
    const { selectedProduct } = useLayout();
    return (
        <Grid item xs={12}>
            {selectedProduct.policyYields?.length > 0 ? (
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Növény</TableCell>
                                <TableCell>Növény kód</TableCell>
                                <TableCell>Hozam (t/ha)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {selectedProduct.policyYields.map((policyYield) => (
                                <TableRow key={policyYield.id}>
                                    <TableCell>{policyYield.plantName ?? '-'}</TableCell>
                                    <TableCell>{policyYield.plantCode ?? '-'}</TableCell>
                                    <TableCell>{policyYield.yield ?? '-'}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Alert severity="info">Nincs elérhető hozam</Alert>
            )}
        </Grid>
    );
};

export default ReportBlock;
