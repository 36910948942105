import { Box, Toolbar, Typography } from '@mui/material';
import React from 'react';
import { ReactComponent as GeneraliSvg } from '../../assets/generali.svg';
import SearchForm from './SearchForm';
import UserMenu from './UserMenu';
import YearSelector from './YearSelector';

const NavbarDesktop = ({ handleClick, user }) => {
    return (
        <Toolbar>
            <Box sx={{ cursor: 'pointer' }} onClick={() => handleClick()}>
                <GeneraliSvg className="generali-logo" />
            </Box>
            <Typography variant="h6" mr={2}>
                GeoRisk
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            {user && (
                <>
                    <YearSelector />
                    <SearchForm />
                    <Typography variant="h6" mr={2}>
                        {user.displayName}
                    </Typography>
                    <UserMenu />
                </>
            )}
        </Toolbar>
    );
};

export default NavbarDesktop;
