import { CONTROLLER_KEYS } from './controllerKeysAndLabels';

const crops = Object.freeze({
    [CONTROLLER_KEYS.SPIKE]: 1,
    [CONTROLLER_KEYS.COLE_SEED]: 2,
    [CONTROLLER_KEYS.CORN]: 3,
    [CONTROLLER_KEYS.SUNFLOWER]: 4,
});

const cropNumbers = Object.freeze({
    1: 'spike',
    2: 'autumnCabbageRape',
    3: 'corn',
    4: 'sunflower',
});

const YIELD_TABLE_OPTIONS = {
    MEPAR: 'Mepar',
    NUTS4: 'Nuts4',
};

const YIELD_TYPE_OPTIONS = {
    REAL: 'real',
    REFERENCE: 'reference',
};

const YIELD_PLANT_OPTIONS = {
    [CONTROLLER_KEYS.RAPE_SEED]: 'REPCE',
    [CONTROLLER_KEYS.SPIKE]: 'KALASZOS',
    [CONTROLLER_KEYS.CORN]: 'KUKORICA',
    [CONTROLLER_KEYS.SUNFLOWER]: 'NAPRAFORGO',
};

const DRAWER_STATES = {
    OPEN: true,
    CLOSED: false,
};

export {
    crops,
    cropNumbers,
    YIELD_TABLE_OPTIONS,
    YIELD_TYPE_OPTIONS,
    YIELD_PLANT_OPTIONS,
    DRAWER_STATES,
};
