import { EditRounded, FileDownloadRounded, InfoRounded } from '@mui/icons-material';
import {
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import React from 'react';

const FieldBlock = () => {
    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h5">Biztosított / Károsodott tábla</Typography>
            </Grid>
            <Grid item xs={12} justifyContent="space-between" sx={{ mb: 2 }}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>MePAR kód:</TableCell>
                            <TableCell>LWEQ1U20</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Kárszám:</TableCell>
                            <TableCell>GP220215143</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Kár dátuma:</TableCell>
                            <TableCell>2022. 07. 24.</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Kártípus:</TableCell>
                            <TableCell>Kukorica</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Növény:</TableCell>
                            <TableCell>Aszálykár</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>MePAR terület:</TableCell>
                            <TableCell>30.08 ha</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Tábla terület:</TableCell>
                            <TableCell>
                                <span style={{ color: 'red' }}>Rajzolás szükséges!</span>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Kár. terület:</TableCell>
                            <TableCell>2 ha</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
            <Grid item xs={6} container justifyContent="space-between">
                <Grid item xs={12}>
                    <Typography variant="h5">Letölthető térképek (2022.01.01.)</Typography>
                </Grid>
                <Grid item xs={6} alignSelf="center">
                    Változás térkép
                    <Tooltip title="Változás térkép" placement="right">
                        <IconButton>
                            <InfoRounded />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={6}>
                    <IconButton>
                        <FileDownloadRounded />
                    </IconButton>
                </Grid>
                <Grid item xs={6} alignSelf="center">
                    Káros - nem káros térkép
                    <Tooltip title="Káros - nem káros térkép" placement="right">
                        <IconButton>
                            <InfoRounded />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={6}>
                    <IconButton>
                        <FileDownloadRounded />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid item xs={6} container justifyContent="space-between">
                <Grid item xs={12}>
                    <Typography variant="h5">Interakciók</Typography>
                </Grid>
                <Grid item xs={6} alignSelf="center">
                    Hozamszámítás
                </Grid>
                <Grid item xs={6}>
                    <IconButton>
                        <EditRounded />
                    </IconButton>
                </Grid>
                <Grid item xs={6} alignSelf="center">
                    Táblahatár mérés
                </Grid>
                <Grid item xs={6}>
                    <IconButton>
                        <EditRounded />
                    </IconButton>
                </Grid>
                <Grid item xs={6} alignSelf="center">
                    Mintavételezés
                </Grid>
                <Grid item xs={6}>
                    <IconButton>
                        <EditRounded />
                    </IconButton>
                </Grid>
            </Grid>
        </>
    );
};

export default FieldBlock;
