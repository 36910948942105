import { equals } from 'ramda';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { PRODUCT_CONTROLLER_STATE } from '../../../config';
import { CONTROLLER_KEYS } from '../../../constants';
import { useAuth } from '../../../providers/AuthProvider';

const MenuContext = createContext();
MenuContext.displayName = 'MenuContext';

const defaultMenuState = {
    [CONTROLLER_KEYS.RISK_FACTOR]: [],
    [CONTROLLER_KEYS.CROP]: [],
    [CONTROLLER_KEYS.FROST]: [],
    [CONTROLLER_KEYS.DAMAGE_CHRONOLOGY]: [],
    [CONTROLLER_KEYS.MONITORING]: [],
    [CONTROLLER_KEYS.YIELD_REFERENCE]: null,
    [CONTROLLER_KEYS.YIELD_CALCULATED]: null,
    [CONTROLLER_KEYS.RADAR]: [],
    [CONTROLLER_KEYS.PLANT]: [],
    [CONTROLLER_KEYS.KARNAVI_PHOTOS]: [CONTROLLER_KEYS.KARNAVI_PHOTOS],
    [CONTROLLER_KEYS.CITIES]: [],
};

function getStateFromStorage(userId) {
    const item = localStorage.getItem(`${PRODUCT_CONTROLLER_STATE}:${userId}`);
    const isExists = !!item && item !== 'undefined';
    if (!isExists) {
        localStorage.setItem(
            `${PRODUCT_CONTROLLER_STATE}:${userId}`,
            JSON.stringify(defaultMenuState)
        );
        return false;
    }
    if (equals(Object.keys(defaultMenuState), Object.keys(JSON.parse(item)))) {
        try {
            return JSON.parse(localStorage.getItem(`${PRODUCT_CONTROLLER_STATE}:${userId}`));
        } catch (err) {
            console.error(err);
            return false;
        }
    }
    localStorage.setItem(`${PRODUCT_CONTROLLER_STATE}:${userId}`, JSON.stringify(defaultMenuState));
    return false;
}

export function MenuContextProvider({ children }) {
    const { user } = useAuth();
    const [checkedItems, setCheckedItemsInternal] = useState(
        () => getStateFromStorage(user.id) || defaultMenuState
    );
    const [menuOpen, setMenuOpen] = useState(false);

    const setCheckedItems = useCallback(
        (newMapController) => {
            if (newMapController === null) {
                localStorage.setItem(
                    `${PRODUCT_CONTROLLER_STATE}:${user.id}`,
                    JSON.stringify(defaultMenuState)
                );
                setCheckedItemsInternal(defaultMenuState);
                return;
            }
            localStorage.setItem(
                `${PRODUCT_CONTROLLER_STATE}:${user.id}`,
                JSON.stringify(newMapController)
            );
            setCheckedItemsInternal(newMapController);
        },
        [setCheckedItemsInternal]
    );

    const handleItemCheck = (item, key) => {
        setCheckedItemsInternal((prevItems) => {
            const isItemPresent = prevItems[key]?.includes(item);
            const newItems = {
                ...prevItems,
                [key]: isItemPresent
                    ? prevItems[key].filter((e) => e !== item)
                    : [...prevItems[key], item],
            };
            setCheckedItems(newItems);
            return newItems;
        });
    };

    const handleRadioItemCheck = (item, key) => {
        setCheckedItems((prevItems) => {
            const isItemPresent = prevItems[key] && prevItems[key] === item;
            const newItems = {
                ...prevItems,
                [key]: isItemPresent ? '' : item,
            };
            setCheckedItems(newItems);
            return newItems;
        });
    };

    const contextValue = useMemo(
        () => ({
            menuOpen,
            checkedItems,
            setMenuOpen,
            handleItemCheck,
            handleRadioItemCheck,
        }),
        [menuOpen, checkedItems, setMenuOpen, handleItemCheck, handleRadioItemCheck]
    );

    return <MenuContext.Provider value={contextValue}>{children}</MenuContext.Provider>;
}

export function useMenu() {
    return useContext(MenuContext);
}
