const OVERVIEW_STATES = {
    OPEN: 'OPEN',
    OPEN_FULLSCREEN: 'OPEN_FULLSCREEN',
    HIDDEN: 'HIDDEN',
    CLOSED: 'CLOSED',
    NONE: false,
};

const OVERVIEW_TYPES = {
    MEPAR: 'MEPAR',
    FIELD: 'FIELD',
    FIELD_INSURED: 'FIELD_INSURED',
    FIELD_DAMAGED: 'FIELD_DAMAGED',
    REPORT: 'REPORT',
    POLICY: 'POLICY',
    NONE: false,
};

// eslint-disable-next-line import/prefer-default-export
export { OVERVIEW_STATES, OVERVIEW_TYPES };
