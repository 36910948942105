import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ADMIN_MENU_KEYS } from '../constants';

const AdminContext = createContext();
AdminContext.displayName = 'AdminContext';

const getCurrentIndex = (location) => {
    if (location.pathname.includes('policies')) {
        return ADMIN_MENU_KEYS.POLICIES;
    }
    if (location.pathname.includes('damagereports')) {
        return ADMIN_MENU_KEYS.DAMAGE_REPORTS;
    }
    if (location.pathname.includes('damageexperts')) {
        return ADMIN_MENU_KEYS.DAMAGE_EXPERTS;
    }
    return ADMIN_MENU_KEYS.NONE;
};

export function AdminContextProvider({ children }) {
    const location = useLocation();
    const [selectedMenuItem, setSelectedMenuItem] = useState(() => getCurrentIndex(location));

    useEffect(() => {
        setSelectedMenuItem(() => getCurrentIndex(location));
    }, [location, setSelectedMenuItem, getCurrentIndex]);

    const contextValue = useMemo(
        () => ({
            selectedMenuItem,
            setSelectedMenuItem,
        }),
        [selectedMenuItem, setSelectedMenuItem]
    );

    return <AdminContext.Provider value={contextValue}>{children}</AdminContext.Provider>;
}

export function useAdmin() {
    return useContext(AdminContext);
}
