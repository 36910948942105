import { Grid, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import TabPanel from './TabPanel';
import Alerting from './policies/Alerting';
import InsuredArea from './policies/InsuredArea';
import Radar from './policies/Radar';

const AdminPolicies = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const tabIndex = searchParams.get('tabIndex');
    const [tabValue, setTabValue] = useState(tabIndex ? parseInt(tabIndex, 10) : 0);

    const handleChange = (_, newValue) => {
        setTabValue(newValue);
        setSearchParams({ ...searchParams, tabIndex: newValue });
    };

    useEffect(() => {
        if (searchParams?.tabIndex) {
            return;
        }
        setSearchParams({ ...searchParams, tabIndex: 0 });
    }, []);

    return (
        <Grid container sx={{ flexGrow: 1, height: '100%' }} alignContent="flex-start">
            <Grid item sx={{ borderBottom: 1, borderColor: 'divider' }} xs={12}>
                <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    variant="scrollable"
                    allowScrollButtonsMobile
                    scrollButtons="auto"
                >
                    <Tab label="Biztosított terület" />
                    <Tab label="Riasztás szolgáltatás" />
                    <Tab label="Viharesemény érintettség" />
                </Tabs>
            </Grid>
            <Grid item xs={12}>
                <TabPanel value={tabValue} index={0}>
                    <InsuredArea />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <Alerting />
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                    <Radar />
                </TabPanel>
            </Grid>
        </Grid>
    );
};

export default AdminPolicies;
