import { Grid, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import React from 'react';
import { useLayout } from '../../../providers/LayoutContext';
import { formatNumberToDecimal } from '../../../utils/formatter';

const InsuredFieldBlock = () => {
    const { selectedProduct } = useLayout();

    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h5">Biztosított tábla</Typography>
            </Grid>
            <Grid item xs={12} justifyContent="space-between" sx={{ mb: 2 }}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>MePAR kód:</TableCell>
                            <TableCell>{selectedProduct.meparCode}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Kötvényszám:</TableCell>
                            <TableCell>{selectedProduct.policyNumber}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Növény:</TableCell>
                            <TableCell>{selectedProduct.plantCode}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Biztosított terület:</TableCell>
                            <TableCell>
                                {formatNumberToDecimal(selectedProduct.insuredArea, 'ha')}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
        </>
    );
};

export default InsuredFieldBlock;
