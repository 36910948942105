import { Grid, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useMap } from 'es-map-widget';
import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import HomeButton from '../../../components/atom/HomeButton';
import ShowOverviewButton from '../../../components/atom/ShowOverviewButton';
import { MAP_LAYER_NAMES, OVERVIEW_STATES, OVERVIEW_TYPES } from '../../../constants';
import { useAppControl } from '../../../providers/AppContext';
import { useLayout } from '../../../providers/LayoutContext';
import { useMapFunctions } from '../../../providers/MapFunctionsProvider';

const openStates = [OVERVIEW_STATES.OPEN_FULLSCREEN, OVERVIEW_STATES.OPEN];

const DamageReportData = ({ result }) => {
    const map = useMap();
    const { selectedProduct, setSelectedProduct } = useLayout();
    const { overviewState, setOverviewState } = useAppControl();
    const { resetMap } = useMapFunctions();
    const [_, setSearchParams] = useSearchParams();

    const handleClick = () => {
        map.setLayerVisibility(MAP_LAYER_NAMES.FIELDS, false);
        if (openStates.includes(overviewState) && selectedProduct?.type === OVERVIEW_TYPES.REPORT) {
            setOverviewState(OVERVIEW_STATES.CLOSED);
            setSelectedProduct(null);
            return;
        }

        setSearchParams({});
        setOverviewState(OVERVIEW_STATES.OPEN);
        setSelectedProduct({ ...result, type: OVERVIEW_TYPES.REPORT });
    };

    const handleNavigation = () => {
        setOverviewState(OVERVIEW_STATES.CLOSED);
        setSelectedProduct(null);
        resetMap();
    };

    return (
        <Grid container item xs={12} direction="column" gap={1} sx={{ mb: 1 }}>
            <Grid item container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h4">Kárakta</Typography>
                </Grid>
                <Grid item>
                    <HomeButton />
                </Grid>
            </Grid>
            <Grid item container justifyContent="space-between">
                <Grid item>Kárakta száma:</Grid>
                <Grid item>{result.damageNumber}</Grid>
            </Grid>
            <Grid item container justifyContent="space-between">
                <Grid item>Kötvényszám:</Grid>
                <Grid item>
                    {result.policy ? (
                        <Link
                            to={`/products/policy/${result.policy?.policyNumber}`}
                            onClick={handleNavigation}
                        >
                            {result.policy.policyNumber}
                        </Link>
                    ) : (
                        <span>{result.policyNumber}</span>
                    )}
                </Grid>
            </Grid>
            <Grid item container justifyContent="space-between">
                <Grid item>Kár dátuma:</Grid>
                <Grid item>{format(new Date(result.damageDate), 'yyyy-MM-dd')}</Grid>
            </Grid>
            <Grid item container justifyContent="space-between">
                <Grid item>Károsodott terület:</Grid>
                <Grid item>{result.area} ha</Grid>
            </Grid>
            <Grid item container justifyContent="space-between">
                <Grid item>Kártípus:</Grid>
                <Grid item>{result.dangerType}</Grid>
            </Grid>
            <Grid item container justifyContent="space-between">
                <Grid item>Kárszemle:</Grid>
                <Grid item>
                    <a
                        href={`https://www.google.com/maps?q=${result.inspectionAddress}`}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        {result.inspectionAddress}
                    </a>
                </Grid>
            </Grid>
            <Grid item textAlign="right" sx={{ mt: 2 }}>
                <ShowOverviewButton onClick={() => handleClick()} title="Kárakta adatai" />
            </Grid>
        </Grid>
    );
};

export default DamageReportData;
