import { Grid, TableCell, TableRow, Typography } from '@mui/material';
import React from 'react';
import { useApi } from '../../../../hooks/useApi';
import { formatNumberToDecimal } from '../../../../utils/formatter';
import DashboardTable from '../DashboardTable';
import { useAppControl } from '../../../../providers/AppContext';

const Alerting = () => {
    const { year } = useAppControl();
    const [result, loading, error] = useApi(
        (api, objWithSignal) => api.dashboards.getPolicyAlertingDashboard({ year }, objWithSignal),
        [year]
    );

    return (
        <Grid container item spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h5">
                    Ügyfelek száma ({year}): {!loading ? result?.farmerCount : ''}
                </Typography>
            </Grid>
            <DashboardTable
                title={`${year} Területméret növényeként (ha)`}
                gridProps={{ xs: 12, lg: 4 }}
                headerRows={['Növény', 'Ha']}
                loading={loading}
                error={error}
            >
                {result?.plants &&
                    result?.plants.length > 0 &&
                    result?.plants.map(({ category, area }) => (
                        <TableRow key={`${category}-${area}`}>
                            <TableCell>{category}</TableCell>
                            <TableCell align="right">{formatNumberToDecimal(area, 'ha')}</TableCell>
                        </TableRow>
                    ))}
            </DashboardTable>
        </Grid>
    );
};

export default Alerting;
