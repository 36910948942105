import { DownloadRounded } from '@mui/icons-material';
import {
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { useWmsDates } from '../../../../mapcontroller/contexts/WmsDateProvider';
import InfoPopover from './InfoPopover';

const DownloadableMaps = ({ result }) => {
    const { wmsData, wmsDownload } = useWmsDates();

    if (!result) {
        return null;
    }

    return (
        <Grid item container xs={12}>
            <Grid item xs={12}>
                <Typography variant="h5">Letölthető térképek</Typography>
            </Grid>
            <TableContainer sx={{ mb: 1 }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Térkép</TableCell>
                            <TableCell>Dátum</TableCell>
                            <TableCell>Letöltés</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                Ndvi{' '}
                                <InfoPopover text="Felhőmentes műholdfelvételekből készült biomassza intenzitás térképek." />
                            </TableCell>
                            <TableCell>
                                {wmsData.date ? format(wmsData.date, 'yyyy-MM-dd') : '-'}
                            </TableCell>
                            <TableCell>
                                {result.mepar?.meparCode ? (
                                    <form
                                        method="get"
                                        action={`/pdfs/${result.mepar.meparCode}/${
                                            result.mepar.meparCode
                                        }_BMI_${format(new Date(wmsData.date), 'yyyyMMdd')}.pdf`}
                                        target="_blank"
                                    >
                                        <IconButton type="submit" disabled={!wmsDownload.ndvi}>
                                            <DownloadRounded />
                                        </IconButton>
                                    </form>
                                ) : (
                                    '-'
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Látható kép{' '}
                                <InfoPopover text="Felhőmentes műholdfelvételekből készült biomassza intenzitás térképek." />
                            </TableCell>
                            <TableCell>
                                {wmsData.date ? format(wmsData.date, 'yyyy-MM-dd') : '-'}
                            </TableCell>
                            <TableCell>
                                {result.mepar?.meparCode ? (
                                    <form
                                        method="get"
                                        action={`/pdfs/${result.mepar.meparCode}/${
                                            result.mepar.meparCode
                                        }_TCI_${format(new Date(wmsData.date), 'yyyyMMdd')}.pdf`}
                                        target="_blank"
                                    >
                                        <IconButton type="submit" disabled={!wmsDownload.visible}>
                                            <DownloadRounded />
                                        </IconButton>
                                    </form>
                                ) : (
                                    '-'
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Táblamonitoring{' '}
                                <InfoPopover text="Virtuális közbenső szemléhez előállított rétegek, amelyek a káreseményt követő felhőmentes felvételeken észlelhető biomassza intenzitás eltérések táblán belüli monitoringját szolgálják." />
                            </TableCell>
                            <TableCell>
                                {wmsData.date ? format(wmsData.date, 'yyyy-MM-dd') : '-'}
                            </TableCell>
                            <TableCell>
                                {result.mepar?.meparCode && result.damage?.id ? (
                                    <form
                                        method="get"
                                        action={`/pdfs/${result.mepar.meparCode}_${result.id}_${
                                            result.damage.id
                                        }/${result.mepar.meparCode}_${
                                            result.id
                                        }_Heterogeneity_${format(
                                            new Date(wmsData.date),
                                            'yyyyMMdd'
                                        )}.pdf`}
                                        target="_blank"
                                    >
                                        <IconButton
                                            type="submit"
                                            disabled={!wmsDownload.heterogeneity}
                                        >
                                            <DownloadRounded />
                                        </IconButton>
                                    </form>
                                ) : (
                                    '-'
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Változástérkép{' '}
                                <InfoPopover
                                    text="
                                        Változástérkép (előszemléhez vagy virtuális közbenső
                                        szemléhez, ha nincs az előszemléig felvétel). A térképi
                                        réteg káresemény előtt és után két időpont közötti biomassza
                                        intenzitás változását ábrázolja.
                                    "
                                />
                            </TableCell>
                            <TableCell>
                                Előtte:{' '}
                                {result.changeMap?.date
                                    ? format(new Date(result.changeMap.date), 'yyyy-MM-dd')
                                    : '-'}
                                <br />
                                Utána:{' '}
                                {result.changeMap?.dateBefore
                                    ? format(new Date(result.changeMap.dateBefore), 'yyyy-MM-dd')
                                    : '-'}
                            </TableCell>
                            <TableCell>
                                {result.changeMap ? (
                                    <form
                                        method="get"
                                        action={`/pdfs/${result.mepar.meparCode}/${
                                            result.mepar.meparCode
                                        }_${result.damage.id}_ChangeMap_${format(
                                            new Date(result.changeMap.date),
                                            'yyyyMMdd'
                                        )}_${format(
                                            new Date(result.changeMap.dateBefore),
                                            'yyyyMMdd'
                                        )}.pdf`}
                                        target="_blank"
                                    >
                                        <IconButton type="submit">
                                            <DownloadRounded />
                                        </IconButton>
                                    </form>
                                ) : (
                                    '-'
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Káros / nem-káros{' '}
                                <InfoPopover
                                    text="
                                        Radar adatok alapján becsült táblán belüli várható
                                        károsodottsági szint a káros - nem káros MEPAR blokkok
                                        elkülönítéséhez."
                                />
                            </TableCell>
                            <TableCell>
                                Előtte:{' '}
                                {result.damageMap?.date
                                    ? format(new Date(result.damageMap.date), 'yyyy-MM-dd')
                                    : '-'}
                                <br />
                                Utána:{' '}
                                {result.damageMap?.dateBefore
                                    ? format(new Date(result.damageMap.dateBefore), 'yyyy-MM-dd')
                                    : '-'}
                            </TableCell>
                            <TableCell>
                                {result.damageMap ? (
                                    <form
                                        method="get"
                                        action={`/pdfs/${result.mepar.meparCode}_${result.id}_${
                                            result.damage.id
                                        }/${result.mepar.meparCode}_${result.id}_DamageMap_${format(
                                            new Date(result.damageMap?.date),
                                            'yyyyMMdd'
                                        )}_${format(
                                            new Date(result.damageMap?.dateBefore),
                                            'yyyyMMdd'
                                        )}.pdf`}
                                        target="_blank"
                                    >
                                        <IconButton type="submit">
                                            <DownloadRounded />
                                        </IconButton>
                                    </form>
                                ) : null}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
};

export default DownloadableMaps;
