import { Alert, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { useLayout } from '../../../providers/LayoutContext';

const PolicyBlock = () => {
    const { selectedProduct } = useLayout();
    return (
        <Grid item xs={12} container justifyContent="space-between">
            {selectedProduct.policyYields?.length > 0 ? (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Növény</TableCell>
                            <TableCell>Növény kód</TableCell>
                            <TableCell>Hozam (t/ha)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {selectedProduct.policyYields.map((yields) => (
                            <TableRow key={yields.id}>
                                <TableCell>{yields.plantName ?? '-'}</TableCell>
                                <TableCell>{yields.plantCode ?? '-'}</TableCell>
                                <TableCell>{yields.yield ?? '-'}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <Alert severity="info">Nincs elérhető hozam</Alert>
            )}
        </Grid>
    );
};

export default PolicyBlock;
