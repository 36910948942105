import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../providers/AuthProvider';

const ProtectedPage = ({ allowedRoles = [], children }) => {
    const { user } = useAuth();

    const userHasRequiredRole =
        (user && allowedRoles.includes(user.role)) || allowedRoles.length === 0;

    if (!user) {
        return <Navigate to="/login" />;
    }

    if (!userHasRequiredRole) {
        return <Navigate to="/accessdenied" />;
    }

    return children;
};

export default ProtectedPage;
