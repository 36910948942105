import { ChevronLeft } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import { useMap } from 'es-map-widget';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import LinkButton from '../../components/atom/LinkButton';
import DataBlock from '../../components/overview/components/DataBlock';
import { MAP_LAYER_NAMES } from '../../constants';
import { useAppControl } from '../../providers/AppContext';
import { useLayout } from '../../providers/LayoutContext';
import ContentArea from '../components/ContentArea';
import MobileOverview from '../components/MobileOverview';

const MobileLayout = ({ children }) => {
    const map = useMap();
    const { selectedProduct, setSelectedProduct } = useLayout();
    const { year } = useAppControl();
    const [open, setOpen] = useState(false);
    const [_searchParams, setSearchParams] = useSearchParams();

    const handleClick = () => setOpen((p) => !p);

    return (
        <>
            <MobileOverview open={open} handleClick={handleClick} />
            {!open && (
                <Grid
                    container
                    sx={{
                        height: 'calc(100% - 36px)',
                        display: 'block',
                        position: 'relative',
                        zIndex: 1081,
                        pr: 1,
                        overflow: 'hidden auto',
                    }}
                    alignContent="flex-start"
                >
                    <Box
                        sx={{
                            display: selectedProduct ? 'block' : 'none',
                            backgroundColor: '#fff',
                            px: 2,
                            pt: 1,
                            height: '100%',
                        }}
                    >
                        <LinkButton
                            onClick={() => {
                                setSearchParams({ year });
                                setSelectedProduct(null);
                                map.setLayerVisibility(MAP_LAYER_NAMES.FIELDS, false);
                            }}
                            startIcon={<ChevronLeft />}
                        >
                            Vissza
                        </LinkButton>
                        <DataBlock />
                    </Box>
                    <div style={{ display: selectedProduct ? 'none' : 'block' }}>
                        <ContentArea>{children}</ContentArea>
                    </div>
                </Grid>
            )}
        </>
    );
};

export default MobileLayout;
