import { ChevronLeftRounded, ChevronRightRounded } from '@mui/icons-material';
import { Grid, IconButton, Paper } from '@mui/material';
import { MAP_EVENTS, useMap } from 'es-map-widget';
import React, { useEffect, useRef, useState } from 'react';
import { MenuContextProvider } from './contexts/MenuContext';
import MapControllerButton from './menu/MapControllerButton';
import menuItems from './menu/menuItems';
import { RadarDateContextProvider } from './contexts/RadarDateProvider';
import LayerAccessController from './contexts/LayerAccessController';

const MapController = () => {
    const map = useMap();
    const menuRef = useRef(null);
    const [menuScroll, setMenuScroll] = useState({ left: 0, leftMax: 0 });

    const [_, setVisibilityMap] = useState({});
    useEffect(() => {
        setVisibilityMap(map.getLayerVisibility());
        const x = map.addEventListener(MAP_EVENTS.visibilityChange, (e) => {
            setVisibilityMap(e.layerVisibility);
        });
        return () => {
            map.removeEventListener(MAP_EVENTS.visibilityChange, x);
        };
    }, [map, setVisibilityMap]);

    return (
        <MenuContextProvider>
            <RadarDateContextProvider>
                <LayerAccessController />
                <Paper elevation={0} square className="map-controller">
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <IconButton
                                size="small"
                                disabled={menuScroll.left === 0}
                                onClick={() => {
                                    menuRef.current.scrollBy(-110, 0);
                                }}
                                className="map-controller-arrow"
                            >
                                <ChevronLeftRounded sx={{ color: 'white' }} />
                            </IconButton>
                        </Grid>
                        <Grid
                            container
                            item
                            xs
                            alignItems="center"
                            justifyContent="flex-start"
                            className="map-controller-menu"
                            ref={menuRef}
                            onScroll={() => {
                                setMenuScroll({
                                    left: menuRef.current.scrollLeft,
                                    leftMax:
                                        menuRef.current.scrollWidth - menuRef.current.clientWidth,
                                });
                            }}
                        >
                            {menuItems.map(({ key, component: MenuItem, ...itemProps }) => {
                                return (
                                    <MapControllerButton key={key} {...itemProps}>
                                        <MenuItem />
                                    </MapControllerButton>
                                );
                            })}
                        </Grid>
                        <Grid item>
                            <IconButton
                                size="small"
                                disabled={
                                    menuScroll.left !== 0 && menuScroll.left >= menuScroll.leftMax
                                }
                                onClick={() => {
                                    menuRef.current.scrollBy(110, 0);
                                }}
                                className="map-controller-arrow"
                            >
                                <ChevronRightRounded sx={{ color: 'white' }} />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Paper>
            </RadarDateContextProvider>
        </MenuContextProvider>
    );
};

export default MapController;
