/* eslint-disable jsx-a11y/control-has-associated-label */
import { MyLocation } from '@mui/icons-material';
import { useMap } from 'es-map-widget';
import React, { useState } from 'react';
import { BASE_COLORS } from '../../constants/colors';

const GeolocationButton = () => {
    const map = useMap();
    const [isLocationVisible, setIsLocationVisible] = useState(false);
    return (
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        <button
            type="button"
            onClick={() => {
                setIsLocationVisible((prevValue) => !prevValue);
                map.toggleGeolocation(!isLocationVisible);
            }}
            style={{
                position: 'absolute',
                top: '64px',
                right: '10px',
                display: 'flex',
                padding: '3px 5px',
                fontSize: '12px',
                borderRadius: '3px',
                border: isLocationVisible
                    ? `2px solid ${BASE_COLORS.PRIMARY_MAIN}`
                    : '1px solid #efefef',
            }}
        >
            <MyLocation fontSize="inherit" />
        </button>
    );
};

export default GeolocationButton;
