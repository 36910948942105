import { AddLocationRounded } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { MAP_EVENTS, useMap } from 'es-map-widget';
import React, { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DRAWING_STATES, MODAL_TYPE, OVERVIEW_STATES, TOOLTIP_TEXTS } from '../../../constants';
import { useModals } from '../../../hooks/useModal';
import { useAppControl } from '../../../providers/AppContext';

const PointAddYieldButton = ({ disabled }) => {
    const map = useMap();
    const { setDrawingState, setOverviewState, samplingData } = useAppControl();
    const { showModal } = useModals();
    const [searchParams] = useSearchParams();
    const damagePlotId = searchParams.get('damagePlotId');

    const handlePointYield = () => {
        setDrawingState(DRAWING_STATES.POINT_YIELD);
        setOverviewState(OVERVIEW_STATES.HIDDEN);
        map.selectLayerStartSelect(samplingData.points);
    };

    const onSelection = useCallback(
        (selectedElement) => {
            if (!selectedElement) {
                return;
            }

            showModal(MODAL_TYPE.SAMPLING_MODIFICATION_MODAL, {
                samplingPoint: selectedElement,
                damagePlotId,
            });
        },
        [showModal, damagePlotId, showModal]
    );

    useEffect(() => {
        const selectionListener = map.addEventListener(MAP_EVENTS.selection, () => {
            onSelection(map.getSelected());
        });
        return () => map.removeEventListener(MAP_EVENTS.selection, selectionListener);
    }, [map, onSelection]);

    return (
        <Tooltip title={TOOLTIP_TEXTS.addYield} placement="right">
            <span>
                <IconButton onClick={handlePointYield} disabled={disabled}>
                    <AddLocationRounded />
                </IconButton>
            </span>
        </Tooltip>
    );
};

export default PointAddYieldButton;
