import { Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { object, string } from 'yup';
import { api } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import { useAuth } from '../../../providers/AuthProvider';

const nameValidationSchema = object({
    displayName: string().required('A mező kitöltése kötelező'),
});

const UserChangeForm = () => {
    const { user, setUser } = useAuth();
    const { notifyError, notifySuccess } = useNotify();

    const [loading, setLoading] = useState(false);

    const initialValues = {
        displayName: user.displayName,
    };

    const submitUserChangeForm = ({ displayName }) => {
        setLoading(true);
        api.users
            .updateCurrentUser({
                displayName,
            })
            .then((res) => {
                notifySuccess('Sikeres adatmódosítás');
                setUser({ ...res.data, token: user.token });
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                notifyError(err);
            });
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={submitUserChangeForm}
            validationSchema={nameValidationSchema}
        >
            {({ values, errors, handleChange }) => (
                <Form style={{ padding: '16px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h5">Megjelenített név megváltoztatása</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                name="displayName"
                                label="Megjelenített név"
                                value={values.displayName}
                                onChange={handleChange}
                                error={!!errors.displayName}
                                helperText={errors.displayName}
                            />
                        </Grid>
                        <Grid item xs={12} textAlign="center">
                            <Button type="submit" fullWidth variant="contained">
                                {!loading ? (
                                    'Mentés'
                                ) : (
                                    <CircularProgress color="secondary" size={25} />
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default UserChangeForm;
