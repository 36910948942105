import { getDate, getMonth } from 'date-fns';
import React, { createContext, useContext, useMemo, useState } from 'react';
import { CONTROLLER_KEYS } from '../../../constants';
import { useAppControl } from '../../../providers/AppContext';

const WmsDateContext = createContext();
WmsDateContext.displayName = 'WmsDateContext';

const today = new Date();

export function WmsDateContextProvider({ children }) {
    const { year } = useAppControl();
    const [wmsMonth, setWmsMonth] = useState(getMonth(today));
    const [wmsData, setWmsData] = useState({
        isLayerVisible: false,
        type: CONTROLLER_KEYS.NDVI,
        date: new Date(year, wmsMonth, getDate(today)),
        layerStringCode: '',
        layerStringDate: '',
        meparCode: null,
        damagePlotId: null,
        damageId: null,
        changeMap: null,
        damageMap: null,
    });

    const [wmsDownload, setWmsDownload] = useState({
        ndvi: false,
        visible: false,
        heterogeneity: false,
    });

    const contextValue = useMemo(
        () => ({
            wmsData,
            wmsMonth,
            wmsDownload,
            setWmsData,
            setWmsMonth,
            setWmsDownload,
        }),
        [wmsData, wmsMonth, wmsDownload, setWmsData, setWmsMonth, setWmsDownload]
    );
    return <WmsDateContext.Provider value={contextValue}>{children}</WmsDateContext.Provider>;
}

export function useWmsDates() {
    return useContext(WmsDateContext);
}
