import { Circle, Fill, Stroke, Text } from 'es-map-widget';
import { FROST_DAMAGE_COLORS } from '../../constants';

const textStyle = {
    font: '12px sans-serif',
    fill: new Fill({ color: '#000' }),
    stroke: new Stroke({
        color: '#fff',
        width: 2,
    }),
    text: '',
};

const styles = {
    mepar: {
        stroke: new Stroke({
            color: '#ffff00',
            width: 3,
        }),
        fill: new Fill({
            color: [255, 0, 0, 0],
        }),
        text: new Text(textStyle),
    },
    field: {
        stroke: new Stroke({
            color: '#ff0000',
            width: 3,
        }),
        fill: new Fill({
            color: [0, 0, 255, 0],
        }),
        text: new Text(textStyle),
    },
    selected: {
        stroke: new Stroke({
            color: 'black',
            width: 3,
        }),
        fill: new Fill({
            color: [0, 255, 255, 0.1],
        }),
        text: new Text({ ...textStyle }),
    },
    sample: {
        image: new Circle({
            radius: 7,
            fill: new Fill({ color: 'black' }),
            stroke: new Stroke({
                color: [255, 0, 0],
                width: 2,
            }),
        }),
    },
    frostDamage: (value) => ({
        stroke: new Stroke({
            color: FROST_DAMAGE_COLORS[value],
            width: 2,
        }),
        fill: new Fill({
            color: FROST_DAMAGE_COLORS[value],
        }),
    }),
    corn: {
        stroke: new Stroke({
            color: [0, 0, 0, 0],
            width: 0,
        }),
        fill: new Fill({
            color: [241, 198, 0, 0.5],
        }),
        text: new Text({
            ...textStyle,
            text: 'Kukorica',
        }),
    },
    autumnCabbageRape: {
        stroke: new Stroke({
            color: [0, 0, 0, 0],
            width: 0,
        }),
        fill: new Fill({
            color: [118, 234, 182, 0.5],
        }),
        text: new Text({
            ...textStyle,
            text: 'Őszi káposztarepce',
        }),
    },
    spike: {
        stroke: new Stroke({
            color: [0, 0, 0, 0],
            width: 0,
        }),
        fill: new Fill({
            color: [147, 234, 118, 0.5],
        }),
        text: new Text({
            ...textStyle,
            text: 'Kalászosok',
        }),
    },
    sunflower: {
        stroke: new Stroke({
            color: [0, 0, 0, 0],
            width: 0,
        }),
        fill: new Fill({
            color: [0, 202, 137, 0.5],
        }),
        text: new Text({
            ...textStyle,
            text: 'Napraforgó',
        }),
    },
    cities: {
        stroke: new Stroke({
            color: [255, 255, 255, 1],
            width: 1,
        }),
        fill: new Fill({
            color: [255, 0, 0, 0],
        }),
    },
};

export { styles, textStyle };
