import { Alert, CircularProgress, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { useApi } from '../../../../../hooks/useApi';
import { useLayout } from '../../../../../providers/LayoutContext';

const RadarDates = () => {
    const { selectedProduct } = useLayout();

    const [result, loading, error] = useApi(
        (API, params) =>
            API.layers.getRadarDatesForMepar(
                {
                    meparCode: selectedProduct.mepar.meparCode,
                    damageDate: format(new Date(selectedProduct.damage.damageDate), 'yyyy-MM-dd'),
                },
                params
            ),
        [selectedProduct.mepar.meparCode]
    );

    if (error) {
        return (
            <Grid item>
                <Alert severity="error">Hiba történt a kérés teljesítése során</Alert>
            </Grid>
        );
    }

    if (loading) {
        return (
            <Grid item xs={12} textAlign="center">
                <CircularProgress />
            </Grid>
        );
    }

    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h6">Kritikus időjárási jelenségek időpontjai:</Typography>
            </Grid>
            {result.length > 0 ? (
                <Grid item xs={12}>
                    {result.map((date) => (
                        <div key={date}>{date}</div>
                    ))}
                </Grid>
            ) : (
                <Grid item xs={12}>
                    <Alert severity="info">Nincs kritikus időjárási jelenség</Alert>
                </Grid>
            )}
        </>
    );
};

export default RadarDates;
