import { Alert, CircularProgress, Grid, Typography } from '@mui/material';
import React from 'react';
import { useApi } from '../../../../hooks/useApi';
import { useAppControl } from '../../../../providers/AppContext';
import { formatNumberToDecimal } from '../../../../utils/formatter';

const DamageSummary = () => {
    const { year } = useAppControl();
    const [result, loading, error] = useApi(
        (api, objWithSignal) => api.dashboards.getDamageOverviewDashboard({ year }, objWithSignal),
        [year]
    );

    if (error) {
        return (
            <Grid item>
                <Alert severity="error">Hiba történt a kérés teljesítése során</Alert>
            </Grid>
        );
    }

    if (loading) {
        return (
            <Grid item>
                <CircularProgress />
            </Grid>
        );
    }

    return (
        <Grid container item spacing={2} direction="column">
            <Grid item>
                <Typography variant="h5">
                    Jegyzőkönyvek alapján lezárt kárakták száma:{' '}
                    {formatNumberToDecimal(result.stats.closed, 'db', 0)}
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="h5">
                    Jegyzőkönyvek alapján még nem lezárt kárakták száma:{' '}
                    {formatNumberToDecimal(result.stats.count, 'db', 0)}
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="h5">
                    Idei ({year}) összes kifizetési javaslat összege (ft):{' '}
                    {formatNumberToDecimal(result.sum, 'Ft', 0)}
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="h5">
                    Idei ({year}) kártartalék összege: {formatNumberToDecimal(0, 'Ft', 0)}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default DamageSummary;
