import React from 'react';

const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            style={{ padding: '16px', width: '100%' }}
        >
            {value === index && children}
        </div>
    );
};

export default TabPanel;
