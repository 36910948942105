import { Circle, Fill, Stroke, Style, Text } from 'es-map-widget';
import { useCallback, useEffect } from 'react';

const KarnaviPhotoDataprovider = ({ extent, layerData, setSource }) => {
    const addStyleToGeoms = useCallback(
        (meparsWithoutStyle) => {
            return meparsWithoutStyle.map((e, i) => {
                return {
                    ...e,
                    style: new Style({
                        image: new Circle({
                            radius: 8,
                            fill: new Fill({ color: 'black' }),
                            stroke: new Stroke({
                                color: [0, 0, 255],
                                width: 2,
                            }),
                        }),
                        text: new Text({
                            font: '13px sans-serif',
                            overflow: true,
                            placement: 'above',
                            fill: new Fill({
                                color: 'white',
                            }),
                            text: `${i + 1}`,
                        }),
                    }),
                };
            });
        },
        [layerData]
    );

    useEffect(() => {
        if (!layerData.geoms || layerData.geoms.length === 0 || !layerData.isLayerVisible) {
            setSource(null);
            return;
        }

        setSource(addStyleToGeoms(layerData.geoms));
    }, [extent, layerData, setSource]);

    return null;
};

export default KarnaviPhotoDataprovider;
