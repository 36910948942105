import { CancelRounded } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useMap } from 'es-map-widget';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { DRAWING_STATES, MAP_LAYER_NAMES, OVERVIEW_STATES } from '../../../constants';
import { useAppControl } from '../../../providers/AppContext';

const PointCancelDeleteButton = () => {
    const map = useMap();
    const { setDrawingState, overviewState, setOverviewState } = useAppControl();
    const [_, setSearchParams] = useSearchParams();

    const handleClick = () => {
        map.drawCancel();
        map.selectLayerStopSelect();
        setOverviewState(OVERVIEW_STATES.OPEN);
        setDrawingState(DRAWING_STATES.NONE);
        map.setLayerVisibility(MAP_LAYER_NAMES.ZONE, false);
        map.setLayerVisibility(MAP_LAYER_NAMES.SAMPLING, false);
        if (overviewState === OVERVIEW_STATES.CLOSED) {
            setSearchParams({});
        }
    };
    return (
        <IconButton onClick={handleClick}>
            <CancelRounded />
        </IconButton>
    );
};

export default PointCancelDeleteButton;
