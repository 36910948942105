import React, { createContext, useContext, useMemo, useState } from 'react';

const DrawContext = createContext();
DrawContext.displayName = 'DrawContext';

export function DrawContextProvider({ children }) {
    const [isLoading, setIsLoading] = useState(false);
    const [isGeneratingSamples, setIsGeneratingSamples] = useState(false);
    const [hasAreaDrawn, setHasAreaDrawn] = useState(false);

    const contextValue = useMemo(
        () => ({
            isLoading,
            setIsLoading,
            isGeneratingSamples,
            setIsGeneratingSamples,
            hasAreaDrawn,
            setHasAreaDrawn,
        }),
        [
            isLoading,
            setIsLoading,
            isGeneratingSamples,
            setIsGeneratingSamples,
            hasAreaDrawn,
            setHasAreaDrawn,
        ]
    );

    return <DrawContext.Provider value={contextValue}>{children}</DrawContext.Provider>;
}

export function useDrawContext() {
    return useContext(DrawContext);
}
