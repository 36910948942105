import { Grid, Paper } from '@mui/material';
import React from 'react';
import PasswordChangeForm from './components/profile/PasswordChangeForm';
import UsernameChangeForm from './components/profile/UsernameChangeForm';

const ProfilePage = () => {
    return (
        <Grid container justifyContent="center" sx={{ mt: 2 }}>
            <Grid item xs={12} lg={6}>
                <Paper>
                    <UsernameChangeForm />
                    <PasswordChangeForm />
                </Paper>
            </Grid>
        </Grid>
    );
};

export default ProfilePage;
