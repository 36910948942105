import { getDate, getMonth } from 'date-fns';
import { useMap } from 'es-map-widget';
import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { CONTROLLER_KEYS, DRAWING_STATES, MAP_LAYER_NAMES } from '../constants';
import { useAppControl } from './AppContext';
import { useWmsDates } from '../components/mapcontroller/contexts/WmsDateProvider';

const LayoutContext = createContext();
LayoutContext.displayName = 'LayoutContext';

const today = new Date();

export function MapFunctionsProvider({ children }) {
    const map = useMap();
    const { year, setDrawingState } = useAppControl();
    const { setWmsData } = useWmsDates();

    const resetMap = useCallback(() => {
        try {
            map.setLayerData(MAP_LAYER_NAMES.MEPAR, { geoms: [] });
            map.setLayerData(MAP_LAYER_NAMES.FIELDS, { geoms: [] });
            map.setLayerData(MAP_LAYER_NAMES.SAMPLING, { geoms: [] });
            map.setLayerData(MAP_LAYER_NAMES.ZONE, { geoms: [] });
            map.setLayerVisibility(MAP_LAYER_NAMES.MEPAR, false);
            map.setLayerVisibility(MAP_LAYER_NAMES.FIELDS, false);
            map.setLayerVisibility(MAP_LAYER_NAMES.SAMPLING, false);
            map.setLayerVisibility(MAP_LAYER_NAMES.ZONE, false);
            map.setLayerVisibility(MAP_LAYER_NAMES.KARNAVI, false);
            map.setLayerVisibility(MAP_LAYER_NAMES.NDVI, false);
            map.removePopover();
            map.drawCancel();
            setWmsData({
                isLayerVisible: false,
                type: CONTROLLER_KEYS.NDVI,
                date: new Date(year, getMonth(today), getDate(today)),
                layerStringCode: '',
                layerStringDate: '',
                meparCode: null,
                damagePlotId: null,
                damageId: null,
                changeMap: null,
                damageMap: null,
            });
            setDrawingState(DRAWING_STATES.NONE);
        } catch (error) {
            console.log(error);
        }
    }, [map, setDrawingState]);

    const contextValue = useMemo(
        () => ({
            resetMap,
        }),
        [resetMap]
    );

    return <LayoutContext.Provider value={contextValue}>{children}</LayoutContext.Provider>;
}

export function useMapFunctions() {
    return useContext(LayoutContext);
}
