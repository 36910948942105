import { Button, ClickAwayListener, Paper, Popper } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useMenu } from '../contexts/MenuContext';
import { useAuth } from '../../../providers/AuthProvider';

const MapControllerButton = ({ label, menuKey, permissions, children }) => {
    const { user } = useAuth();
    const { checkedItems, setMenuOpen } = useMenu();
    const [anchorEl, setAnchorEl] = useState(null);
    const [className, setClassName] = useState('');

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const getClassName = useCallback(() => {
        if (Array.isArray(menuKey)) {
            return menuKey.some((key) => {
                if (checkedItems[key]?.isLayerVisible) {
                    return true;
                }
                return checkedItems[key]?.length > 0;
            })
                ? 'has-items-checked'
                : '';
        }

        return checkedItems[menuKey]?.length > 0 ? 'has-items-checked' : '';
    }, [checkedItems, menuKey]);

    useEffect(() => {
        setClassName(() => getClassName(checkedItems, menuKey));
    }, [checkedItems, menuKey, setClassName]);

    useEffect(() => {
        setMenuOpen(open);
    }, [open, setMenuOpen]);

    if (!permissions.includes(user.role) && permissions.length > 0) {
        return null;
    }

    return (
        <div>
            <Button
                variant="contained"
                onClick={handleClick}
                disableRipple
                disableTouchRipple
                disableFocusRipple
                className="map-controller-button"
            >
                <span className={className}>{label}</span>
            </Button>
            <Popper
                open={open}
                anchorEl={anchorEl}
                placement="bottom-start"
                sx={{
                    zIndex: 1100,
                }}
            >
                <ClickAwayListener onClickAway={handleClose} touchEvent={false}>
                    <Paper className="map-controller-dropdown">{children}</Paper>
                </ClickAwayListener>
            </Popper>
        </div>
    );
};

export default MapControllerButton;
