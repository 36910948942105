import { Grid } from '@mui/material';
import clsx from 'clsx';
import { useMap } from 'es-map-widget';
import React, { useState } from 'react';
import { CONTROLLER_KEYS, CONTROLLER_LABELS } from '../../../constants';
import { MAP_LAYER_NAMES } from '../../../constants/mapLayerNames';
import { useLayout } from '../../../providers/LayoutContext';
import RadarDataController from '../components/RadarDataController';
import { useMenu } from '../contexts/MenuContext';
import { useRadarDates } from '../contexts/RadarDateProvider';
import MapControllerMenuItem from '../menu/MapControllerMenuItem';
import SubMenuWrapper from '../menu/SubMenuWrapper';

const RadarController = () => {
    const map = useMap();
    const { radarData } = useRadarDates();
    const { checkedItems, handleItemCheck } = useMenu();
    const { matchesLgUp } = useLayout();
    const [isRadarLayerVisible, setIsRadarLayerVisible] = useState(
        checkedItems[CONTROLLER_KEYS.RADAR].includes(CONTROLLER_KEYS.RADAR)
    );

    return (
        <Grid container>
            <Grid
                item
                xs={12}
                lg={6}
                className={clsx('dropdown-level-1', !matchesLgUp && 'mobile')}
            >
                <MapControllerMenuItem
                    text={CONTROLLER_LABELS.RADAR}
                    checkbox={{
                        checked: isRadarLayerVisible,
                        onChange: (event) => {
                            setIsRadarLayerVisible((p) => !p);
                            map.setLayerVisibility(MAP_LAYER_NAMES.RADAR, !isRadarLayerVisible);
                            if (!event.target.checked) {
                                map.setLayerData(MAP_LAYER_NAMES.RADAR_MEPAR, {
                                    isLayerVisible: false,
                                });
                                map.setLayerVisibility(MAP_LAYER_NAMES.RADAR_MEPAR, false);
                            } else {
                                map.setLayerData(MAP_LAYER_NAMES.RADAR_MEPAR, {
                                    isLayerVisible: radarData.isMeparLayerVisible,
                                    date: radarData.date,
                                });
                                map.setLayerVisibility(
                                    MAP_LAYER_NAMES.RADAR_MEPAR,
                                    radarData.isMeparLayerVisible
                                );
                            }
                            handleItemCheck(CONTROLLER_KEYS.RADAR, CONTROLLER_KEYS.RADAR);
                        },
                    }}
                    disabled={!radarData?.date}
                />
            </Grid>
            <Grid
                item
                xs={12}
                lg={6}
                className={clsx('dropdown-level-2', !matchesLgUp && 'mobile')}
            >
                <SubMenuWrapper isVisible>
                    <RadarDataController isLayerVisible={isRadarLayerVisible} />
                </SubMenuWrapper>
            </Grid>
        </Grid>
    );
};

export default RadarController;
