import { InfoRounded } from '@mui/icons-material';
import { IconButton, Popover, Typography } from '@mui/material';
import React, { useState } from 'react';

const InfoPopover = ({ text }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <IconButton onClick={handleClick}>
                <InfoRounded />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
            >
                <Typography variant="body2" sx={{ p: 2, maxWidth: '350px' }}>
                    {text}
                </Typography>
            </Popover>
        </>
    );
};

export default InfoPopover;
