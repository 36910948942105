import { Fill, Stroke, Style, Text } from 'es-map-widget';
import { useCallback, useEffect } from 'react';
import { styles } from '../mapwrapper/MapWrapperStyles';

const SamplingDataprovider = ({ extent, layerData, setSource }) => {
    const addStyleToMepars = useCallback(
        (meparsWithoutStyle) => {
            return meparsWithoutStyle.map((e) => {
                return {
                    ...e,
                    style: new Style({
                        ...styles.sample,
                        stroke: new Stroke({
                            color: e.isGenerated ? [255, 0, 0] : [0, 255, 0],
                            width: 2,
                        }),
                        text: new Text({
                            font: '14px sans-serif',
                            overflow: true,
                            placement: 'above',
                            fill: new Fill({
                                color: 'white',
                            }),
                            text: `${e.sequenceId}`,
                        }),
                    }),
                };
            });
        },
        [layerData]
    );
    useEffect(() => {
        if (!layerData.geoms || layerData.geoms.length === 0) {
            setSource(null);
            return;
        }

        setSource(addStyleToMepars(layerData.geoms));
    }, [extent, layerData, setSource]);

    return null;
};

export default SamplingDataprovider;
