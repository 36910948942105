import { ColorizeRounded, SquareFootRounded } from '@mui/icons-material';
import { Divider, Grid, IconButton, Tooltip } from '@mui/material';
import { useMap } from 'es-map-widget';
import React, { useState } from 'react';
import { MAP_LAYER_NAMES, OVERVIEW_STATES, OVERVIEW_TYPES } from '../../constants';
import { DRAWING_STATES } from '../../constants/drawingStates';
import { TOOLTIP_TEXTS } from '../../constants/texts';
import { useAppControl } from '../../providers/AppContext';
import { useLayout } from '../../providers/LayoutContext';
import PointDrawControls from './PointDrawControls';
import PolygonDrawControls from './PolygonDrawControls';

const DrawControls = () => {
    const map = useMap();
    const { selectedProduct } = useLayout();
    const { drawingState, setDrawingState, setOverviewState } = useAppControl();
    const [isDrawingPoints, setIsDrawingPoints] = useState(false);

    return (
        <>
            <Divider />
            <Grid item container direction="column" xs={12}>
                {!drawingState && selectedProduct?.type !== OVERVIEW_TYPES.FIELD_INSURED && (
                    <>
                        <Tooltip title={TOOLTIP_TEXTS.damagePlot} placement="right">
                            <IconButton
                                onClick={() => {
                                    setDrawingState(DRAWING_STATES.POLYGON);
                                    setOverviewState(OVERVIEW_STATES.HIDDEN);
                                    map.drawPolygon();
                                }}
                            >
                                <SquareFootRounded />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={TOOLTIP_TEXTS.sampling} placement="right">
                            <IconButton
                                onClick={() => {
                                    setIsDrawingPoints(true);
                                    setDrawingState(DRAWING_STATES.POINT_SETUP);
                                    map.setLayerVisibility(MAP_LAYER_NAMES.SAMPLING, true);
                                    map.setLayerVisibility(MAP_LAYER_NAMES.ZONE, true);
                                }}
                            >
                                <ColorizeRounded />
                            </IconButton>
                        </Tooltip>
                    </>
                )}
                {drawingState === DRAWING_STATES.POLYGON && <PolygonDrawControls />}
                <PointDrawControls
                    isDrawingPoints={isDrawingPoints}
                    setIsDrawingPoints={setIsDrawingPoints}
                />
            </Grid>
        </>
    );
};

export default DrawControls;
