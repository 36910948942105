import { format } from 'date-fns';
import {
    Circle,
    Fill,
    FullScreen,
    getDefaultViewOptions,
    Stroke,
    Style,
    Text,
    Zoom,
    MousePosition,
    createStringXY,
} from 'es-map-widget';

const MAP_OPTIONS = {
    viewOptions: { ...getDefaultViewOptions() },
    selectOptions: null,
    drawOptions: {
        drawLayerStyle: (feature) => {
            const props = feature.getGeometry().getProperties();
            return [
                new Style({
                    image: new Circle({
                        radius: 7,
                        fill: new Fill({ color: 'black' }),
                        stroke: new Stroke({
                            color: props?.isGenerated ? [0, 0, 255] : [0, 255, 0],
                            width: 2,
                        }),
                    }),
                    text: new Text({
                        font: '14px sans-serif',
                        overflow: true,
                        placement: 'above',
                        fill: new Fill({
                            color: 'white',
                        }),
                        text: `${props?.sequenceId || ''}`,
                    }),
                }),
            ];
        },
        drawStyle: {
            image: new Circle({
                radius: 5,
                stroke: new Stroke({
                    color: 'rgba(255, 0, 0)',
                }),
                fill: new Fill({
                    color: 'rgba(255, 255, 255)',
                }),
            }),
        },
    },
    selectLayerOptions: {
        filter(feature) {
            return feature.getGeometry().getType() === 'Point';
        },
    },
    popupTemplate: {
        title: () => {
            return `Kárfotó`;
        },
        attributes: {
            // az attributes key-eknek ugyanannak a névnek kell lennie mint a feature-ön a get-el lekérhető névnek
            serialNumber: {
                title: 'Sorszám',
                format(value, feature) {
                    return feature.get('serialNumber') || 'Nem elérhető';
                },
            },
            damageNumber: {
                title: 'Kárszám',
                format: (value, feature) => {
                    return feature.get('damageNumber') || 'Nem elérhető';
                },
            },
            inspectionNumber: {
                title: 'Szemle azonosító',
                format: (value, feature) => {
                    return feature.get('inspectionNumber') || 'Nem elérhető';
                },
            },
            externalId: {
                title: 'Kárnavi azonosító',
                format: (value, feature) => {
                    return feature.get('externalId') || 'Nem elérhető';
                },
            },
            photoCreatedAt: {
                title: 'Kép készítésének időpontja',
                format: (value, feature) => {
                    const photoDate = feature.get('photoCreatedAt');
                    return photoDate
                        ? format(new Date(photoDate), 'yyyy. MM. dd HH:mm')
                        : 'Nem elérhető';
                },
            },
        },
    },
    popupCondition: 'singleClick',
    controls: [
        new FullScreen(),
        new Zoom(),
        new MousePosition({
            coordinateFormat: createStringXY(0),
            projection: 'EPSG:23700',
            undefinedHTML: '&nbsp;',
        }),
        new MousePosition({
            coordinateFormat: createStringXY(6),
            projection: 'EPSG:4326',
            undefinedHTML: '&nbsp;',
            className: 'wgs84-mouse-position',
        }),
    ],
    geolocationOptions: {
        geoLocationStyle: {
            image: new Circle({
                radius: 6,
                fill: new Fill({
                    color: '#3399CC',
                }),
                stroke: new Stroke({
                    color: '#fff',
                    width: 2,
                }),
            }),
        },
    },
};

// eslint-disable-next-line import/prefer-default-export
export { MAP_OPTIONS };
