/* eslint-disable react/no-array-index-key */
import { Alert, CircularProgress, Grid, TableCell, TableRow } from '@mui/material';
import React from 'react';
import { useApi } from '../../../../hooks/useApi';
import { formatNumberToDecimal } from '../../../../utils/formatter';
import DashboardTable from '../DashboardTable';
import { useAppControl } from '../../../../providers/AppContext';

const Experts = () => {
    const { year } = useAppControl();
    const [result, loading, error] = useApi(
        (api, objWithSignal) => api.dashboards.getExpertDamagesDashboard({ year }, objWithSignal),
        [year]
    );

    if (loading) {
        return (
            <Grid container justifyContent="center" alignContent="flex-start">
                <CircularProgress />
            </Grid>
        );
    }

    if (error) {
        return (
            <Grid container>
                <Alert severity="error">{error.message}</Alert>
            </Grid>
        );
    }
    return (
        <Grid container item spacing={2}>
            <DashboardTable
                title={`${year} - Kárszakértők káraktáinak (db), károsodott tábláinak (db), megrajzolt tábláinak (db) száma és károsodott területeinek mérete (ha)`}
                gridProps={{ xs: 12, lg: 6 }}
                headerRows={[
                    'Kárszakértő',
                    'Kárakták száma',
                    'Károsodott táblák',
                    'Megrajzolt táblák',
                    'Károsodott terület',
                ]}
                loading={loading}
                error={error}
                tableSx={{
                    maxHeight: 'calc(100vh - 260px)',
                }}
            >
                {result.tables &&
                    result.tables.length > 0 &&
                    result.tables.map(
                        (
                            { name, damageCount, damagePlotCount, damagedArea, drawnPlotCount },
                            i
                        ) => (
                            <TableRow key={i}>
                                <TableCell>{name}</TableCell>
                                <TableCell align="right">
                                    {formatNumberToDecimal(damageCount, 'db', 0)}
                                </TableCell>
                                <TableCell align="right">
                                    {formatNumberToDecimal(damagePlotCount, 'db', 0)}
                                </TableCell>
                                <TableCell align="right">
                                    {formatNumberToDecimal(drawnPlotCount, 'db', 0)}
                                </TableCell>
                                <TableCell align="right">
                                    {formatNumberToDecimal(damagedArea, 'ha')}
                                </TableCell>
                            </TableRow>
                        )
                    )}
            </DashboardTable>
            <DashboardTable
                title={`${year} - Kárszakértők által vett mintavételek száma (db)`}
                gridProps={{ xs: 12, lg: 6 }}
                headerRows={['Kárszakértő', 'Db']}
                loading={loading}
                error={error}
                tableSx={{
                    maxHeight: 'calc(100vh - 260px)',
                }}
            >
                {result.samplings &&
                    result.samplings.length > 0 &&
                    result.samplings.map(({ name, samplingCount }, i) => (
                        <TableRow key={i}>
                            <TableCell>{name}</TableCell>
                            <TableCell align="right">
                                {formatNumberToDecimal(samplingCount, 'db', 0)}
                            </TableCell>
                        </TableRow>
                    ))}
            </DashboardTable>
            <DashboardTable
                title={`${year} - Kárszakértők aktív és lezárt káraktáinak száma (db)`}
                gridProps={{ xs: 12, lg: 6 }}
                headerRows={['Kárszakértő', 'Lezárt', 'Nyitott']}
                loading={loading}
                error={error}
                tableSx={{
                    maxHeight: 'calc(100vh - 260px)',
                }}
            >
                {result.experts &&
                    result.experts.length > 0 &&
                    result.experts.map(({ expert, open, closed }) => (
                        <TableRow key={`${expert}`}>
                            <TableCell>{expert}</TableCell>
                            <TableCell align="right">
                                {formatNumberToDecimal(closed, 'db', 0)}
                            </TableCell>
                            <TableCell align="right">
                                {formatNumberToDecimal(open, 'db', 0)}
                            </TableCell>
                        </TableRow>
                    ))}
            </DashboardTable>
        </Grid>
    );
};

export default Experts;
