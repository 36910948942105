import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROLES } from './constants';
import AdminLayout from './layouts/AdminLayout';
import AppLayout from './layouts/AppLayout';
import PageLayout from './layouts/PageLayout';
import AccessDenied from './pages/AccessDenied';
import AdminDashboard from './pages/AdminDashboard';
import AdminDamageExperts from './pages/components/admin/AdminDamageExperts';
import AdminDamageReports from './pages/components/admin/AdminDamageReports';
import AdminPolicies from './pages/components/admin/AdminPolicies';
import DamageReportPage from './pages/DamageReportPage';
import Home from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import MeparPage from './pages/MeparPage';
import NotFound from './pages/NotFoundPage';
import PolicyPage from './pages/PolicyPage';
import ProfilePage from './pages/ProfilePage';
import ProtectedPage from './pages/ProtectedPage';

const App = () => {
    return (
        <Routes>
            <Route element={<AppLayout />}>
                <Route
                    path="/"
                    element={
                        <ProtectedPage>
                            <PageLayout>
                                <Home />
                            </PageLayout>
                        </ProtectedPage>
                    }
                />
                <Route
                    path="profile"
                    element={
                        <ProtectedPage>
                            <ProfilePage />
                        </ProtectedPage>
                    }
                />
                <Route
                    path="/products/policy/:id"
                    element={
                        <ProtectedPage
                            allowedRoles={[
                                ROLES.ADMIN,
                                ROLES.API,
                                ROLES.EXPERT_DAMAGE,
                                ROLES.EXPERT_RISK_TAKING,
                                ROLES.EXPERT_UW,
                                ROLES.KARNAVIGATOR,
                                ROLES.MANAGER,
                                ROLES.OPERATOR,
                                ROLES.PUCCINI,
                            ]}
                        >
                            <PageLayout>
                                <PolicyPage />
                            </PageLayout>
                        </ProtectedPage>
                    }
                />
                <Route
                    path="/products/mepar/:id"
                    element={
                        <ProtectedPage
                            allowedRoles={[
                                ROLES.ADMIN,
                                ROLES.API,
                                ROLES.EXPERT_DAMAGE,
                                ROLES.EXPERT_RISK_TAKING,
                                ROLES.EXPERT_UW,
                                ROLES.KARNAVIGATOR,
                                ROLES.MANAGER,
                                ROLES.OPERATOR,
                                ROLES.PUCCINI,
                            ]}
                        >
                            <PageLayout>
                                <MeparPage />
                            </PageLayout>
                        </ProtectedPage>
                    }
                />
                <Route
                    path="/products/report/:id"
                    element={
                        <ProtectedPage
                            allowedRoles={[
                                ROLES.ADMIN,
                                ROLES.API,
                                ROLES.EXPERT_DAMAGE,
                                ROLES.EXPERT_RISK_TAKING,
                                ROLES.EXPERT_UW,
                                ROLES.KARNAVIGATOR,
                                ROLES.MANAGER,
                                ROLES.OPERATOR,
                                ROLES.PUCCINI,
                            ]}
                        >
                            <PageLayout>
                                <DamageReportPage />
                            </PageLayout>
                        </ProtectedPage>
                    }
                />
                <Route path="login" element={<LoginPage />} />
                <Route path="*" element={<NotFound />} />
            </Route>
            <Route element={<AdminLayout />}>
                <Route
                    path="/dashboard/"
                    element={
                        <ProtectedPage allowedRoles={[ROLES.ADMIN, ROLES.MANAGER]}>
                            <AdminDashboard />
                        </ProtectedPage>
                    }
                />
                <Route
                    path="/dashboard/damageexperts"
                    element={
                        <ProtectedPage allowedRoles={[ROLES.ADMIN, ROLES.MANAGER]}>
                            <AdminDamageExperts />
                        </ProtectedPage>
                    }
                />
                <Route
                    path="/dashboard/damagereports"
                    element={
                        <ProtectedPage allowedRoles={[ROLES.ADMIN, ROLES.MANAGER]}>
                            <AdminDamageReports />
                        </ProtectedPage>
                    }
                />
                <Route
                    path="/dashboard/policies"
                    element={
                        <ProtectedPage allowedRoles={[ROLES.ADMIN, ROLES.MANAGER]}>
                            <AdminPolicies />
                        </ProtectedPage>
                    }
                />
            </Route>
            <Route path="/accessdenied" element={<AccessDenied />} />
        </Routes>
    );
};

export default App;
