import { Box, Dialog, DialogContent, DialogTitle, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import { MODAL_TYPE } from '../constants';
import { useLayout } from '../providers/LayoutContext';
import ImportFile from './components/ImportFile';
import NewDamageReport from './components/NewDamageReport';

function TabPanel(props) {
    const { matchesLgUp } = useLayout();
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: matchesLgUp ? 3 : 1, pb: 0 }}>{children}</Box>}
        </div>
    );
}

const NewDamageReportModal = ({ showModal }) => {
    const { matchesLgUp } = useLayout();

    const handleClose = (_, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        showModal(MODAL_TYPE.NONE);
    };
    const [tabValue, setTabValue] = useState(0);

    return (
        <Dialog open onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle>Új kárakta</DialogTitle>
            <DialogContent sx={{ p: matchesLgUp ? 2 : 1 }}>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={tabValue}
                            onChange={(event, newValue) => setTabValue(newValue)}
                            aria-label="basic tabs example"
                        >
                            <Tab label="Kézi adatmegadás" />
                            <Tab label="Importálás" />
                        </Tabs>
                    </Box>
                    <TabPanel value={tabValue} index={0}>
                        <NewDamageReport showModal={showModal} />
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <ImportFile showModal={showModal} />
                    </TabPanel>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default NewDamageReportModal;
