import { AddCircleRounded } from '@mui/icons-material';
import { Button, CircularProgress, Grid, IconButton, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { getYear } from 'date-fns';
import { Form, Formik } from 'formik';
import React from 'react';
import { MODAL_TYPE } from '../../constants';
import { api } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import { useLayout } from '../../providers/LayoutContext';
import DamagedField from './DamagedField';
import { initialDamagedFieldValues, initialNewDamageReportValues } from './initialValues';
import { reportValidationSchema } from './reportValidationSchema';

const NewDamageReport = ({ showModal }) => {
    const { notifySuccess, notifyError } = useNotify();
    const { matchesLgUp } = useLayout();
    const handleClose = () => showModal(MODAL_TYPE.NONE);

    const removeField = (setFieldValue, blocks, idx) => {
        setFieldValue(
            'blocks',
            blocks.filter((_, i) => i !== idx)
        );
    };

    const onSubmit = async (values, { setSubmitting }) => {
        api.createDamage
            .createDamage({
                ...values,
                area: parseFloat(values.area, 10),
                blocks: values.blocks.map((block) => ({
                    ...block,
                    area: parseFloat(block.area),
                })),
                year: getYear(values.damageDate),
            })
            .then(() => {
                notifySuccess('Kárakta létrehozása sikeres');
                handleClose();
            })
            .catch((err) => {
                notifyError(err);
                setSubmitting(false);
            });
    };

    return (
        <Formik
            initialValues={initialNewDamageReportValues}
            onSubmit={onSubmit}
            validationSchema={reportValidationSchema}
        >
            {({
                submitForm,
                isSubmitting,
                touched,
                errors,
                getFieldProps,
                values,
                setFieldValue,
            }) => (
                <Form>
                    <Grid container spacing={2} sx={{ pb: matchesLgUp ? 4 : 2 }}>
                        <Grid item xs={12} lg={6}>
                            <TextField
                                margin="dense"
                                label="Kárszám"
                                fullWidth
                                required
                                {...getFieldProps('damageNumber')}
                                error={touched.damageNumber && !!errors.damageNumber}
                                helperText={
                                    touched.damageNumber && errors.damageNumber
                                        ? `${errors.damageNumber}`
                                        : null
                                }
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <TextField
                                margin="dense"
                                label="Kötvényszám"
                                required
                                fullWidth
                                {...getFieldProps('policyNumber')}
                                error={touched.policyNumber && !!errors.policyNumber}
                                helperText={
                                    touched.policyNumber && errors.policyNumber
                                        ? `${errors.policyNumber}`
                                        : null
                                }
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <DesktopDatePicker
                                label="Káresemény időpontja"
                                inputFormat="yyyy-MM-dd"
                                value={values.damageDate}
                                onChange={(newDate) => setFieldValue('damageDate', newDate)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        {...getFieldProps('damageDate')}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ pb: matchesLgUp ? 4 : 2 }}>
                        <Grid item xs={12} lg={6}>
                            <TextField
                                margin="dense"
                                label="Károsodott terület összesen (Ha)"
                                required
                                fullWidth
                                {...getFieldProps('area')}
                                error={touched.area && !!errors.area}
                                helperText={touched.area && errors.area ? `${errors.area}` : null}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <TextField
                                margin="dense"
                                label="Káresemény típusa (Vihar, Jégverés, stb.)"
                                required
                                fullWidth
                                {...getFieldProps('dangerType')}
                                error={touched.dangerType && !!errors.dangerType}
                                helperText={
                                    touched.dangerType && errors.dangerType
                                        ? `${errors.dangerType}`
                                        : null
                                }
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <TextField
                                margin="dense"
                                label="Kárszemle helyszíne"
                                required
                                fullWidth
                                {...getFieldProps('inspectionAddress')}
                                error={touched.inspectionAddress && !!errors.inspectionAddress}
                                helperText={
                                    touched.inspectionAddress && errors.inspectionAddress
                                        ? `${errors.inspectionAddress}`
                                        : null
                                }
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <TextField
                                margin="dense"
                                label="Kárszemle irányítószáma"
                                required
                                fullWidth
                                {...getFieldProps('inspectionAddressZip')}
                                error={
                                    touched.inspectionAddressZip && !!errors.inspectionAddressZip
                                }
                                helperText={
                                    touched.inspectionAddressZip && errors.inspectionAddressZip
                                        ? `${errors.inspectionAddressZip}`
                                        : null
                                }
                            />
                        </Grid>
                    </Grid>
                    {values.blocks.map((block, idx) => (
                        <DamagedField
                            // eslint-disable-next-line react/no-array-index-key
                            key={idx}
                            idx={idx}
                            touched={touched}
                            errors={errors}
                            removeDisabled={values.blocks.length < 2}
                            removeField={() => removeField(setFieldValue, values.blocks, idx)}
                            getFieldProps={getFieldProps}
                        />
                    ))}
                    <Grid item xs={12} textAlign="center">
                        <IconButton
                            color="primary"
                            onClick={() =>
                                setFieldValue('blocks', [
                                    ...values.blocks,
                                    {
                                        ...initialDamagedFieldValues,
                                    },
                                ])
                            }
                        >
                            <AddCircleRounded />
                        </IconButton>
                    </Grid>
                    <Grid item container xs={12} justifyContent="flex-end" alignItems="center">
                        <Button onClick={handleClose} disabled={isSubmitting} sx={{ mr: 1 }}>
                            Mégse
                        </Button>
                        <Button variant="contained" onClick={submitForm}>
                            {isSubmitting ? <CircularProgress /> : 'Mentés'}
                        </Button>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default NewDamageReport;
