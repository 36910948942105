import { AddRounded } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useMap } from 'es-map-widget';
import React from 'react';
import { DRAWING_STATES, OVERVIEW_STATES, TOOLTIP_TEXTS } from '../../../constants';
import { useAppControl } from '../../../providers/AppContext';

const PointAddButton = ({ disabled }) => {
    const map = useMap();
    const { setDrawingState, setOverviewState, samplingData } = useAppControl();
    const handlePointAdd = () => {
        setDrawingState(DRAWING_STATES.POINT);
        setOverviewState(OVERVIEW_STATES.HIDDEN);
        map.drawPoints(samplingData.points.filter((e) => e.geom));
    };

    return (
        <Tooltip title={TOOLTIP_TEXTS.addOrModify} placement="right">
            <span>
                <IconButton onClick={handlePointAdd} disabled={disabled}>
                    <AddRounded />
                </IconButton>
            </span>
        </Tooltip>
    );
};

export default PointAddButton;
