import { Alert, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { useMap } from 'es-map-widget';
import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DRAWING_STATES, MAP_LAYER_NAMES } from '../../../../constants';
import { api, useApi } from '../../../../hooks/useApi';
import { useAppControl } from '../../../../providers/AppContext';
import { useLayout } from '../../../../providers/LayoutContext';
import { useWmsDates } from '../../../mapcontroller/contexts/WmsDateProvider';
import DamagedFieldTable from './components/DamagedFieldTable';
import DamagedFieldTabs from './components/DamagedFieldTabs';
import DownloadableMaps from './components/DownloadableMaps';
import { useDrawContext } from '../../../../providers/DrawContextProvider';

const DamagedFieldBlock = () => {
    const map = useMap();
    const { setWmsData } = useWmsDates();
    const { drawingState, setSamplingData } = useAppControl();
    const { matchesLgUp, selectedProduct } = useLayout();
    const { setIsLoading, isGeneratingSamples, setHasAreaDrawn } = useDrawContext();
    const [searchParams] = useSearchParams();
    const [reloadFlag, setReloadFlag] = useState();
    const [isSaved, setIsSaved] = useState(false);

    const damagePlotId = searchParams.get('damagePlotId');

    const [result, loading, error] = useApi(
        (API, params) => API.damagePlots.getDamagePlot(damagePlotId, params),
        [damagePlotId, isSaved, reloadFlag, isGeneratingSamples]
    );

    useEffect(() => {
        setIsSaved(
            drawingState === DRAWING_STATES.POLYGON || drawingState === DRAWING_STATES.POLYGON_EDIT
        );
    }, [drawingState, setIsSaved]);

    const [areas, areasLoading, areasError] = useApi(
        (API, params) => {
            if (!selectedProduct?.code) {
                return Promise.resolve({ data: null });
            }
            return API.damagePlots.getDamagePlotAreas(selectedProduct.code, params);
        },
        [drawingState, reloadFlag, selectedProduct.code]
    );

    useEffect(() => {
        setHasAreaDrawn(areas?.length > 0 || false);
    }, [areas, setHasAreaDrawn]);

    useEffect(() => {
        if (areasLoading || loading) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [setIsLoading, loading, areasLoading]);

    const downloadSamplingFile = useCallback(() => {
        api.downloads
            .downloadSampling(damagePlotId)
            .then((res) => {
                const downloadLink = document.createElement('a');
                downloadLink.href = `data:text/xml;charset=utf-8,${encodeURIComponent(res.data)}`;
                downloadLink.download = `${result.damage.damageNumber}_${result.mepar.meparCode}_${result.id}.kml`;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            })
            .catch((err) => console.log(err));
    });

    useEffect(() => {
        if (!result) {
            return;
        }
        setWmsData((p) => ({
            ...p,
            changeMap: result.changeMap
                ? {
                      dateBefore: new Date(result.changeMap.date),
                      dateAfter: new Date(result.changeMap.dateBefore),
                  }
                : null,
            damageMap: result.damageMap
                ? {
                      dateBefore: result.damageMap.date,
                      dateAfter: result.damageMap.dateBefore,
                  }
                : null,
        }));

        map.setLayerData(MAP_LAYER_NAMES.ZONE, {
            geoms: result.samplingZones.map((e) => ({
                ...e,
                category: parseInt(e.category, 10),
            })),
        });
        map.setLayerData(MAP_LAYER_NAMES.SAMPLING, {
            geoms: result.samplings,
        });
        setSamplingData({
            points: result.samplings,
            zones: result.samplingZones,
        });
    }, [map, result, setWmsData, isGeneratingSamples]);

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        console.log(error);
        return <Alert severity="error">Hiba történt a lekérdezés során.</Alert>;
    }

    return (
        <Grid item container xs={12} spacing={2} direction={matchesLgUp ? 'row' : 'column-reverse'}>
            <Grid
                item
                xs={12}
                lg={6}
                container
                justifyContent="space-between"
                alignContent="flex-start"
            >
                <Grid item xs={12}>
                    <Typography variant="h5">Károsodott tábla</Typography>
                </Grid>
                <Grid item container xs={12} justifyContent="space-between" sx={{ mb: 2 }}>
                    <DamagedFieldTable result={result} areas={areas} />
                </Grid>
                <DownloadableMaps result={result} />
                <Grid item xs={12}>
                    <Button variant="contained" onClick={downloadSamplingFile}>
                        Mintavételi terv letöltése (KML)
                    </Button>
                </Grid>
            </Grid>
            <Grid container item xs={12} lg={6}>
                <Grid item xs={12} container justifyContent="space-between">
                    <DamagedFieldTabs
                        result={result}
                        areas={areas}
                        areasLoading={areasLoading}
                        areasError={areasError}
                        setReloadFlag={setReloadFlag}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DamagedFieldBlock;
