import { Alert, CircularProgress, Grid } from '@mui/material';
import { useMap } from 'es-map-widget';
import { groupBy, uniqBy } from 'ramda';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import LinkAccordion from '../components/LinkAccordion';
import { MAP_LAYER_NAMES, OVERVIEW_TYPES } from '../constants';
import { useApi } from '../hooks/useApi';
import { useAppControl } from '../providers/AppContext';
import { useLayout } from '../providers/LayoutContext';
import PolicyData from './components/policy/PolicyData';

const PolicyPage = () => {
    const { id } = useParams();
    const map = useMap();
    const { year } = useAppControl();
    const { matchesLgUp, setSelectedProduct } = useLayout();
    const [result, loading, error] = useApi(
        (api, params) => api.policies.getPolicy({ policyNumber: id, year }, params),
        [id, year]
    );
    const [nodes, setNodes] = useState([]);
    const [reportNodes, setReportNodes] = useState([]);
    const [searchParams] = useSearchParams();
    const damagePlotId = parseInt(searchParams.get('damagePlotId'), 10);

    const zoomToMepar = useCallback(
        (child) => {
            if (!child.geom) {
                return;
            }
            map.zoomToGeomsExtent(
                [
                    {
                        geom: child.geom,
                    },
                ],
                !matchesLgUp
                    ? { padding: [0, 0, 0, 0] }
                    : {
                          padding: [50, 100, 400, 100],
                      }
            );
        },
        [map]
    );

    useEffect(() => {
        if (!result) {
            return;
        }

        setReportNodes(
            result.damages.map((damage) => ({
                code: damage.damageNumber,
                name: damage.damageNumber,
                children: [],
            }))
        );

        const nodesFromResult = Object.entries(groupBy((e) => e.meparCode, result.policyPlots)).map(
            ([code, data]) => ({
                code,
                name: code,
                children: data.map((damagedField) => ({
                    code: damagedField.id,
                    name: `${damagedField.plantCode} - ${damagedField.insuredArea} ha`,
                    type: OVERVIEW_TYPES.FIELD_INSURED,
                    meparCode: damagedField.meparCode,
                    geom: damagedField.mepar?.geom,
                    policyNumber: result.policyNumber,
                    plantCode: damagedField.plantCode,
                    insuredArea: damagedField.insuredArea,
                })),
            })
        );
        setNodes(nodesFromResult);

        const policyGeoms = uniqBy(
            (x) => x.meparCode,
            nodesFromResult.flatMap((e) => e.children)
        )
            .filter((e) => e.geom)
            .map((damage) => ({
                geom: damage.geom,
                layerName: damage.meparCode,
            }));
        map.setLayerVisibility(MAP_LAYER_NAMES.MEPAR, true);
        map.setLayerData(MAP_LAYER_NAMES.MEPAR, {
            geoms: policyGeoms,
        });

        if (damagePlotId) {
            const data = result.policyPlots.find((e) => e.id === damagePlotId);
            if (!data) {
                return;
            }
            setSelectedProduct({
                type: OVERVIEW_TYPES.FIELD_INSURED,
                code: damagePlotId,
                meparCode: data.meparCode,
                policyNumber: result.policyNumber,
                insuredArea: data.insuredArea,
                plantCode: data.plantCode,
            });
            zoomToMepar({
                geom: data.mepar.geom,
            });
        } else if (policyGeoms.length > 0) {
            map.zoomToGeomsExtent(policyGeoms, !matchesLgUp ? { padding: [0, 0, 0, 0] } : {});
        }
    }, [map, result, damagePlotId, zoomToMepar]);

    if (error) {
        console.log(error);
        return (
            <Grid item xs={12}>
                <Alert severity="error">A kötvény az adott évben nem található</Alert>
            </Grid>
        );
    }
    if (loading) {
        return (
            <Grid item>
                <CircularProgress />
            </Grid>
        );
    }

    return (
        <Grid container item sx={{ height: '100%' }}>
            <Grid container item>
                <PolicyData result={result} />
            </Grid>
            <Grid container item>
                {
                    // TODO: itt majd a policyPlotId-t is át kell adni valahogy a linkekhez
                }
                <LinkAccordion
                    nodes={reportNodes}
                    linkTo={OVERVIEW_TYPES.REPORT}
                    title="Kötvényhez tartozó kárakták"
                />
                <LinkAccordion
                    nodes={nodes}
                    linkTo={OVERVIEW_TYPES.MEPAR}
                    title="Biztosított táblák"
                    cb={zoomToMepar}
                />
            </Grid>
        </Grid>
    );
};

export default PolicyPage;
