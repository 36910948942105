import { CloseRounded, InsertDriveFileRounded, UploadFileRounded } from '@mui/icons-material';
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    InputBase,
    InputLabel,
    Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import { BASE_COLORS, MODAL_TYPE } from '../../constants';
import { api } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';

const ImportFile = ({ showModal }) => {
    const { notifySuccess, notifyError } = useNotify();
    const handleClose = () => showModal(MODAL_TYPE.NONE);

    const onSubmit = (values, { setSubmitting }) => {
        return api.createDamageFromExcel
            .createDamageFromExcel({ excelFile: values.file })
            .then(() => {
                notifySuccess('Kárakta létrehozása sikeres');
                setSubmitting(false);
                handleClose();
            })
            .catch((err) => {
                notifyError(err);
                setSubmitting(false);
            });
    };

    const handleFileChange = (e, setFieldValue) => {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            setFieldValue('file', e.target.files[0]);
        }
    };

    return (
        <Formik onSubmit={onSubmit} initialValues={{ file: null }}>
            {({ submitForm, values, setFieldValue, isSubmitting }) => (
                <Form>
                    {!values.file ? (
                        <>
                            <InputBase
                                id="file-upload-damage-report"
                                type="file"
                                sx={{ display: 'none' }}
                                onChange={(event) => handleFileChange(event, setFieldValue)}
                                inputProps={{
                                    accept: '.xls,.xlsx,application/vnd.ms-excel',
                                }}
                            />
                            <InputLabel htmlFor="file-upload-damage-report">
                                <Box
                                    sx={{
                                        cursor: 'pointer',
                                        borderRadius: 1,
                                        border: `2px dashed ${BASE_COLORS.PRIMARY_MAIN}`,
                                        padding: 2,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <UploadFileRounded sx={{ mr: 1 }} /> Kattints a fájl
                                    feltötéséhez
                                </Box>
                            </InputLabel>
                        </>
                    ) : (
                        <Box
                            sx={{
                                p: 1,
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                            }}
                        >
                            <Typography variant="h6" sx={{ mr: 1 }}>
                                A kiválasztott fájl:
                            </Typography>
                            <Box
                                sx={{
                                    p: 1,
                                    background: BASE_COLORS.BACKGROUND_LIGHT_RED,
                                    borderRadius: 1,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <InsertDriveFileRounded sx={{ mr: 0.5 }} />
                                <span>{values.file.name}</span>
                                <IconButton
                                    onClick={() => setFieldValue('file', null)}
                                    size="small"
                                >
                                    <CloseRounded fontSize="small" />
                                </IconButton>
                            </Box>
                        </Box>
                    )}
                    <Grid
                        item
                        container
                        xs={12}
                        justifyContent="flex-end"
                        alignItems="center"
                        sx={{ mt: 2 }}
                    >
                        <Button onClick={handleClose} disabled={isSubmitting} sx={{ mr: 1 }}>
                            Mégse
                        </Button>
                        <Button variant="contained" onClick={submitForm} disabled={isSubmitting}>
                            {isSubmitting ? <CircularProgress /> : 'Mentés'}
                        </Button>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default ImportFile;
