import { ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';
import React, { createRef } from 'react';
import MapController from '../../components/mapcontroller/MapController';
import MapWrapper from '../../components/mapwrapper/MapWrapper';
import { BASE_COLORS } from '../../constants';

const MobileOverview = ({ open, handleClick }) => {
    // TODO: where is this used?
    const overview = createRef();

    return (
        <Grid
            container
            sx={{
                position: 'fixed',
                zIndex: 1080,
                height: 'calc(100% - 92px)',
                top: 56,
                left: 0,
            }}
            className="mobile-overview"
            alignContent="flex-start"
        >
            <Grid
                container
                item
                xs={12}
                sx={{
                    position: 'relative',
                    display: 'block',
                    visibility: open ? 'visible' : 'hidden',
                    zIndex: open ? 1080 : -1,
                    height: '100%',
                }}
            >
                <Grid item xs={12} sx={{ width: '100%', height: '52.5px' }}>
                    <MapController />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{ position: 'relative', height: 'calc(100% - 52.5px)' }}
                    alignItems="stretch"
                >
                    <MapWrapper overviewRef={overview} />
                </Grid>
            </Grid>
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{
                    width: '100%',
                    backgroundColor: '#F0F4F7',
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                    height: '36px',
                    '& button:last-child': {
                        marginRight: '10px',
                    },
                    boxShadow: `0px -2px 5px ${BASE_COLORS.BACKGROUND_LIGHT_GRAY}`,
                }}
                onClick={handleClick}
            >
                <Grid item sx={{ ml: 2, fontSize: '24px' }}>
                    Térkép
                </Grid>
                <Grid item>
                    <IconButton size="small">
                        {open ? <ExpandMoreRounded /> : <ExpandLessRounded />}
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default MobileOverview;
