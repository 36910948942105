import { Fill, Style, Text } from 'es-map-widget';
import { useCallback, useEffect } from 'react';
import { api } from '../../hooks/useApi';
import { styles } from '../mapwrapper/MapWrapperStyles';

const CityDataprovider = ({ extent, layerData, setSource }) => {
    const getLayer = useCallback(
        async (controller, params) =>
            api.layers.listCities(params, { signal: controller.signal }).then((res) => res.data),
        [api]
    );

    const addStyleToCities = useCallback(
        (regionsWithoutStyle) => {
            return regionsWithoutStyle.map((e) => {
                return {
                    ...e,
                    style: new Style({
                        ...styles.cities,
                        text: new Text({
                            font: '12px sans-serif',
                            fill: new Fill({ color: '#fff' }),
                            text: e.NUTS5NAME,
                        }),
                    }),
                };
            });
        },
        [layerData]
    );
    useEffect(() => {
        if (!layerData.isLayerVisible) {
            setSource(null);
            return;
        }

        const controller = new AbortController();

        getLayer(controller, {
            extent,
        })
            .then((layer) => setSource(addStyleToCities(layer)))
            .catch((err) => {
                if (err.code !== 'ERR_CANCELED') {
                    console.error(err);
                }
            });

        // eslint-disable-next-line consistent-return
        return () => {
            controller.abort();
        };
    }, [extent, layerData, setSource]);

    return null;
};

export default CityDataprovider;
