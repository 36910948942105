import React from 'react';
import DesktopLayout from './DesktopLayout';
import MobileLayout from '../mobile/layouts/MobileLayout';
import { useLayout } from '../providers/LayoutContext';

const PageLayout = ({ children }) => {
    const { matchesLgUp } = useLayout();
    return matchesLgUp ? (
        <DesktopLayout>{children}</DesktopLayout>
    ) : (
        <MobileLayout>{children}</MobileLayout>
    );
};

export default PageLayout;
