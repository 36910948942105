import { Style, Text } from 'es-map-widget';
import { useCallback, useEffect } from 'react';
import { api } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import { styles, textStyle } from '../mapwrapper/MapWrapperStyles';
import { useAppControl } from '../../providers/AppContext';

const FrostMeparDataprovider = ({ extent, layerData, setSource }) => {
    const { year } = useAppControl();
    const { notifyError } = useNotify();

    const getMepars = useCallback(
        async (controller, params) =>
            api.layers
                .listMeparsForFrost(params, { signal: controller.signal })
                .then((res) => res.data),
        [api]
    );

    const addStyleToMepars = (meparsWithoutStyle) =>
        meparsWithoutStyle.map((e) => ({
            ...e,
            style: new Style({
                ...styles.mepar,
                text: new Text({
                    ...textStyle,
                    text: `${e.meparCode}${e.frost ? `\n ${e.frost.toFixed(2)}` : ''}`,
                }),
            }),
        }));

    useEffect(() => {
        if (!layerData.isLayerVisible) {
            setSource(null);
            return;
        }

        const controller = new AbortController();
        getMepars(controller, { extent, year })
            .then((newMepars) => {
                setSource(addStyleToMepars(newMepars));
            })
            .catch((err) => {
                if (err.code !== 'ERR_CANCELED') {
                    notifyError(err);
                }
            });

        // eslint-disable-next-line consistent-return
        return () => {
            controller.abort();
        };
    }, [layerData, getMepars, extent, addStyleToMepars, setSource]);

    return null;
};

export default FrostMeparDataprovider;
