import { Box, useMediaQuery } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from '../components/header/Navbar';
import NavigationDrawer from '../pages/components/admin/NavigationDrawer';
import { useAdmin } from '../providers/AdminContext';
import { useLayout } from '../providers/LayoutContext';

const AdminLayout = () => {
    const matchesSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const { matchesLgUp } = useLayout();
    const { selectedMenuItem, setSelectedMenuItem } = useAdmin();

    return (
        <>
            <NavigationDrawer
                selectedMenuItem={selectedMenuItem}
                setSelectedMenuItem={setSelectedMenuItem}
            />
            <Navbar isOnDashboard />
            <Box
                bgcolor="#FAFBFD"
                sx={{
                    pt: matchesSm ? '56px' : '64px',
                    height: '100%',
                    marginLeft: matchesLgUp ? '240px' : '0px',
                    width: matchesLgUp ? 'calc(100vw - 240px)' : '100%',
                    overflowY: 'auto',
                }}
            >
                <Outlet />
            </Box>
        </>
    );
};

export default AdminLayout;
