import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import { IconButton, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { OVERVIEW_STATES, ROLES } from '../../constants';
import { useAppControl } from '../../providers/AppContext';
import { useAuth } from '../../providers/AuthProvider';
import { useLayout } from '../../providers/LayoutContext';
import { useMapFunctions } from '../../providers/MapFunctionsProvider';

const UserMenu = () => {
    const navigate = useNavigate();
    const { user, setUser } = useAuth();
    const { setOverviewState, setIsSearchVisible } = useAppControl();
    const { setSelectedProduct } = useLayout();
    const { resetMap } = useMapFunctions();

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logoutUser = () => {
        setUser(null);
        setOverviewState(OVERVIEW_STATES.CLOSED);
        setSelectedProduct(null);
        setIsSearchVisible(false);
        resetMap();
    };

    return (
        <>
            <IconButton
                size="large"
                edge="start"
                color="inherit"
                onClick={(event) => setAnchorEl(event.currentTarget)}
            >
                <PersonOutlineRoundedIcon sx={{ color: 'white !important' }} />
            </IconButton>

            <Menu anchorEl={anchorEl} open={open} onClose={() => handleClose()}>
                <MenuItem
                    onClick={() => {
                        navigate('/profile');
                        handleClose();
                    }}
                >
                    Profilom
                </MenuItem>
                {[ROLES.ADMIN, ROLES.MANAGER].includes(user.role) && (
                    <MenuItem
                        onClick={() => {
                            navigate('/dashboard');
                            handleClose();
                        }}
                    >
                        Vezetői dashboard
                    </MenuItem>
                )}
                <MenuItem
                    onClick={() => {
                        logoutUser();
                        navigate('/login');
                        handleClose();
                    }}
                >
                    Kijelentkezés
                </MenuItem>
            </Menu>
        </>
    );
};

export default UserMenu;
