import { Grid, Typography } from '@mui/material';
import { useMap } from 'es-map-widget';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import HomeButton from '../../../components/atom/HomeButton';
import ShowOverviewButton from '../../../components/atom/ShowOverviewButton';
import { MAP_LAYER_NAMES, OVERVIEW_STATES, OVERVIEW_TYPES } from '../../../constants';
import { useAppControl } from '../../../providers/AppContext';
import { useLayout } from '../../../providers/LayoutContext';

const openStates = [OVERVIEW_STATES.OPEN_FULLSCREEN, OVERVIEW_STATES.OPEN];

const PolicyData = ({ result }) => {
    const map = useMap();
    const { selectedProduct, setSelectedProduct } = useLayout();
    const { overviewState, setOverviewState } = useAppControl();
    const [_, setSearchParams] = useSearchParams();

    const handleClick = () => {
        map.setLayerVisibility(MAP_LAYER_NAMES.FIELDS, false);
        if (openStates.includes(overviewState) && selectedProduct?.type === OVERVIEW_TYPES.POLICY) {
            setOverviewState(OVERVIEW_STATES.CLOSED);
            setSelectedProduct(null);
            return;
        }

        setSearchParams({});
        setOverviewState(OVERVIEW_STATES.OPEN);
        setSelectedProduct({ ...result, type: OVERVIEW_TYPES.POLICY });
    };

    return (
        <Grid container item xs={12} direction="column" gap={1} sx={{ mb: 1 }}>
            <Grid item container justifyContent="space-between" alignItems="center">
                <Grid item xs={12} sx={{ mb: 1 }}>
                    <Typography variant="h4">Kötvény</Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    container
                    justifyContent="space-between"
                    alignItems="centre"
                    sx={{ mb: 1 }}
                >
                    <Grid item>Kötvényszám:</Grid>
                    <Grid item>{result.policyNumber}</Grid>
                </Grid>
                <Grid item xs={12} container justifyContent="flex-end">
                    <HomeButton />
                </Grid>
            </Grid>
            <Grid item textAlign="right" sx={{ mt: 2 }}>
                <ShowOverviewButton onClick={handleClick} title="Kötvény adatai" />
            </Grid>
        </Grid>
    );
};

export default PolicyData;
