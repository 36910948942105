/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { useMap } from 'es-map-widget';
import { ChevronRightRounded } from '@mui/icons-material';
import { Checkbox, Grid } from '@mui/material';
import { useAuth } from '../../../providers/AuthProvider';
import { hasPermission } from '../../../constants';
import { useMenu } from '../contexts/MenuContext';

const MapControllerMenuItem = ({
    text,
    onClick,
    isActive = false,
    checkbox = undefined,
    hasSubMenu = false,
    mapLayer = null,
    controllerItem = null,
    controllerKey = null,
    permissions = [],
}) => {
    const { checkedItems, handleItemCheck } = useMenu();
    const { user } = useAuth();
    const map = useMap();

    // remove menu item from selected items to handle legacy localStorage states
    useEffect(() => {
        if (hasPermission(user, permissions)) {
            return;
        }

        if (checkedItems[controllerKey] && checkedItems[controllerKey].includes(controllerItem)) {
            handleItemCheck(controllerItem, controllerKey);
        }

        map.setLayerData(mapLayer, {
            isLayerVisible: false,
        });
        map.setLayerVisibility(mapLayer, false);
    }, [map, checkedItems, controllerItem, controllerKey, user, permissions]);

    // don't show the menu item if the user doesn't have permission
    if (!hasPermission(user, permissions)) {
        return null;
    }

    return (
        <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className="map-controller-submenu-item"
        >
            <Grid item>
                {typeof checkbox !== 'undefined' && Object.keys(checkbox).length > 0 && (
                    <Checkbox
                        size="small"
                        className="map-controller-checkbox"
                        sx={{ pl: 0 }}
                        {...(checkbox ?? {})}
                    />
                )}
                <span
                    style={{
                        cursor: hasSubMenu ? 'pointer' : 'auto',
                        color: 'white',
                        textDecoration: isActive ? 'underline' : 'none',
                    }}
                    onClick={onClick}
                >
                    {text}
                </span>
            </Grid>
            <Grid item>{hasSubMenu && <ChevronRightRounded fontSize="small" />}</Grid>
        </Grid>
    );
};

export default MapControllerMenuItem;
