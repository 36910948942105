const TOOLTIP_TEXTS = {
    legend: 'Jelmagyarázat',
    newDamageReport: 'Új kárakta',
    damagePlot: 'Károsodott terület rajzolás',
    sampling: 'Mintavételezés',
    addOrModify: 'Hozzáadás/módosítás',
    addYield: 'Hozamszámítási adatok módosítása',
    delete: 'Törlés',
    cancel: 'Mégse',
    undo: 'Visszavonás',
    save: 'Mentés',
    saveMepar: 'Mepar teljes területének kijelölése károsodottként',
    generateZones: 'Zónakijelölés indítása',
};

// eslint-disable-next-line import/prefer-default-export
export { TOOLTIP_TEXTS };
