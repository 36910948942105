import { Menu } from '@mui/icons-material';
import Search from '@mui/icons-material/Search';
import { Box, IconButton, Toolbar } from '@mui/material';
import React from 'react';
import { ReactComponent as GeneraliSvg } from '../../assets/generali.svg';
import { BASE_COLORS, DRAWER_STATES } from '../../constants';
import { useAppControl } from '../../providers/AppContext';
import SearchForm from './SearchForm';
import UserMenu from './UserMenu';
import YearSelector from './YearSelector';

const NavbarMobile = ({ handleClick, user, isOnDashboard }) => {
    const { isSearchVisible, setIsSearchVisible, setAdminDrawerState } = useAppControl();

    const handleSearchClick = () => {
        setIsSearchVisible(!isSearchVisible);
    };

    const handleMenuClick = () => {
        setAdminDrawerState((p) =>
            p === DRAWER_STATES.OPEN ? DRAWER_STATES.CLOSED : DRAWER_STATES.OPEN
        );
    };

    return (
        <>
            <Toolbar>
                {isOnDashboard && (
                    <IconButton onClick={handleMenuClick} sx={{ mr: 1 }}>
                        <Menu sx={{ color: 'white !important' }} />
                    </IconButton>
                )}
                <Box sx={{ cursor: 'pointer' }} onClick={() => handleClick()}>
                    <GeneraliSvg className="generali-logo" />
                </Box>
                <Box sx={{ flexGrow: 1 }} />
                {user && (
                    <>
                        <YearSelector />
                        <Box sx={{ flexGrow: 1 }} />
                        <IconButton onClick={handleSearchClick}>
                            <Search
                                sx={{
                                    color: `${
                                        isSearchVisible ? `${BASE_COLORS.SECONDARY_MAIN}` : 'white'
                                    } !important`,
                                    mr: 1,
                                }}
                            />
                        </IconButton>
                        <UserMenu />
                    </>
                )}
            </Toolbar>
            {isSearchVisible && (
                <Toolbar>
                    <SearchForm setIsVisible={() => setIsSearchVisible(false)} />
                </Toolbar>
            )}
        </>
    );
};

export default NavbarMobile;
