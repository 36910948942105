import { Alert, CircularProgress, Grid } from '@mui/material';
import { useMap } from 'es-map-widget';
import { groupBy } from 'ramda';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import LinkAccordion from '../components/LinkAccordion';
import { MAP_LAYER_NAMES, OVERVIEW_TYPES } from '../constants';
import { useApi } from '../hooks/useApi';
import { useAppControl } from '../providers/AppContext';
import { useLayout } from '../providers/LayoutContext';
import { formatNumberToDecimal } from '../utils/formatter';
import DamageReportData from './components/damageReport/DamageReportData';

const DamageReportPage = () => {
    const { id } = useParams();
    const map = useMap();
    const { matchesLgUp, setSelectedProduct } = useLayout();
    const { drawingState, year } = useAppControl();

    const [nodes, setNodes] = useState([]);
    const [result, loading, error] = useApi(
        (api, params) => api.damages.getDamage({ damageNumber: id, year }, params),
        [id, year]
    );
    const [searchParams] = useSearchParams();
    const damagePlotId = searchParams.get('damagePlotId');

    const zoomToMepar = useCallback(
        (child) => {
            if (!child.mepar?.polygon) {
                return;
            }
            map.zoomToGeomsExtent(
                [
                    {
                        geom: child.mepar.polygon,
                    },
                ],
                !matchesLgUp ? { padding: [0, 0, 0, 0] } : {}
            );
        },
        [map]
    );

    useEffect(() => {
        if (damagePlotId) {
            setSelectedProduct({ type: OVERVIEW_TYPES.FIELD_DAMAGED });
        }
        if (!result) {
            return;
        }

        const nodesFromResult = Object.entries(groupBy((e) => e.meparCode, result.damagePlots)).map(
            ([code, data]) => ({
                code,
                name: `${code} (${data.length} károsodott tábla)`,
                children: data.map((damagedField) => ({
                    code: damagedField.id,
                    name: `${damagedField.plantCode} - ${formatNumberToDecimal(
                        damagedField.damagedArea,
                        'ha'
                    )}`,
                    type: OVERVIEW_TYPES.FIELD_DAMAGED,
                    mepar: {
                        meparCode: damagedField.mepar?.meparCode ?? null,
                        area: damagedField.mepar?.area ?? null,
                        polygon: damagedField.mepar?.geom ?? null,
                    },
                    damage: {
                        damageNumber: result.damageNumber ?? null,
                        damageDate: result.damageDate ?? null,
                        dangerType: result.dangerType ?? null,
                    },
                    plantCode: damagedField.plantCode,
                    damagedArea: damagedField.damagedArea,
                    tableGeometry: damagedField.tableGeometry,
                    tableGeometryHa: damagedField.tableGeometryHa,
                })),
            })
        );
        setNodes(nodesFromResult);

        if (damagePlotId !== null) {
            const selected = nodesFromResult
                .flatMap((e) => e.children)
                .find((e) => e.code === parseInt(damagePlotId, 10));
            if (selected) {
                setSelectedProduct(selected);
                zoomToMepar(selected);
            }
        }

        const damagePlots = result.damagePlots.filter((e) => e.mepar?.geom);
        map.setLayerVisibility(MAP_LAYER_NAMES.MEPAR, true);
        map.setLayerData(MAP_LAYER_NAMES.MEPAR, {
            geoms: damagePlots.map((damage) => ({
                geom: damage.mepar.geom,
                layerName: damage.meparCode,
            })),
        });

        if (damagePlotId === null && !drawingState) {
            if (damagePlots.length === 0) {
                return;
            }

            map.zoomToGeomsExtent(
                damagePlots.map((damage) => ({
                    geom: damage.mepar.geom,
                })),
                !matchesLgUp ? { padding: [0, 0, 0, 0] } : {}
            );
        }
    }, [map, result, drawingState, damagePlotId, setSelectedProduct]);

    useEffect(() => {
        if (!result || !result.damagePhotos) {
            return;
        }

        map.addPopoverToLayer(MAP_LAYER_NAMES.KARNAVI);
        map.setLayerVisibility(MAP_LAYER_NAMES.KARNAVI, result.damagePhotos.length > 0);
        map.setLayerData(MAP_LAYER_NAMES.KARNAVI, {
            geoms: result.damagePhotos.map((damagePhoto, i) => ({
                ...damagePhoto,
                serialNumber: i + 1,
                damageNumber: result.damageNumber,
            })),
        });
    }, [result, id, map]);

    if (error) {
        return (
            <Grid item xs={12}>
                <Alert severity="error">Hiba történt a kérés teljesítése során</Alert>
            </Grid>
        );
    }
    if (loading) {
        return (
            <Grid item>
                <CircularProgress />
            </Grid>
        );
    }
    return (
        <>
            <DamageReportData result={result} />
            <LinkAccordion nodes={nodes} linkTo={OVERVIEW_TYPES.MEPAR} cb={zoomToMepar} />
        </>
    );
};

export default DamageReportPage;
