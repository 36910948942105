import { Grid } from '@mui/material';
import React, { createRef } from 'react';
import MapController from './mapcontroller/MapController';
import MapWrapper from './mapwrapper/MapWrapper';
import DataOverview from './overview/DataOverview';

const ContentArea = () => {
    const overview = createRef();

    return (
        <Grid container item xs={12} sx={{ position: 'relative' }} spacing={2}>
            <Grid item sx={{ width: '100%', height: '52.5px' }}>
                <MapController />
            </Grid>
            <Grid
                item
                sx={{ position: 'relative', height: 'calc(100% - 52.5px)', width: '100%' }}
                alignItems="stretch"
            >
                <MapWrapper overviewRef={overview} />
            </Grid>
            <DataOverview ref={overview} />
        </Grid>
    );
};

export default ContentArea;
