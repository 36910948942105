import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { format, parseISO } from 'date-fns';
import React, { useEffect } from 'react';
import { formatNumberToDecimal } from '../../../../../utils/formatter';
import { useWmsDates } from '../../../../mapcontroller/contexts/WmsDateProvider';

const DamagedFieldTable = ({ result, areas }) => {
    const { setWmsData } = useWmsDates();

    useEffect(() => {
        if (!result) {
            return;
        }

        setWmsData((pV) => ({
            ...pV,
            meparCode: result.mepar?.meparCode,
            damageId: result.damage?.id,
            damagePlotId: result.id,
            dateBefore: result.changeMap?.dateBefore
                ? new Date(result.changeMap?.dateBefore)
                : null,
            dateAfter: result.changeMap?.date ? new Date(result.changeMap?.date) : null,
        }));
    }, [result, setWmsData]);

    if (!result) {
        return null;
    }

    return (
        <Table>
            <TableBody>
                <TableRow>
                    <TableCell>MePAR kód:</TableCell>
                    <TableCell>
                        {result.mepar?.meparCode || 'Nem található kapcsolódó MePAR'}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Kárszám:</TableCell>
                    <TableCell>
                        {result.damage?.damageNumber || 'Nem található kapcsolódó kárakta'}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Kár dátuma:</TableCell>
                    <TableCell>
                        {result.damage?.damageDate
                            ? format(parseISO(result.damage.damageDate), 'yyyy-MM-dd')
                            : ''}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Kártípus:</TableCell>
                    <TableCell>{result.damage?.dangerType}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Növény:</TableCell>
                    <TableCell>{result.plantCode}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>MePAR terület:</TableCell>
                    <TableCell>{formatNumberToDecimal(result.mepar?.area, 'ha')}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Berajzolt terület:</TableCell>
                    <TableCell>
                        {areas && areas.length > 0 ? (
                            formatNumberToDecimal(result.tableGeometryHa, 'ha')
                        ) : (
                            <span style={{ color: 'red' }}>Rajzolás szükséges!</span>
                        )}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Kár. terület:</TableCell>
                    <TableCell>{formatNumberToDecimal(result.damagedArea, 'ha')}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};

export default DamagedFieldTable;
