/* eslint-disable camelcase */
import { Alert, CircularProgress, Grid, TableCell, TableRow } from '@mui/material';
import React from 'react';
import { useApi } from '../../../../hooks/useApi';
import { useAppControl } from '../../../../providers/AppContext';
import { formatNumberToDecimal } from '../../../../utils/formatter';
import DashboardTable from '../DashboardTable';

const ExpertActivity = () => {
    const { year } = useAppControl();
    const [result, loading, error] = useApi(
        (api, objWithSignal) => api.dashboards.getExpertActivityDashboard({ year }, objWithSignal),
        [year]
    );

    if (loading) {
        return (
            <Grid container justifyContent="center" alignContent="flex-start">
                <CircularProgress />
            </Grid>
        );
    }

    if (error) {
        return (
            <Grid container>
                <Alert severity="error">Hiba a kérés teljesítése során</Alert>
            </Grid>
        );
    }

    return (
        <Grid container item spacing={2}>
            <DashboardTable
                title="GeoRisk felületen történt aktivitás mértéke legutóbbi héten, legutóbbi hónapban és az elmúlt egy évben összesen (db)"
                gridProps={{ xs: 12, lg: 6 }}
                headerRows={['Kárszakértő', 'Legutóbbi hét', 'Legutóbbi hónap', 'Legutóbbi év']}
                loading={loading}
                error={error}
            >
                {result?.activities &&
                    result?.activities.map(({ user, last_week, last_month, last_year }) => (
                        <TableRow key={`${user}-${last_week}`}>
                            <TableCell>{user}</TableCell>
                            <TableCell align="right">
                                {formatNumberToDecimal(last_week, 'db', 0)}
                            </TableCell>
                            <TableCell align="right">
                                {formatNumberToDecimal(last_month, 'db', 0)}
                            </TableCell>
                            <TableCell align="right">
                                {formatNumberToDecimal(last_year, 'db', 0)}
                            </TableCell>
                        </TableRow>
                    ))}
            </DashboardTable>
            <DashboardTable
                title="Kárszakértők által megtekintett károk száma (db)"
                gridProps={{ xs: 12, lg: 6 }}
                headerRows={['Kárszakértő', 'Db']}
                loading={loading}
                error={error}
            >
                {result?.damages &&
                    result?.damages.map(({ user, damageViewed }) => (
                        <TableRow key={`${user}-${damageViewed}`}>
                            <TableCell>{user}</TableCell>
                            <TableCell align="right">
                                {formatNumberToDecimal(damageViewed, 'db', 0)}
                            </TableCell>
                        </TableRow>
                    ))}
            </DashboardTable>
        </Grid>
    );
};

export default ExpertActivity;
