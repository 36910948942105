import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { api } from '../../../../../hooks/useApi';
import useNotify from '../../../../../hooks/useNotify';
import { useAppControl } from '../../../../../providers/AppContext';

const YieldCalculation = ({ calculatedYield }) => {
    const { notifyError } = useNotify();
    const { samplingData } = useAppControl();
    const [yields, setYields] = useState(calculatedYield);
    const [loading, setLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const damagePlotId = searchParams.get('damagePlotId');

    const calculateYield = useCallback(() => {
        setLoading(true);
        api.damagePlots
            .calculateYield(parseInt(damagePlotId, 10))
            .then((res) => {
                setYields(res.data.calculatedYield);
                setLoading(false);
            })
            .catch((err) => {
                notifyError(err?.response?.data || { message: 'Hiba történt' });
                console.log(err);
                setLoading(false);
            });
    }, [api, damagePlotId]);

    return (
        <Grid container sx={{ p: 1 }}>
            {yields ? (
                <Grid item xs={12}>
                    <Typography variant="body">Számított hozam: {yields}</Typography>
                </Grid>
            ) : (
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={calculateYield}
                        disabled={samplingData.points.length === 0}
                    >
                        {loading ? (
                            <CircularProgress color="secondary" size={25} />
                        ) : (
                            'Hozamszámítás'
                        )}
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};

export default YieldCalculation;
