import { DescriptionRounded, EventNoteRounded, PeopleRounded } from '@mui/icons-material';
import {
    Box,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Toolbar,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DRAWER_STATES } from '../../../constants';
import { ADMIN_MENU_KEYS, ADMIN_MENU_LABELS } from '../../../constants/adminMenuKeysAndLabels';
import { useAppControl } from '../../../providers/AppContext';
import { useLayout } from '../../../providers/LayoutContext';

const drawerWidth = 240;

const NavigationDrawer = ({ selectedMenuItem, setSelectedMenuItem }) => {
    const navigate = useNavigate();
    const { matchesLgUp } = useLayout();
    const { adminDrawerState, setAdminDrawerState } = useAppControl();
    const [mobileProps, setMobileProps] = useState({
        anchor: 'left',
        open: adminDrawerState,
        variant: 'temporary',
        onClose: () => setAdminDrawerState(false),
    });

    useEffect(() => {
        if (!matchesLgUp) {
            setMobileProps((pV) => ({ ...pV, open: adminDrawerState }));
            return;
        }
        setMobileProps({});
    }, [matchesLgUp, adminDrawerState, setMobileProps]);

    const handleMenuItemMobileClick = useCallback(() => {
        if (!matchesLgUp) {
            setAdminDrawerState(DRAWER_STATES.CLOSED);
        }
    }, [matchesLgUp, setAdminDrawerState]);

    return (
        <Drawer
            variant="permanent"
            {...mobileProps}
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
            }}
        >
            {matchesLgUp && <Toolbar />}
            <Box sx={{ overflow: 'auto' }}>
                <List sx={{ pt: matchesLgUp ? 0 : 1, pb: 0 }}>
                    <ListItem disablePadding>
                        <ListItemButton
                            selected={ADMIN_MENU_KEYS.POLICIES === selectedMenuItem}
                            onClick={() => {
                                handleMenuItemMobileClick();
                                setSelectedMenuItem(ADMIN_MENU_KEYS.POLICIES);
                                navigate('/dashboard/policies');
                            }}
                        >
                            <ListItemIcon>
                                <PeopleRounded />
                            </ListItemIcon>
                            <ListItemText>{ADMIN_MENU_LABELS.POLICIES}</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton
                            selected={ADMIN_MENU_KEYS.DAMAGE_REPORTS === selectedMenuItem}
                            onClick={() => {
                                handleMenuItemMobileClick();
                                setSelectedMenuItem(ADMIN_MENU_KEYS.DAMAGE_REPORTS);
                                navigate('/dashboard/damagereports');
                            }}
                        >
                            <ListItemIcon>
                                <EventNoteRounded />
                            </ListItemIcon>
                            <ListItemText>{ADMIN_MENU_LABELS.DAMAGE_REPORTS}</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton
                            selected={ADMIN_MENU_KEYS.DAMAGE_EXPERTS === selectedMenuItem}
                            onClick={() => {
                                handleMenuItemMobileClick();
                                setSelectedMenuItem(ADMIN_MENU_KEYS.DAMAGE_EXPERTS);
                                navigate('/dashboard/damageexperts');
                            }}
                        >
                            <ListItemIcon>
                                <DescriptionRounded />
                            </ListItemIcon>
                            <ListItemText>{ADMIN_MENU_LABELS.DAMAGE_EXPERTS}</ListItemText>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
        </Drawer>
    );
};

export default NavigationDrawer;
