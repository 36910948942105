import React from 'react';

const SubMenuWrapper = ({ children, isVisible }) => {
    return (
        <div
            style={{
                display: isVisible ? 'block' : 'none',
            }}
        >
            {children}
        </div>
    );
};

export default SubMenuWrapper;
