import { Grid } from '@mui/material';
import { useMap } from 'es-map-widget';
import React, { useState } from 'react';
import { CONTROLLER_KEYS, CONTROLLER_LABELS } from '../../../constants';
import { MAP_LAYER_NAMES } from '../../../constants/mapLayerNames';
import { useLayout } from '../../../providers/LayoutContext';
import { useMenu } from '../contexts/MenuContext';
import MapControllerMenuItem from '../menu/MapControllerMenuItem';

const KarnaviController = () => {
    const map = useMap();
    const { checkedItems, handleItemCheck } = useMenu();
    const { matchesLgUp } = useLayout();
    const [isLayerVisible, setIsLayerVisible] = useState(
        checkedItems[CONTROLLER_KEYS.KARNAVI_PHOTOS].includes(CONTROLLER_KEYS.KARNAVI_PHOTOS)
    );

    return (
        <Grid container>
            <Grid item xs={12} lg={6} className={!matchesLgUp ? 'mobile' : ''}>
                <MapControllerMenuItem
                    text={CONTROLLER_LABELS.KARNAVI_PHOTOS}
                    checkbox={{
                        checked: isLayerVisible,
                        onChange: (event) => {
                            setIsLayerVisible((p) => !p);
                            if (!event.target.checked) {
                                map.setLayerData(MAP_LAYER_NAMES.KARNAVI, {
                                    isLayerVisible: false,
                                });
                                map.setLayerVisibility(MAP_LAYER_NAMES.KARNAVI, false);
                            } else {
                                map.setLayerData(MAP_LAYER_NAMES.KARNAVI, {
                                    isLayerVisible: true,
                                });
                                map.setLayerVisibility(MAP_LAYER_NAMES.KARNAVI, true);
                            }
                            handleItemCheck(
                                CONTROLLER_KEYS.KARNAVI_PHOTOS,
                                CONTROLLER_KEYS.KARNAVI_PHOTOS
                            );
                        },
                    }}
                    hasSubMenu={false}
                />
            </Grid>
        </Grid>
    );
};

export default KarnaviController;
