import { RemoveCircleRounded } from '@mui/icons-material';
import { Grid, IconButton, TextField, Typography } from '@mui/material';
import React from 'react';

const DamagedField = ({ removeField, idx, removeDisabled, touched, errors, getFieldProps }) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={8} lg={10}>
                <Typography variant="h5">Károsodott tábla #{idx + 1}</Typography>
            </Grid>
            <Grid item xs={4} lg={2} textAlign="right">
                <IconButton onClick={removeField} disabled={removeDisabled}>
                    <RemoveCircleRounded />
                </IconButton>
            </Grid>
            <Grid item xs={12} lg={4}>
                <TextField
                    margin="dense"
                    label="MePAR kód"
                    required
                    fullWidth
                    {...getFieldProps(`blocks[${idx}].mepar`)}
                    error={
                        touched.blocks &&
                        !!errors.blocks &&
                        touched.blocks[idx]?.mepar &&
                        !!errors.blocks[idx]?.mepar
                    }
                    helperText={
                        touched.blocks &&
                        errors.blocks &&
                        touched.blocks[idx]?.mepar &&
                        errors.blocks[idx]?.mepar
                            ? errors.blocks[idx].mepar
                            : null
                    }
                />
            </Grid>
            <Grid item xs={12} lg={4}>
                <TextField
                    margin="dense"
                    label="Károsodott terület (Ha)"
                    required
                    fullWidth
                    {...getFieldProps(`blocks[${idx}].area`)}
                    error={
                        touched.blocks &&
                        !!errors.blocks &&
                        touched.blocks[idx]?.area &&
                        !!errors.blocks[idx]?.area
                    }
                    helperText={
                        touched.blocks &&
                        errors.blocks &&
                        touched.blocks[idx]?.area &&
                        errors.blocks[idx]?.area
                            ? errors.blocks[idx].area
                            : null
                    }
                />
            </Grid>
            <Grid item xs={12} lg={4}>
                <TextField
                    margin="dense"
                    label="Növény"
                    required
                    fullWidth
                    {...getFieldProps(`blocks[${idx}].plants`)}
                    error={
                        touched.blocks &&
                        !!errors.blocks &&
                        touched.blocks[idx]?.plants &&
                        !!errors.blocks[idx]?.plants
                    }
                    helperText={
                        touched.blocks &&
                        errors.blocks &&
                        touched.blocks[idx]?.plants &&
                        errors.blocks[idx]?.plants
                            ? errors.blocks[idx].plants
                            : null
                    }
                />
            </Grid>
        </Grid>
    );
};

export default DamagedField;
