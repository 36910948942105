import { DeleteRounded, EditRounded } from '@mui/icons-material';
import { Alert, CircularProgress, Grid, IconButton } from '@mui/material';
import { Feature, toPolygon, useMap } from 'es-map-widget';
import React, { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DRAWING_STATES, OVERVIEW_STATES } from '../../../../../constants';
import { api } from '../../../../../hooks/useApi';
import { useAppControl } from '../../../../../providers/AppContext';
import { useLayout } from '../../../../../providers/LayoutContext';
import { formatNumberToDecimal } from '../../../../../utils/formatter';

const DamagePlotAreas = ({ loading, error, data, setReloadFlag }) => {
    const map = useMap();
    const { selectedProduct } = useLayout();
    const { drawingState, setDrawingState, setOverviewState } = useAppControl();
    const [searchParams, setSearchParams] = useSearchParams();

    const deleteDamagePlot = useCallback(
        async ({ id, plotId }) =>
            api.damagePlots.removeDamagePlotArea(id, plotId).then((res) => res.data),
        [api]
    );

    if (loading) {
        return (
            <Grid item xs={12} sx={{ display: 'flex' }} justifyContent="center">
                <CircularProgress />
            </Grid>
        );
    }

    if (error) {
        return <Alert severity="error">Hiba a táblák lekérdezése során</Alert>;
    }

    return (
        <>
            <Grid item xs={12} sx={{ mb: 2, fontWeight: 600 }}>
                Károsodott területek:
            </Grid>
            {!data || data.length === 0 ? (
                <Grid item xs={12}>
                    <Alert severity="error">Rajzolás szükséges</Alert>
                </Grid>
            ) : (
                data.map((damage) => (
                    <Grid key={damage.id} item container justifyContent="space-between">
                        <Grid item>
                            <span
                                style={{
                                    display: 'inline-block',
                                    width: '15px',
                                    height: '15px',
                                    borderRadius: '4px',
                                    border: '0.5px solid black',
                                    backgroundColor: damage.color,
                                    marginRight: '10px',
                                }}
                            />
                            Terület - {formatNumberToDecimal(damage.area, 'ha')}
                        </Grid>
                        <Grid item>
                            <IconButton
                                onClick={() => {
                                    setDrawingState(DRAWING_STATES.POLYGON);
                                    setOverviewState(OVERVIEW_STATES.HIDDEN);
                                    // TODO: handle direct navigation
                                    setSearchParams({
                                        damagePlotId: searchParams.get('damagePlotId'),
                                        plotId: damage.id,
                                    });
                                    // TODO: move this conversion to es-map-widget
                                    map.drawEdit(new Feature(toPolygon({ geom: damage.geom })));
                                }}
                                disabled={drawingState !== DRAWING_STATES.NONE}
                            >
                                <EditRounded />
                            </IconButton>
                            <IconButton
                                onClick={() =>
                                    deleteDamagePlot({
                                        id: selectedProduct.code,
                                        plotId: damage.id,
                                    }).then(() => setReloadFlag((p) => !p))
                                }
                                disabled={drawingState !== DRAWING_STATES.NONE}
                            >
                                <DeleteRounded />
                            </IconButton>
                        </Grid>
                    </Grid>
                ))
            )}
        </>
    );
};

export default DamagePlotAreas;
