import { Alert, CircularProgress, Grid } from '@mui/material';
import { useMap } from 'es-map-widget';
import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import LinkAccordion from '../components/LinkAccordion';
import { useWmsDates } from '../components/mapcontroller/contexts/WmsDateProvider';
import { MAP_LAYER_NAMES, OVERVIEW_STATES, OVERVIEW_TYPES, ROLE_GROUPS } from '../constants';
import { useApi } from '../hooks/useApi';
import { useAppControl } from '../providers/AppContext';
import { useLayout } from '../providers/LayoutContext';
import { formatNumberToDecimal } from '../utils/formatter';
import MeparData from './components/mepar/MeparData';
import { useAuth } from '../providers/AuthProvider';

const MeparPage = () => {
    const map = useMap();
    const { id } = useParams();
    const { setOverviewState, year } = useAppControl();
    const { matchesLgUp, setSelectedProduct } = useLayout();
    const { setWmsData } = useWmsDates();
    const { user } = useAuth();

    const [result, loading, error] = useApi(
        (api, params) => api.blocks.getBlock({ meparCode: id, year }, params),
        [id, year]
    );
    const [nodes, setNodes] = useState([]);
    const [damageNodes, setDamageNodes] = useState([]);
    const [searchParams] = useSearchParams();
    const damagePlotId = searchParams.get('damagePlotId');
    const policyPlotId = searchParams.get('policyPlotId');

    useEffect(() => {
        if (!result) {
            setOverviewState(OVERVIEW_STATES.CLOSED);
            return;
        }

        if (!ROLE_GROUPS.FROST_PRODUCTS.includes(user.role)) {
            delete result.frost;
        }

        if (policyPlotId) {
            let policyPlot;
            const selectedPolicy = result.policies.find((policy) =>
                policy.policyPlots.find((plot) => {
                    if (plot.id === parseInt(policyPlotId, 10)) {
                        policyPlot = plot;
                        return true;
                    }
                    return false;
                })
            );

            if (selectedPolicy) {
                setOverviewState(OVERVIEW_STATES.OPEN);
                setSelectedProduct({
                    code: parseInt(policyPlotId, 10),
                    type: OVERVIEW_TYPES.FIELD_INSURED,
                    meparCode: policyPlot.meparCode,
                    policyNumber: selectedPolicy.policyNumber,
                    plantCode: policyPlot.plantCode,
                    insuredArea: policyPlot.insuredArea,
                });
            }
        }

        if (damagePlotId !== null) {
            setOverviewState(OVERVIEW_STATES.OPEN);

            setSelectedProduct({
                code: parseInt(damagePlotId, 10),
                type: OVERVIEW_TYPES.FIELD_DAMAGED,
            });
        }

        setWmsData((pV) => ({
            ...pV,
            meparCode: result.meparCode,
        }));

        if (result.policies) {
            setNodes(
                result.policies.map((policy) => ({
                    code: policy.policyNumber,
                    name: `${policy.policyNumber}${
                        policy.policyPlots ? ` (${policy.policyPlots.length} tábla)` : ''
                    }`,
                    children: policy.policyPlots.map((p) => ({
                        code: p.id,
                        name: `${p.plantCode} - ${p.insuredArea} ha`,
                        type: OVERVIEW_TYPES.FIELD_INSURED,
                        meparCode: p.meparCode,
                        policyNumber: policy.policyNumber,
                        plantCode: p.plantCode,
                        insuredArea: p.insuredArea,
                    })),
                }))
            );
        }

        if (result.damages) {
            setDamageNodes(
                result.damages.map((damage) => ({
                    code: damage.damageNumber,
                    name: `${damage.damageNumber}`,
                    children: damage.damagePlots.map((damagePlot) => ({
                        code: damagePlot.id,
                        name: `${damagePlot.plantCode} - ${formatNumberToDecimal(
                            damagePlot.damagedArea,
                            'ha'
                        )}`,
                        type: OVERVIEW_TYPES.FIELD_DAMAGED,
                        mepar: {
                            meparCode: damagePlot.meparCode,
                            area: result.area,
                        },
                        plantCode: damagePlot.plantCode,
                        damagedArea: damagePlot.damagedArea,
                        tableGeometryHa: damagePlot.tableGeometryHa,
                    })),
                }))
            );
        }

        map.setLayerVisibility(MAP_LAYER_NAMES.MEPAR, true);
        map.setLayerData(MAP_LAYER_NAMES.MEPAR, {
            geoms: [
                {
                    geom: result.geom,
                    layerName: result.meparCode,
                },
            ],
        });

        if (!result.geom) {
            return;
        }

        map.zoomToGeomsExtent(
            {
                geom: result.geom,
            },
            !matchesLgUp ? { padding: [0, 0, 0, 0] } : {}
        );
    }, [
        result,
        setNodes,
        setDamageNodes,
        setWmsData,
        setOverviewState,
        damagePlotId,
        policyPlotId,
    ]);

    if (error) {
        return (
            <Grid item>
                <Alert severity="error">Hiba történt a kérés teljesítése során</Alert>
            </Grid>
        );
    }

    if (loading) {
        return (
            <Grid item>
                <CircularProgress />
            </Grid>
        );
    }

    return (
        <>
            <MeparData result={result} dataType={OVERVIEW_TYPES.MEPAR} noOutline={!result.geom} />
            <LinkAccordion
                title="MePAR blokkhoz tartozó kötvények"
                linkTo={OVERVIEW_TYPES.POLICY}
                nodes={nodes}
            />
            <LinkAccordion
                title="MePAR blokkhoz tartozó kárakták"
                linkTo={OVERVIEW_TYPES.REPORT}
                nodes={damageNodes}
            />
        </>
    );
};

export default MeparPage;
