const CONTROLLER_KEYS = Object.freeze({
    MONITORING: 'MONITORING',
    NDVI: 'NDVI',
    CHANGE_MAP: 'CHANGE_MAP',
    VISIBLE_IMAGE: 'VISIBLE_IMAGE',
    FROST_DAMAGE_LEVEL: 'FROST_DAMAGE_LEVEL',
    DEEP_MAP: 'DEEP_MAP',
    RISK_FACTOR: 'RISK_FACTOR',
    WEATHER_COMPOSITE: 'WEATHER_COMPOSITE',
    FROST: 'FROST',
    FROST_DAMAGE_CHRONOLOGY: 'FROST_DAMAGE_CHRONOLOGY',
    DAMAGE_CHRONOLOGY: 'DAMAGE_CHRONOLOGY',
    DAMAGE_CHRONOLOGY_SUM: 'DAMAGE_CHRONOLOGY_SUM',
    PLANT: 'PLANT',
    YIELD_REFERENCE: 'YIELD_REFERENCE',
    YIELD_CALCULATED: 'YIELD_CALCULATED',
    CROP: 'CROP',
    COLE_SEED: 'COLE_SEED',
    CORN: 'CORN',
    SPIKE: 'SPIKE',
    RAPE_SEED: 'RAPE_SEED',
    WHEAT: 'WHEAT',
    SUNFLOWER: 'SUNFLOWER',
    FIELD_WMS: 'FIELD_WMS',
    FIELD_WMS_MENU: 'FIELD_WMS_MENU',
    DAMAGE_MAP: 'DAMAGE_MAP',
    YIELD: 'YIELD',
    YIELD_REAL: 'YIELD_REAL',
    YIELD_MAP: 'YIELD_MAP',
    RADAR: 'RADAR',
    RADAR_MENU: 'RADAR_MENU',
    KARNAVI_PHOTOS: 'KARNAVI_PHOTOS',
    KARNAVI_PHOTOS_MENU: 'KARNAVI_PHOTOS_MENU',
    FROST_RISK: 'FROST_RISK',
    CITIES: 'CITIES',
    CITIES_BORDER: 'CITIES_BORDER',
});

const CONTROLLER_LABELS = {
    [CONTROLLER_KEYS.MONITORING]: 'Monitoring',
    [CONTROLLER_KEYS.NDVI]: 'NDVI',
    [CONTROLLER_KEYS.VISIBLE_IMAGE]: 'Látható kép',
    [CONTROLLER_KEYS.CHANGE_MAP]: 'Változás térkép',
    [CONTROLLER_KEYS.DAMAGE_MAP]: 'Káros - nem káros térkép',
    [CONTROLLER_KEYS.RISK_FACTOR]: 'Kockázati faktorok',
    [CONTROLLER_KEYS.WEATHER_COMPOSITE]: 'Időjárás kompozit',
    [CONTROLLER_KEYS.FROST]: 'Fagyveszély',
    [CONTROLLER_KEYS.FROST_DAMAGE_LEVEL]: 'Fagyveszély mértéke',
    [CONTROLLER_KEYS.DEEP_MAP]: 'Mélyfekvésű területek',
    [CONTROLLER_KEYS.DAMAGE_CHRONOLOGY]: 'Kárkronológia',
    [CONTROLLER_KEYS.DAMAGE_CHRONOLOGY_SUM]: 'Összesített kárkronológia',
    [CONTROLLER_KEYS.FROST_DAMAGE_CHRONOLOGY]: 'Fagykár kronológia',
    [CONTROLLER_KEYS.PLANT]: 'Növény térképek',
    [CONTROLLER_KEYS.YIELD_REFERENCE]: 'Referencia hozamtérkép',
    [CONTROLLER_KEYS.YIELD_CALCULATED]: 'Számított hozamtérkép',
    [CONTROLLER_KEYS.CROP]: 'Vetéstérkép',
    [CONTROLLER_KEYS.SPIKE]: 'Kalászosok',
    [CONTROLLER_KEYS.COLE_SEED]: 'Őszi káposztarepce',
    [CONTROLLER_KEYS.CORN]: 'Kukorica',
    [CONTROLLER_KEYS.SUNFLOWER]: 'Napraforgó',
    [CONTROLLER_KEYS.RAPE_SEED]: 'Repce',
    [CONTROLLER_KEYS.WHEAT]: 'Búza',
    [CONTROLLER_KEYS.YIELD]: 'Hozamtérképek',
    [CONTROLLER_KEYS.YIELD_REAL]: 'Számított hozamtérkép',
    [CONTROLLER_KEYS.YIELD_MAP]: 'Hozamtérkép',
    [CONTROLLER_KEYS.FIELD_WMS]: 'Tábla monitoring',
    [CONTROLLER_KEYS.FIELD_WMS_MENU]: 'Kronológia',
    [CONTROLLER_KEYS.RADAR]: 'Erőforrás allokáció támogatása',
    [CONTROLLER_KEYS.RADAR_MENU]: 'Erőforrások',
    [CONTROLLER_KEYS.KARNAVI_PHOTOS]: 'Fotók megjelenítése',
    [CONTROLLER_KEYS.KARNAVI_PHOTOS_MENU]: 'Kárnavi',
    [CONTROLLER_KEYS.FROST_RISK]: 'Fagyveszély értéke',
    [CONTROLLER_KEYS.CITIES]: 'Városok',
    [CONTROLLER_KEYS.CITIES_BORDER]: 'Városok határvonalai',
};

export { CONTROLLER_KEYS, CONTROLLER_LABELS };
