import { getDate, getMonth } from 'date-fns';
import React, { createContext, useContext, useMemo, useState } from 'react';
import { CONTROLLER_KEYS } from '../../../constants';
import { useAppControl } from '../../../providers/AppContext';
import { useMenu } from './MenuContext';

const RadarDateContext = createContext();
RadarDateContext.displayName = 'RadarDateContext';

const today = new Date();

export function RadarDateContextProvider({ children }) {
    const { checkedItems } = useMenu();
    const { year } = useAppControl();
    const [radarMonth, setRadarMonth] = useState(getMonth(today));
    const [radarData, setRadarData] = useState({
        isLayerVisible: checkedItems[CONTROLLER_KEYS.RADAR].includes(CONTROLLER_KEYS.RADAR),
        isMeparLayerVisible: true,
        date: new Date(year, radarMonth, getDate(today)),
        count: null,
    });

    const contextValue = useMemo(
        () => ({
            radarData,
            radarMonth,
            setRadarData,
            setRadarMonth,
        }),
        [radarData, radarMonth, setRadarData, setRadarMonth]
    );
    return <RadarDateContext.Provider value={contextValue}>{children}</RadarDateContext.Provider>;
}

export function useRadarDates() {
    return useContext(RadarDateContext);
}
