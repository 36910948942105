/* eslint-disable camelcase */
import { Button, CircularProgress, Grid, TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useAuth } from '../../../providers/AuthProvider';
import useNotify from '../../../hooks/useNotify';
import { api } from '../../../hooks/useApi';

const loginFormValidation = Yup.object({
    username: Yup.string().required('A mező kitöltése kötelező'),
    password: Yup.string().required('A mező kitöltése kötelező'),
});

const LoginForm = () => {
    const { setUser } = useAuth();
    const { notifyError } = useNotify();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const initialValues = {
        username: '',
        password: '',
    };

    const submitLoginForm = ({ username, password }) => {
        setLoading(true);
        api.users
            .loginUser({ username, password })
            .then((res) => {
                const user = res.data;
                setUser({ ...user });
                api.setSecurityData(user.token);
                setLoading(false);
                navigate('/');
            })
            .catch((err) => {
                console.error(err);
                notifyError(err);
                setLoading(false);
            });
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={submitLoginForm}
            validationSchema={loginFormValidation}
        >
            {({ values, getFieldProps, errors, touched }) => (
                <Form style={{ padding: '16px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Felhasználónév"
                                {...getFieldProps('username')}
                                error={!!touched.username && !!errors.username}
                                helperText={
                                    touched.username && errors.username
                                        ? `${errors.username}`
                                        : null
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                type="password"
                                label="Jelszó"
                                value={values.password}
                                {...getFieldProps('password')}
                                error={!!touched.password && !!errors.password}
                                helperText={
                                    touched.password && errors.password ? errors.password : ''
                                }
                            />
                        </Grid>
                        <Grid item xs={12} textAlign="center">
                            <Button type="submit" variant="contained" fullWidth>
                                {!loading ? (
                                    'Belépés'
                                ) : (
                                    <CircularProgress color="secondary" size={25} />
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default LoginForm;
