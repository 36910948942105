import { ChevronRightRounded } from '@mui/icons-material';
import React from 'react';
import LinkButton from './LinkButton';

const ShowOverviewButton = ({ onClick, title }) => {
    return (
        <LinkButton onClick={onClick} endIcon={<ChevronRightRounded />}>
            {title}
        </LinkButton>
    );
};

export default ShowOverviewButton;
