import { Alert, Grid, Typography } from '@mui/material';
import React from 'react';
import HomeButton from '../../../components/atom/HomeButton';
import { formatNumberToDecimal } from '../../../utils/formatter';

const MeparData = ({ result, noOutline }) => {
    return (
        <Grid item container xs={12} sx={{ overflowX: 'hidden' }}>
            <Grid
                item
                container
                xs={12}
                sx={{ mb: 1 }}
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item>
                    <Typography variant="h4">MePAR blokk</Typography>
                </Grid>
                <Grid item>
                    <HomeButton />
                </Grid>
            </Grid>
            {noOutline && (
                <Grid item xs={12} sx={{ mb: 1 }}>
                    <Alert severity="error">A MePAR körvonal hiányzik!</Alert>
                </Grid>
            )}
            <Grid container item xs={12} direction="column" gap={1} sx={{ mb: 1 }}>
                <Grid item container justifyContent="space-between">
                    <Grid item>MePAR kód:</Grid>
                    <Grid item>{result.meparCode}</Grid>
                </Grid>
                <Grid item container justifyContent="space-between">
                    <Grid item>MePAR terület:</Grid>
                    <Grid item>{formatNumberToDecimal(result.area, 'ha')}</Grid>
                </Grid>
                {result.frost !== undefined && (
                    <Grid item container justifyContent="space-between">
                        <Grid item>Fagyveszély értéke:</Grid>
                        <Grid item>{formatNumberToDecimal(result.frost)}</Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

export default MeparData;
