import { Button } from '@mui/material';
import React from 'react';

const LinkButton = ({ children, ...otherParams }) => {
    return (
        <Button className="link-button" disableRipple variant="text" {...otherParams}>
            {children}
        </Button>
    );
};

export default LinkButton;
