import { CancelRounded } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useMap } from 'es-map-widget';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
    DRAWING_STATES,
    MAP_LAYER_NAMES,
    OVERVIEW_STATES,
    TOOLTIP_TEXTS,
} from '../../../constants';
import { useAppControl } from '../../../providers/AppContext';

const PointCancelSetupButton = ({ disabled }) => {
    const map = useMap();
    const [_, setSearchParams] = useSearchParams();
    const { overviewState, setDrawingState, setOverviewState } = useAppControl();

    const handleClick = () => {
        map.drawCancel();
        map.setLayerVisibility(MAP_LAYER_NAMES.SAMPLING, false);
        map.setLayerVisibility(MAP_LAYER_NAMES.ZONE, false);
        setOverviewState(OVERVIEW_STATES.OPEN);
        setDrawingState(DRAWING_STATES.NONE);
        if (overviewState === OVERVIEW_STATES.CLOSED) {
            setSearchParams({});
        }
    };

    return (
        <Tooltip title={TOOLTIP_TEXTS.cancel} placement="right">
            <span>
                <IconButton onClick={handleClick} disabled={disabled}>
                    <CancelRounded />
                </IconButton>
            </span>
        </Tooltip>
    );
};

export default PointCancelSetupButton;
