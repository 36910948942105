import { PublicRounded } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

const BaseMapIcon = ({ text, isSelected, xs = 4, otherStyles = {}, onClick = () => {} }) => {
    return (
        <Grid
            item
            xs={xs}
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ px: 1, ...otherStyles }}
        >
            <IconButton
                variant="contained"
                onClick={onClick}
                disableFocusRipple
                disableTouchRipple
                disableRipple
                className={clsx('base-map-icon', isSelected ? 'selected' : '')}
            >
                <PublicRounded fontSize="large" />
            </IconButton>
            <span style={{ fontSize: '10px' }}>{text}</span>
        </Grid>
    );
};

export default BaseMapIcon;
