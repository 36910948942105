/* eslint-disable jsx-a11y/no-static-element-interactions */
import { CloseRounded } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import {
    Alert,
    CircularProgress,
    ClickAwayListener,
    Grid,
    IconButton,
    InputBase,
    ListSubheader,
    MenuItem,
    MenuList,
    Paper,
} from '@mui/material';
import { useMap } from 'es-map-widget';
import { groupBy } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_COLORS, OVERVIEW_STATES, ROLES } from '../../constants';
import { api, useApi } from '../../hooks/useApi';
import useDebounce from '../../hooks/useDebounce';
import useNotify from '../../hooks/useNotify';
import { useAppControl } from '../../providers/AppContext';
import { useAuth } from '../../providers/AuthProvider';
import { useLayout } from '../../providers/LayoutContext';
import { useMapFunctions } from '../../providers/MapFunctionsProvider';

const generateLink = (item) => {
    // eslint-disable-next-line no-nested-ternary
    const type = item.type === 'damage' ? 'report' : item.type === 'block' ? 'mepar' : 'policy';

    return `/products/${type}/${item.text}`;
};

const SearchForm = ({ setIsVisible = () => {} }) => {
    const map = useMap();
    const navigate = useNavigate();
    const [value, setValue] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const [open, setOpen] = useState(false);
    const [focused, setFocused] = useState(false);
    const [cityLoading, setCityLoading] = useState(false);
    const { setIsSearchVisible, setOverviewState, year } = useAppControl();
    const { matchesLgUp, setSelectedProduct } = useLayout();
    const { resetMap } = useMapFunctions();
    const { user } = useAuth();
    const { notifyError } = useNotify();

    const [searchResult, setSearchResult] = useState({
        damage: [],
        policy: [],
        block: [],
        cities: [],
    });

    useEffect(() => {
        setSearchInput(value);
    }, [value, setSearchInput]);

    useDebounce(() => setValue(searchInput), [searchInput, setValue], 300);

    const [result, loading, error] = useApi(
        (API, params) =>
            API.search.searchAll({ phrase: !value ? undefined : value, limit: 10, year }, params),
        [value, year]
    );

    useEffect(() => {
        if (!result) {
            return;
        }

        setSearchResult(groupBy((e) => e.type, result));
    }, [result, setSearchResult]);

    const handleSearchClose = () => {
        setOpen(false);
        setFocused(false);
    };

    const onClickItem = (item) => {
        setOpen(false);
        setFocused(false);
        setOverviewState(OVERVIEW_STATES.CLOSED);
        setSelectedProduct(null);
        resetMap();
        setValue('');
        if (!matchesLgUp) {
            setIsSearchVisible(false);
        }
        navigate(generateLink(item));
    };

    const navigateToCity = (item) => {
        setOpen(false);
        setFocused(false);
        setOverviewState(OVERVIEW_STATES.CLOSED);
        setSelectedProduct(null);
        resetMap();
        setValue('');
        if (!matchesLgUp) {
            setIsSearchVisible(false);
        }
        // we dont really need the extent here, because the backend will ignore it
        // just leave it here to keep the world in balance
        setCityLoading(true);
        api.layers
            .listCities({ names: [item.text], extent: [271973, -201809, 1103127, 605541] })
            .then((res) => {
                const [city] = res.data;
                if (!city) {
                    return;
                }
                map.zoomToGeomsExtent(
                    [
                        {
                            geom: city.geom,
                        },
                    ],
                    !matchesLgUp ? { padding: [50, 50, 50, 50] } : {}
                );
                setCityLoading(false);
            })
            .catch((err) => {
                setCityLoading(false);
                notifyError(err);
            });
    };

    return (
        <ClickAwayListener
            onClickAway={() => {
                setOpen(false);
                setFocused(false);
                setIsVisible();
            }}
            touchEvent={false}
        >
            <div
                style={{
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 10,
                    marginRight: matchesLgUp && '10px',
                    padding: '4px 14px',
                    borderRadius: '8px',
                    backgroundColor: '#fff',
                    border: `1px solid ${BASE_COLORS.PRIMARY_MAIN}`,
                    borderBottomLeftRadius: !focused && '8px',
                    borderBottomRightRadius: !focused && '8px',
                    borderBottom: focused
                        ? `1px solid ${BASE_COLORS.BACKGROUND_LIGHT_GRAY}`
                        : `1px solid ${BASE_COLORS.PRIMARY_MAIN}`,
                    width: !matchesLgUp && '100%',
                }}
            >
                <SearchIcon
                    sx={{
                        color: 'black',
                    }}
                />
                <InputBase
                    type="text"
                    value={searchInput}
                    onChange={(event) => setSearchInput(event.target.value)}
                    onFocus={() => {
                        setOpen(true);
                        setFocused(true);
                    }}
                    placeholder="Keresés..."
                    sx={{
                        '& .MuiInputBase-input': {
                            backgroundColor: '#fff',
                            color: 'black',
                            paddingLeft: '8px',
                        },
                    }}
                    fullWidth={!matchesLgUp}
                    endAdornment={
                        !matchesLgUp && (
                            <IconButton onClick={handleSearchClose} sx={{ p: 0 }}>
                                <CloseRounded />
                            </IconButton>
                        )
                    }
                />
                {open && (
                    <Paper
                        sx={{
                            display: open && 'block',
                            position: 'absolute',
                            top: 43,
                            left: 0,
                            backgroundColor: '#fff',
                            width: '100%',
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                        }}
                    >
                        {(loading || cityLoading) && (
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                sx={{ p: 1 }}
                            >
                                <CircularProgress />
                            </Grid>
                        )}
                        {!loading && (
                            <>
                                <MenuList
                                    key="damages"
                                    subheader={
                                        <ListSubheader
                                            style={{ lineHeight: '36px', fontWeight: 700 }}
                                            component="div"
                                            id="damage-subheader"
                                        >
                                            Kárakták
                                        </ListSubheader>
                                    }
                                >
                                    {searchResult.damage && searchResult.damage?.length > 0 ? (
                                        searchResult.damage.map((item) => (
                                            <MenuItem
                                                key={`${item.id}-${item.text}`}
                                                onClick={() => onClickItem(item)}
                                            >
                                                {item.text}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem disabled>Nem található ilyen kód</MenuItem>
                                    )}
                                </MenuList>
                                {user.role !== ROLES.EXPERT_DAMAGE && (
                                    <MenuList
                                        key="policies"
                                        subheader={
                                            <ListSubheader
                                                style={{ lineHeight: '36px', fontWeight: 700 }}
                                                component="div"
                                                id="damage-subheader"
                                            >
                                                Kötvények
                                            </ListSubheader>
                                        }
                                    >
                                        {searchResult.policy && searchResult.policy?.length > 0 ? (
                                            searchResult.policy.map((item) => (
                                                <MenuItem
                                                    key={`${item.id}-${item.text}`}
                                                    onClick={() => onClickItem(item)}
                                                >
                                                    {item.text}
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem disabled>Nem található ilyen kód</MenuItem>
                                        )}
                                    </MenuList>
                                )}
                                <MenuList
                                    key="mepars"
                                    subheader={
                                        <ListSubheader
                                            style={{ lineHeight: '36px', fontWeight: 700 }}
                                            component="div"
                                            id="damage-subheader"
                                        >
                                            MePAR-ok
                                        </ListSubheader>
                                    }
                                >
                                    {searchResult.block && searchResult.block?.length > 0 ? (
                                        searchResult.block.map((item) => (
                                            <MenuItem
                                                key={`${item.id}-${item.text}`}
                                                onClick={() => onClickItem(item)}
                                            >
                                                {item.text}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem disabled>Nem található ilyen kód</MenuItem>
                                    )}
                                </MenuList>
                                <MenuList
                                    key="city"
                                    subheader={
                                        <ListSubheader
                                            style={{ lineHeight: '36px', fontWeight: 700 }}
                                            component="div"
                                            id="damage-subheader"
                                        >
                                            Városok
                                        </ListSubheader>
                                    }
                                >
                                    {searchResult.city && searchResult.city?.length > 0 ? (
                                        searchResult.city.map((item) => (
                                            <MenuItem
                                                key={`${item.id}-${item.text}`}
                                                onClick={() => navigateToCity(item)}
                                            >
                                                {item.text}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem disabled>Nem található ilyen kód</MenuItem>
                                    )}
                                </MenuList>
                            </>
                        )}
                        {error && !loading && (
                            <Alert severity="error" sx={{ m: 1 }}>
                                Valami hiba történt
                            </Alert>
                        )}
                    </Paper>
                )}
            </div>
        </ClickAwayListener>
    );
};

export default SearchForm;
