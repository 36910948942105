const DRAWING_STATES = {
    POLYGON: 'POLYGON',
    POLYGON_EDIT: 'POLYGON_EDIT',
    POINT: 'POINT',
    POINT_SETUP: 'POINT_SETUP',
    POINT_YIELD: 'POINT_YIELD',
    POINT_DELETE: 'POINT_DELETE',
    NONE: false,
};

// eslint-disable-next-line import/prefer-default-export
export { DRAWING_STATES };
