import { Style, Text } from 'es-map-widget';
import { useCallback, useEffect } from 'react';
import { styles, textStyle } from '../mapwrapper/MapWrapperStyles';

const MeparDataprovider = ({ extent, layerData, setSource }) => {
    const addStyleToMepars = useCallback(
        (meparsWithoutStyle) => {
            return meparsWithoutStyle.map((e) => {
                return {
                    ...e,
                    style: new Style({
                        ...styles.mepar,
                        text: new Text({
                            ...textStyle,
                            text: e.layerName,
                        }),
                    }),
                };
            });
        },
        [layerData]
    );
    useEffect(() => {
        if (!layerData.geoms || layerData.geoms.length === 0) {
            setSource(null);
            return;
        }

        setSource(addStyleToMepars(layerData.geoms));
    }, [extent, layerData, setSource]);

    return null;
};

export default MeparDataprovider;
