import { format } from 'date-fns';
import { CONTROLLER_KEYS } from '../constants';

const generateLayerString = ({
    type,
    meparCode,
    damagePlotId,
    damageId,
    date,
    changeMap,
    damageMap,
}) => {
    let layerStringCode;
    let layerStringDate;
    switch (type) {
        case CONTROLLER_KEYS.FIELD_WMS:
            layerStringCode = `${meparCode}_${damagePlotId}_${damageId}`;
            layerStringDate = `${meparCode}_${damagePlotId}_Heterogeneity_${format(
                date,
                'yyyyMMdd'
            )}`;
            break;
        case CONTROLLER_KEYS.NDVI:
            layerStringCode = `${meparCode}`;
            layerStringDate = `${meparCode}_BMI_${format(date, 'yyyyMMdd')}`;
            break;
        case CONTROLLER_KEYS.VISIBLE_IMAGE:
            layerStringCode = `${meparCode}`;
            layerStringDate = `${meparCode}_TCI_${format(date, 'yyyyMMdd')}`;
            break;
        case CONTROLLER_KEYS.DAMAGE_MAP:
            layerStringCode = `${meparCode}_${damagePlotId}_${damageId}`;
            layerStringDate = `${meparCode}_${damagePlotId}_${damageId}_DamageMap_${format(
                new Date(damageMap.dateBefore),
                'yyyyMMdd'
            )}_${format(new Date(damageMap.dateAfter), 'yyyyMMdd')}`;
            break;
        case CONTROLLER_KEYS.CHANGE_MAP:
            layerStringCode = `${meparCode}`;
            layerStringDate = `${meparCode}_${damageId}_ChangeMap_${format(
                new Date(changeMap.dateBefore),
                'yyyyMMdd'
            )}_${format(new Date(changeMap.dateAfter), 'yyyyMMdd')}`;
            break;
        default:
            layerStringCode = '';
            layerStringDate = '';
            break;
    }
    return {
        layerStringCode,
        layerStringDate,
    };
};

export default generateLayerString;
