import { Box, useMediaQuery } from '@mui/material';
import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from '../components/header/Navbar';
import useWindowHeight from '../hooks/useWindowHeight';
import { useLayout } from '../providers/LayoutContext';

const AppLayout = () => {
    const matchesSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const { matchesLgUp } = useLayout();
    const height = useWindowHeight();

    const sx = useMemo(
        () => ({
            p: matchesLgUp ? 5 : 1,
            // eslint-disable-next-line no-nested-ternary
            pt: matchesSm ? '64px' : !matchesLgUp ? '72px' : '104px',
            height: matchesLgUp ? '100vh' : height,
            pb: matchesSm ? '36px' : 0,
            overflowY: 'auto',
        }),
        [matchesLgUp, matchesSm, height]
    );
    return (
        <>
            <Navbar />
            <Box bgcolor="#FAFBFD" sx={sx}>
                <Outlet />
            </Box>
        </>
    );
};

export default AppLayout;
