import { useEffect } from 'react';
import { MAP_EVENTS, useMap } from 'es-map-widget';
import { CONTROLLER_KEYS, getZoomLevelVisibility, MAP_LAYER_NAMES } from '../../../constants';
import { useAuth } from '../../../providers/AuthProvider';
import { useMenu } from './MenuContext';

function getLayerVisibilityInMenu(checkedItemsInMenu, layer) {
    const MAP_LAYER_NAMES_TO_MENU_ITEMS = {
        [MAP_LAYER_NAMES.YIELD_MAP]: () =>
            (checkedItemsInMenu[CONTROLLER_KEYS.YIELD_REFERENCE] &&
                checkedItemsInMenu[CONTROLLER_KEYS.YIELD_REFERENCE].isLayerVisible) ||
            (checkedItemsInMenu[CONTROLLER_KEYS.YIELD_CALCULATED] &&
                checkedItemsInMenu[CONTROLLER_KEYS.YIELD_CALCULATED].isLayerVisible),
        [MAP_LAYER_NAMES.FROST]: () =>
            checkedItemsInMenu[CONTROLLER_KEYS.FROST].includes(CONTROLLER_KEYS.FROST_DAMAGE_LEVEL),
        [MAP_LAYER_NAMES.DEEP_MAP]: () =>
            checkedItemsInMenu[CONTROLLER_KEYS.FROST].includes(CONTROLLER_KEYS.DEEP_MAP),
        [MAP_LAYER_NAMES.FROST_RISK]: () =>
            checkedItemsInMenu[CONTROLLER_KEYS.FROST].includes(CONTROLLER_KEYS.FROST_RISK),
        [MAP_LAYER_NAMES.DAMAGE_CHRONOLOGY]: () =>
            checkedItemsInMenu[CONTROLLER_KEYS.DAMAGE_CHRONOLOGY].includes(
                CONTROLLER_KEYS.DAMAGE_CHRONOLOGY_SUM
            ),
        [MAP_LAYER_NAMES.FROST_DAMAGE_CHRONOLOGY]: () =>
            checkedItemsInMenu[CONTROLLER_KEYS.DAMAGE_CHRONOLOGY].includes(
                CONTROLLER_KEYS.FROST_DAMAGE_CHRONOLOGY
            ),
        [MAP_LAYER_NAMES.CROP]: () => checkedItemsInMenu[CONTROLLER_KEYS.CROP].length > 0,
        [MAP_LAYER_NAMES.RADAR]: () =>
            checkedItemsInMenu[CONTROLLER_KEYS.RADAR].includes(CONTROLLER_KEYS.RADAR),
        [MAP_LAYER_NAMES.RADAR_MEPAR]: () =>
            checkedItemsInMenu[CONTROLLER_KEYS.RADAR].includes(CONTROLLER_KEYS.RADAR_MEPAR),
        [MAP_LAYER_NAMES.CITY]: () =>
            checkedItemsInMenu[CONTROLLER_KEYS.CITIES].includes(CONTROLLER_KEYS.CITIES),
    };

    return MAP_LAYER_NAMES_TO_MENU_ITEMS[layer] && MAP_LAYER_NAMES_TO_MENU_ITEMS[layer]();
}

function updateLayerVisibility(map, user, checkedItems) {
    const zoomLevel = map.getZoom();

    Object.values(MAP_LAYER_NAMES).forEach((layer) => {
        const isVisible = getZoomLevelVisibility(user, layer, zoomLevel);
        const isVisibleInMenu = getLayerVisibilityInMenu(checkedItems, layer);

        if (isVisible === undefined || isVisibleInMenu === undefined || !isVisibleInMenu) {
            return;
        }

        try {
            if (map.getLayerVisibility(layer) !== isVisible) {
                map.setLayerVisibility(layer, isVisible);
                map.setLayerData(layer, { isLayerVisible: isVisible });
            }
        } catch (error) {
            // layers may not be added to the map
        }
    });
}

const LayerAccessController = () => {
    const map = useMap();
    const { user } = useAuth();
    const { checkedItems } = useMenu();

    // adjust the layer's visibility for the current user if necessary
    useEffect(() => {
        // update the layer visibility whenever the user changes menu selections
        updateLayerVisibility(map, user, checkedItems);

        // also update the layer visibility whenever the user zooms the map in or out
        // however as there is no zoom change event in the map, use extentChange instead (fired a bit more often)
        const extentListener = () => updateLayerVisibility(map, user, checkedItems);
        map.addEventListener(MAP_EVENTS.extentChange, extentListener);

        return () => {
            map.removeEventListener(MAP_EVENTS.extentChange, extentListener);
        };
    }, [map, user, checkedItems]);

    return null;
};

export default LayerAccessController;
