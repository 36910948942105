/**
 * Logged in user key name in local storage
 * @type {string}
 */
export const LOGGED_IN_USER_KEY = 'georiskLoggedInUser';

/**
 * MapController in local storage
 * @type {string}
 */
export const ENVIRO_MAP_CONTROLLER = 'enviroMapController';

/**
 * Menu state for QoL and persist through sessions
 * @type {stirng}
 */
export const PRODUCT_CONTROLLER_STATE = 'georiskProductControllerState';

/**
 * Backend url for openapi
 * @type {string}
 */
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || `http://localhost:3001`;
/**
 * Default map for map component
 */
export const BASE_MAP = 'basemap';
/**
 * Sentry DSN url
 * @type {string|string}
 */
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || 'nosentry';
export const SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV || '';
