import {
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useMap } from 'es-map-widget';
import { Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { MAP_LAYER_NAMES, MODAL_TYPE } from '../constants';
import { api } from '../hooks/useApi';
import useNotify from '../hooks/useNotify';
import { useAppControl } from '../providers/AppContext';

const SamplingModificationModal = ({ samplingPoint, damagePlotId, showModal }) => {
    const map = useMap();
    const { samplingData, setSamplingData } = useAppControl();
    const { notifySuccess, notifyError } = useNotify();
    const handleClose = (_, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        showModal(MODAL_TYPE.NONE);
    };

    const onSubmit = async (values) => {
        api.damagePlots
            .saveSampling({
                damagePlotId: parseInt(damagePlotId, 10),
                sampling: samplingData.points.map((p) => {
                    if (p.sequenceId === samplingPoint.sequenceId) {
                        return {
                            sequenceId: p.sequenceId,
                            geom: p.geom,
                            isGenerated: p.isGenerated,
                            isUsed: p.isUsed,
                            yield: parseFloat(Number(`${values.yield}`.replace(/,/, '.'))),
                            name: values.name || p.name,
                            comment: values.comment || p.comment,
                            sampledAt: values.date ? new Date(values.date) : null,
                        };
                    }
                    return {
                        sequenceId: p.sequenceId,
                        geom: p.geom,
                        isGenerated: p.isGenerated,
                        isUsed: p.isUsed,
                        yield: p.yield,
                        name: p.name,
                        comment: p.comment,
                        sampledAt: p.date ?? null,
                    };
                }),
            })
            .then((res) => {
                notifySuccess('Sikeres módosítás');
                setSamplingData((p) => ({ ...p, points: res.data }));
                map.setLayerData(MAP_LAYER_NAMES.SAMPLING, { geoms: res.data });
                showModal(MODAL_TYPE.NONE);
            })
            .catch((err) => notifyError(err));
    };

    return (
        <Dialog open onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle>Mintavétel módosítása</DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={{
                        yield: samplingPoint.yield,
                        name: samplingPoint.name,
                        comment: samplingPoint.comment,
                        date: samplingPoint.sampledAt || new Date(),
                    }}
                    onSubmit={onSubmit}
                    validationSchema={Yup.object({
                        yield: Yup.number()
                            .nullable()
                            .transform((_value, originalValue) => {
                                const a = Number(`${originalValue}`.replace(/,/, '.'));
                                return !Number.isNaN(a) ? a : null;
                            })
                            .test(
                                'maxDigitsAfterDecimal',
                                'Csak számokat tartalmazhat a következő formátumban: 5,1',
                                (number) => /^\d+([.,]\d{1})?$/.test(number)
                            )
                            .required('Kötelező')
                            .positive(),
                        name: Yup.string().nullable(),
                        comment: Yup.string().nullable(),
                        date: Yup.date().required('Kötelező'),
                    })}
                    enableReinitialize
                >
                    {({
                        submitForm,
                        isSubmitting,
                        touched,
                        errors,
                        getFieldProps,
                        values,
                        setFieldValue,
                    }) => (
                        <Form>
                            <Grid container spacing={2} sx={{ pb: 1 }}>
                                <Grid item xs={12} lg={6}>
                                    Sorszám: {samplingPoint.sequenceId}
                                </Grid>
                                <Grid item container xs={12} lg={6} direction="column">
                                    <Grid item>Koordináták:</Grid>
                                    <Grid item container justifyContent="flex-start">
                                        <Grid item sx={{ mr: 0.5 }}>
                                            {samplingPoint.geom.getCoordinates()[0]},
                                        </Grid>
                                        <Grid item>{samplingPoint.geom.getCoordinates()[1]}</Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <TextField
                                        margin="dense"
                                        label="Hozam (t/ha)"
                                        fullWidth
                                        required
                                        {...getFieldProps('yield')}
                                        value={values.yield || ''}
                                        error={touched.yield && !!errors.yield}
                                        helperText={
                                            touched.yield && errors.yield ? `${errors.yield}` : null
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <TextField
                                        margin="dense"
                                        label="Elnevezés"
                                        fullWidth
                                        {...getFieldProps('name')}
                                        value={values.name}
                                        error={touched.name && !!errors.name}
                                        helperText={
                                            touched.name && errors.name ? `${errors.name}` : null
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <TextField
                                        margin="dense"
                                        label="Megjegyzés"
                                        fullWidth
                                        {...getFieldProps('comment')}
                                        value={values.comment}
                                        error={touched.comment && !!errors.comment}
                                        helperText={
                                            touched.comment && errors.comment
                                                ? `${errors.comment}`
                                                : null
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <DatePicker
                                        label="Mintavételezés dátuma"
                                        disableFuture
                                        value={values.date}
                                        onChange={(date) => setFieldValue('date', date)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                margin="dense"
                                                required
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    lg={8}
                                    justifyContent="flex-start"
                                    alignItems="center"
                                >
                                    <Typography variant="caption">
                                        A *-al jelölt mezők kitöltése kötelező!
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    lg={4}
                                    justifyContent="flex-end"
                                    alignItems="center"
                                >
                                    <Button
                                        onClick={handleClose}
                                        disabled={isSubmitting}
                                        sx={{ mr: 1 }}
                                    >
                                        Mégse
                                    </Button>
                                    <Button variant="contained" onClick={submitForm}>
                                        {isSubmitting ? <CircularProgress /> : 'Mentés'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default SamplingModificationModal;
