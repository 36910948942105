/* eslint-disable react/no-array-index-key */
import { Alert, CircularProgress, Grid, TableCell, TableRow } from '@mui/material';
import React, { useState } from 'react';
import { useApi } from '../../../../hooks/useApi';
import { useAppControl } from '../../../../providers/AppContext';
import { formatNumberToDecimal } from '../../../../utils/formatter';
import DashboardTable from '../DashboardTable';

const DamagedPlants = () => {
    const { year } = useAppControl();
    const [result, loading, error] = useApi(
        (api, objWithSignal) => api.dashboards.getDamagePlantsDashboard({ year }, objWithSignal),
        [year]
    );
    const [filtered, setFiltered] = useState([]);

    if (error) {
        return (
            <Grid item>
                <Alert severity="error">Hiba történt a kérés teljesítése során</Alert>
            </Grid>
        );
    }

    if (loading) {
        return (
            <Grid item>
                <CircularProgress />
            </Grid>
        );
    }

    return (
        <Grid container item spacing={2}>
            <DashboardTable
                title={`${year} - Károsodott biztosított növények káraktáinak száma és területmérete`}
                gridProps={{ xs: 12, lg: 6 }}
                headerRows={['Növény', 'Kárakták száma', 'Ha']}
                loading={loading}
                error={error}
                tableSx={{
                    maxHeight: 'calc(100vh - 330px)',
                }}
                filterable
                toFilter={result}
                setFiltered={setFiltered}
            >
                {filtered.map(({ count, category, area }, i) => (
                    <TableRow key={i}>
                        <TableCell>{category}</TableCell>
                        <TableCell align="right">{count}</TableCell>
                        <TableCell align="right">{formatNumberToDecimal(area, 'ha')}</TableCell>
                    </TableRow>
                ))}
            </DashboardTable>
        </Grid>
    );
};

export default DamagedPlants;
