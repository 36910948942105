import { FormGroup } from '@mui/material';
import { useMap } from 'es-map-widget';
import React from 'react';
import { CONTROLLER_KEYS, CONTROLLER_LABELS, MAP_LAYER_NAMES } from '../../../constants';
import { useMenu } from '../contexts/MenuContext';
import MapControllerMenuItem from '../menu/MapControllerMenuItem';

const FrostDataController = ({ selectedItems, setSelectedItems }) => {
    const map = useMap();
    const { handleItemCheck } = useMenu();
    return (
        <FormGroup>
            <MapControllerMenuItem
                text={CONTROLLER_LABELS.FROST_DAMAGE_LEVEL}
                checkbox={{
                    checked: selectedItems.includes(CONTROLLER_KEYS.FROST_DAMAGE_LEVEL),
                    onChange: (event) => {
                        const isChecked = event.currentTarget.checked;
                        setSelectedItems(
                            isChecked
                                ? [...selectedItems, CONTROLLER_KEYS.FROST_DAMAGE_LEVEL]
                                : selectedItems.filter(
                                      (e) => e !== CONTROLLER_KEYS.FROST_DAMAGE_LEVEL
                                  )
                        );
                        handleItemCheck(CONTROLLER_KEYS.FROST_DAMAGE_LEVEL, CONTROLLER_KEYS.FROST);
                    },
                }}
            />
            <MapControllerMenuItem
                text={CONTROLLER_LABELS.DEEP_MAP}
                checkbox={{
                    checked: selectedItems.includes(CONTROLLER_KEYS.DEEP_MAP),
                    onChange: (event) => {
                        const isChecked = event.currentTarget.checked;
                        setSelectedItems(
                            isChecked
                                ? [...selectedItems, CONTROLLER_KEYS.DEEP_MAP]
                                : selectedItems.filter((e) => e !== CONTROLLER_KEYS.DEEP_MAP)
                        );
                        map.setLayerVisibility(MAP_LAYER_NAMES.DEEP_MAP, isChecked);
                        handleItemCheck(CONTROLLER_KEYS.DEEP_MAP, CONTROLLER_KEYS.FROST);
                    },
                }}
            />
            <MapControllerMenuItem
                text={CONTROLLER_LABELS.FROST_RISK}
                checkbox={{
                    checked: selectedItems.includes(CONTROLLER_KEYS.FROST_RISK),
                    onChange: (event) => {
                        const isChecked = event.currentTarget.checked;
                        setSelectedItems(
                            isChecked
                                ? [...selectedItems, CONTROLLER_KEYS.FROST_RISK]
                                : selectedItems.filter((e) => e !== CONTROLLER_KEYS.FROST_RISK)
                        );
                        map.setLayerVisibility(MAP_LAYER_NAMES.FROST_RISK, isChecked);
                        map.setLayerData(MAP_LAYER_NAMES.FROST_RISK, {
                            isLayerVisible: isChecked,
                        });
                        handleItemCheck(CONTROLLER_KEYS.FROST_RISK, CONTROLLER_KEYS.FROST);
                    },
                }}
            />
        </FormGroup>
    );
};

export default FrostDataController;
