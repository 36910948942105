/* eslint-disable react/no-array-index-key */
import { Alert, CircularProgress, Grid, TableCell, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useApi } from '../../../../hooks/useApi';
import { useAppControl } from '../../../../providers/AppContext';
import { formatNumberToDecimal } from '../../../../utils/formatter';
import DashboardTable from '../DashboardTable';

const Damages = () => {
    const { year } = useAppControl();
    const [result, loading, error] = useApi(
        (api, objWithSignal) => api.dashboards.getDamageTypesDashboard({ year }, objWithSignal),
        [year]
    );

    const [filtered, setFiltered] = useState([]);

    useEffect(() => {
        if (!result) {
            return;
        }

        setFiltered(result);
    }, [result, setFiltered]);

    if (error) {
        return (
            <Grid item>
                <Alert severity="error">Hiba történt a kérés teljesítése során</Alert>
            </Grid>
        );
    }

    if (loading) {
        return (
            <Grid item>
                <CircularProgress />
            </Grid>
        );
    }

    return (
        <Grid container item spacing={2}>
            <DashboardTable
                title={`${year} - Kárakták száma (db) és területmérete (ha) károkonként`}
                gridProps={{ xs: 12, lg: 6 }}
                headerRows={['Kár oka', 'Kárakták száma', 'Ha']}
                loading={loading}
                error={error}
                tableSx={{ maxHeight: 'calc(100vh - 310px)' }}
                filterable
                toFilter={result}
                setFiltered={setFiltered}
            >
                {filtered &&
                    filtered.map(({ count, category, area }, i) => (
                        <TableRow key={i}>
                            <TableCell>{category}</TableCell>
                            <TableCell align="right">{count}</TableCell>
                            <TableCell align="right">{formatNumberToDecimal(area, 'ha')}</TableCell>
                        </TableRow>
                    ))}
            </DashboardTable>
        </Grid>
    );
};

export default Damages;
