import { Grid, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import TabPanel from './TabPanel';
import ExpertActivity from './damageExperts/ExpertActivity';
import Experts from './damageExperts/Experts';

const AdminDamageExperts = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const tabIndex = searchParams.get('tabIndex');
    const [tabValue, setTabValue] = useState(tabIndex ? parseInt(tabIndex, 10) : 0);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
        setSearchParams({ ...searchParams, tabIndex: newValue });
    };

    useEffect(() => {
        if (searchParams?.tabIndex) {
            return;
        }
        setSearchParams({ ...searchParams, tabIndex: 0 });
    }, []);

    return (
        <Grid container sx={{ flexGrow: 1, height: '100%' }} alignContent="flex-start">
            <Grid item sx={{ borderBottom: 1, borderColor: 'divider' }} xs={12}>
                <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                >
                    <Tab label="Kárszakértők - kárakták" />
                    <Tab label="Kárszakértői aktivitás" />
                </Tabs>
            </Grid>
            <Grid item xs={12}>
                <TabPanel value={tabValue} index={0}>
                    <Experts />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <ExpertActivity />
                </TabPanel>
            </Grid>
        </Grid>
    );
};

export default AdminDamageExperts;
