import { UndoRounded } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useMap } from 'es-map-widget';
import React from 'react';
import { DRAWING_STATES, TOOLTIP_TEXTS } from '../../../constants';
import { useAppControl } from '../../../providers/AppContext';

const PointUndoButton = () => {
    const map = useMap();
    const { drawingState } = useAppControl();

    const handleClick = () => {
        map.drawPointUndo(drawingState === DRAWING_STATES.POINT_DELETE ? 'delete' : 'add');
    };

    return (
        <Tooltip title={TOOLTIP_TEXTS.undo} placement="right">
            <span>
                <IconButton
                    onClick={handleClick}
                    disabled={
                        drawingState === DRAWING_STATES.POINT_YIELD ||
                        drawingState === DRAWING_STATES.POINT_DELETE
                    }
                >
                    <UndoRounded />
                </IconButton>{' '}
            </span>
        </Tooltip>
    );
};

export default PointUndoButton;
