import { Fill, Stroke, Style, Text } from 'es-map-widget';
import { useCallback, useEffect } from 'react';
import { getMapVariantForZoomLevel, MAP_LAYER_NAMES, YIELD_COLORS } from '../../constants';
import { api } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import { useAppControl } from '../../providers/AppContext';
import { useAuth } from '../../providers/AuthProvider';

export default function YieldDataprovider({ extent, zoom, layerData, setSource }) {
    const { year } = useAppControl();
    const { table, type, plant, isLayerVisible } = layerData;
    const { notifyError } = useNotify();
    const { user } = useAuth();

    const getYieldMap = useCallback(
        async (controller, params) =>
            api.layers
                .getYieldsLayer(params, { signal: controller.signal })
                .then((res) => res.data),
        [api]
    );

    const addStyleToZones = (zonesWithoutStyle) =>
        zonesWithoutStyle.map((e) => {
            const y = e.yield < 0 ? 0 : Math.trunc(e.yield);
            return {
                ...e,
                style: new Style({
                    fill: new Fill({
                        color: YIELD_COLORS[y % 2 === 0 ? y : y - 1],
                    }),
                    stroke: new Stroke({
                        color: [0, 0, 255, 0.85],
                        width: 1,
                    }),
                    text: new Text({
                        font: '12px sans-serif',
                        fill: new Fill({ color: '#000' }),
                        stroke: new Stroke({
                            color: '#fff',
                            width: 2,
                        }),
                        text: `${e.yield}`,
                    }),
                }),
            };
        });

    useEffect(() => {
        const mapVariantForZoomLevel = getMapVariantForZoomLevel(
            user,
            MAP_LAYER_NAMES.YIELD_MAP,
            zoom
        );

        if (!isLayerVisible || table === '' || type === '' || plant === '') {
            setSource(null);
            return;
        }

        const controller = new AbortController();

        getYieldMap(controller, {
            table: mapVariantForZoomLevel,
            type,
            plant,
            extent,
            year,
        })
            .then((res) => setSource(addStyleToZones(res)))
            .catch((err) => {
                if (err.code !== 'ERR_CANCELED') {
                    notifyError(err);
                }
            });

        // eslint-disable-next-line consistent-return
        return () => {
            controller.abort();
        };
    }, [extent, table, type, plant, addStyleToZones, setSource, zoom, isLayerVisible, user]);

    return null;
}
