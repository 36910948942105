import * as Sentry from '@sentry/react';
import { CssBaseline } from '@mui/material';
import 'es-map-widget/dist/ol-ext.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import Providers from './providers';
import { SENTRY_DSN, SENTRY_ENV } from './config';

const root = ReactDOM.createRoot(document.getElementById('root'));

if (SENTRY_DSN === 'nosentry') {
    // no sentry inside the system
    root.render(
        <React.StrictMode>
            <BrowserRouter>
                <Providers>
                    <CssBaseline />
                    <App />
                </Providers>
            </BrowserRouter>
        </React.StrictMode>
    );
} else {
    Sentry.init({
        dsn: SENTRY_DSN,
        environment: SENTRY_ENV,
        integrations: [new Sentry.BrowserTracing()],
        tracesSampleRate: 1.0,
        normalizeDepth: 12,
        beforeSend(event) {
            // was: beforeSend(event, hint)
            // Check if it is an exception, and if so, show the report dialog
            if (event.exception) {
                Sentry.showReportDialog({ eventId: event.event_id });
            }
            return event;
        },
    });

    root.render(
        <Sentry.ErrorBoundary>
            <BrowserRouter>
                <Providers>
                    <CssBaseline />
                    <App />
                </Providers>
            </BrowserRouter>
        </Sentry.ErrorBoundary>
    );
}
