/* eslint-disable react/no-array-index-key */
import { Grid, TableCell, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useApi } from '../../../../hooks/useApi';
import { useAppControl } from '../../../../providers/AppContext';
import { formatNumberToDecimal } from '../../../../utils/formatter';
import DashboardTable from '../DashboardTable';

const cropNames = Object.freeze({
    1: 'Kalászosok',
    2: 'Őszi káposztarepce',
    3: 'Kukorica',
    4: 'Napraforgó',
});

const InsuredArea = () => {
    const { year } = useAppControl();
    const [result, loading, error] = useApi(
        (api, objWithSignal) => api.dashboards.getPolicyAreaDashboard({ year }, objWithSignal),
        [year]
    );

    const [filteredAllPlants, setFilteredAllPlants] = useState([]);

    useEffect(() => {
        if (!result || !result?.plants) {
            return;
        }

        setFilteredAllPlants(result.plants);
    }, [result, setFilteredAllPlants]);

    return (
        <Grid container item spacing={2}>
            <DashboardTable
                title={`${year} - Országos vetéstérkép növénykategóriáinak területmérete (ha)`}
                gridProps={{ xs: 12, lg: 4 }}
                headerRows={['Növény', 'Ha']}
                loading={loading}
                error={error}
            >
                {result?.country &&
                    result?.country.length > 0 &&
                    result?.country.map(({ category, area }, i) => (
                        <TableRow key={i}>
                            <TableCell>{cropNames[category]}</TableCell>
                            <TableCell align="right">{formatNumberToDecimal(area, 'ha')}</TableCell>
                        </TableRow>
                    ))}
            </DashboardTable>
            <DashboardTable
                title={`${year} - Idei év összes kötvényének főnövényei és azok területmérete (ha)`}
                gridProps={{ xs: 12, lg: 4 }}
                headerRows={['Növény', 'Ha']}
                loading={loading}
                error={error}
            >
                {result?.categories &&
                    result?.categories.map(({ category, area }) => (
                        <TableRow key={`${category}-${area}`}>
                            <TableCell>{category}</TableCell>
                            <TableCell align="right">{formatNumberToDecimal(area, 'ha')}</TableCell>
                        </TableRow>
                    ))}
            </DashboardTable>
            <DashboardTable
                title={`${year} - Idei év összes kötvényének összes növénye és azok területmérete (ha)`}
                gridProps={{ xs: 12, lg: 4 }}
                headerRows={['Növény', 'Ha']}
                loading={loading}
                error={error}
                filterable
                toFilter={result?.plants || []}
                setFiltered={setFilteredAllPlants}
                tableSx={{
                    maxHeight: 'calc(100vh - 260px)',
                }}
            >
                {filteredAllPlants &&
                    filteredAllPlants.map(({ category, area }, i) => (
                        <TableRow key={i}>
                            <TableCell>{category !== '' ? category : '-'}</TableCell>
                            <TableCell align="right">{formatNumberToDecimal(area, 'ha')}</TableCell>
                        </TableRow>
                    ))}
            </DashboardTable>
        </Grid>
    );
};

export default InsuredArea;
