import { Grid, Paper } from '@mui/material';
import { useMap } from 'es-map-widget';
import React, { useRef, useState } from 'react';
import { BASE_MAP } from '../../../config';
import { MAP_LAYER_NAMES } from '../../../constants';
import { useLayout } from '../../../providers/LayoutContext';
import BaseMapIcon from '../menu/BaseMapIcon';

const MAP_KEYS = {
    OSM: MAP_LAYER_NAMES.OSM,
    BING: MAP_LAYER_NAMES.BING,
};

function getMapState() {
    const mapState = localStorage.getItem(BASE_MAP);
    if (!mapState || mapState === 'undefined') {
        localStorage.setItem(BASE_MAP, MAP_KEYS.OSM);
        return MAP_KEYS.OSM;
    }
    return mapState;
}

const BaseMapController = ({ bottom }) => {
    const map = useMap();
    const [selectedMap, setSelectedMap] = useState(() => getMapState());
    const { matchesLgUp } = useLayout();
    const paperRef = useRef(null);

    const handleClick = () => {
        if (selectedMap === MAP_KEYS.OSM) {
            localStorage.setItem(BASE_MAP, MAP_KEYS.BING);
            map.setLayerVisibility(MAP_LAYER_NAMES.BING, true);
            map.setLayerVisibility(MAP_LAYER_NAMES.OSM, false);
        } else {
            map.setLayerVisibility(MAP_LAYER_NAMES.OSM, true);
            map.setLayerVisibility(MAP_LAYER_NAMES.BING, false);
            localStorage.setItem(BASE_MAP, MAP_KEYS.OSM);
        }
        setSelectedMap(selectedMap === MAP_KEYS.OSM ? MAP_KEYS.BING : MAP_KEYS.OSM);
    };

    return (
        <Paper className="basemap-menu" sx={{ bottom, right: matchesLgUp ? 16 : 8 }} ref={paperRef}>
            <Grid container>
                <BaseMapIcon
                    xs={12}
                    text={
                        selectedMap === MAP_LAYER_NAMES.OSM
                            ? MAP_LAYER_NAMES.OSM
                            : MAP_LAYER_NAMES.BING
                    }
                    onClick={handleClick}
                />
            </Grid>
        </Paper>
    );
};

export default BaseMapController;
