import { CircularProgress, IconButton } from '@mui/material';
import React from 'react';

const LoadingIconButton = ({ icon, onClick, loading, ...iconButtonProps }) => {
    return (
        <IconButton onClick={onClick} {...iconButtonProps}>
            {!loading ? icon : <CircularProgress color="secondary" size={25} />}
        </IconButton>
    );
};

export default LoadingIconButton;
