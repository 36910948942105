import { getMonth } from 'date-fns';
import { LAYER_TYPES, Map, PREDEFINED_LAYERS } from 'es-map-widget';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BASE_MAP, PRODUCT_CONTROLLER_STATE } from '../../config';
import { CONTROLLER_KEYS, OVERVIEW_STATES, YIELD_PLANT_OPTIONS, crops } from '../../constants';
import { MAP_LAYER_NAMES } from '../../constants/mapLayerNames';
import DrawControllerWrapper from '../../mapdrawingcontroller/DrawControllerWrapper';
import { useAppControl } from '../../providers/AppContext';
import { useAuth } from '../../providers/AuthProvider';
import BaseMapController from '../mapcontroller/maincontrollers/BaseMapController';
import ChangeMapDataprovider from '../mapdata/ChangeMapDataprovider';
import CityDataprovider from '../mapdata/CityDataprovider';
import CropDataprovider from '../mapdata/CropDataprovider';
import DamageChronologyDataprovider from '../mapdata/DamageChronologyDataprovider';
import DamageMapDataprovider from '../mapdata/DamageMapDataprovider';
import FieldDataprovider from '../mapdata/FieldDataprovider';
import FrostDamageChronologyDataprovider from '../mapdata/FrostDamageChronologyDataprovider';
import FrostMeparDataprovider from '../mapdata/FrostMeparDataprovider';
import KarnaviPhotoDataprovider from '../mapdata/KarnaviPhotoDataprovider';
import MeparDataprovider from '../mapdata/MeparDataprovider';
import NdviDataprovider from '../mapdata/NdviDataprovider';
import RadarDataprovider from '../mapdata/RadarDataprovider';
import RadarMeparDataprovider from '../mapdata/RadarMeparDataprovider';
import SamplingDataprovider from '../mapdata/SamplingDataprovider';
import YieldDataprovider from '../mapdata/YieldDataProvider';
import ZoneDataprovider from '../mapdata/ZoneDataprovider';
import MapLegend from '../maplegend/MapLegend';
import GeolocationButton from './GeolocationButton';
import './map.css';

const today = new Date();

const LEGEND_FROM_BOTTOM = 8;
/**
 * Application layer for the Map, nothing below this should be exposed to the app and should move
 * to the map component
 * @returns {JSX.Element}
 * @constructor
 */
const MapWrapper = ({ overviewRef }) => {
    const { user } = useAuth();
    const { year, overviewState } = useAppControl();
    const baseMap = useMemo(() => localStorage.getItem(BASE_MAP) || 'BING', []);
    const controllerState = useMemo(
        () => JSON.parse(localStorage.getItem(`${PRODUCT_CONTROLLER_STATE}:${user.id}`)),
        []
    );
    const [legendOpen, setLegendOpen] = useState(false);
    const [legendFromBottom, setLegendFromBottom] = useState(LEGEND_FROM_BOTTOM);

    const onToggleLegend = useCallback(
        (shouldOpen) => {
            setLegendOpen(shouldOpen);
        },
        [setLegendOpen]
    );

    useEffect(() => {
        if (overviewRef.current && overviewState === OVERVIEW_STATES.OPEN) {
            setLegendFromBottom(overviewRef.current.clientHeight + 32);
            return;
        }
        if (overviewRef.current && overviewState === OVERVIEW_STATES.HIDDEN) {
            setLegendFromBottom(overviewRef.current.clientHeight + 32);
            return;
        }
        setLegendFromBottom(LEGEND_FROM_BOTTOM);
    }, [overviewRef, overviewState]);

    const getMapLayerDefinition = useCallback(() => {
        return {
            [MAP_LAYER_NAMES.OSM]: {
                type: LAYER_TYPES.TILE,
                source: PREDEFINED_LAYERS.OSM,
                defaultVisibility: baseMap === MAP_LAYER_NAMES.OSM,
            },
            [MAP_LAYER_NAMES.BING]: {
                type: LAYER_TYPES.TILE,
                source: PREDEFINED_LAYERS.BING,
                sourceSettings: {
                    key: 'AkeEA2smohBmDAMR5JG4WOP2xCSL2VIAqufB5Yz71BdMd8IwakpqUMjnHrY00VsE',
                    imagerySet: 'Aerial',
                },
                defaultVisibility: baseMap === MAP_LAYER_NAMES.BING,
            },
            [MAP_LAYER_NAMES.FROST]: {
                type: LAYER_TYPES.TILE,
                source: PREDEFINED_LAYERS.XYZ,
                sourceSettings: {
                    url: `/api/layers/tiles/frostMap/{z}/{x}/{y}/image.png?token=${user.token}`,
                    maxZoom: 16,
                },
                defaultVisibility: controllerState[CONTROLLER_KEYS.FROST].includes(
                    CONTROLLER_KEYS.FROST_DAMAGE_LEVEL
                ),
            },
            [MAP_LAYER_NAMES.DEEP_MAP]: {
                type: LAYER_TYPES.TILE,
                source: PREDEFINED_LAYERS.XYZ,
                sourceSettings: {
                    url: `/api/layers/tiles/deepMap/{z}/{x}/{y}/image.png?token=${user.token}`,
                    maxZoom: 16,
                },
                defaultVisibility: controllerState[CONTROLLER_KEYS.FROST].includes(
                    CONTROLLER_KEYS.DEEP_MAP
                ),
            },
            [MAP_LAYER_NAMES.NDVI]: {
                type: LAYER_TYPES.WMS,
                source: NdviDataprovider,
                defaultVisibility: controllerState[CONTROLLER_KEYS.MONITORING].includes(
                    CONTROLLER_KEYS.NDVI
                ),
                initialLayerData: {
                    isLayerVisible: controllerState[CONTROLLER_KEYS.MONITORING].includes(
                        CONTROLLER_KEYS.NDVI
                    ),
                    date: new Date(),
                    layerStringCode: '',
                    layerStringDate: '',
                },
            },
            [MAP_LAYER_NAMES.CHANGE_MAP]: {
                type: LAYER_TYPES.WMS,
                source: ChangeMapDataprovider,
                defaultVisibility: controllerState[CONTROLLER_KEYS.MONITORING].includes(
                    CONTROLLER_KEYS.CHANGE_MAP
                ),
                initialLayerData: {
                    isLayerVisible: controllerState[CONTROLLER_KEYS.MONITORING].includes(
                        CONTROLLER_KEYS.CHANGE_MAP
                    ),
                    date: new Date(),
                    layerStringCode: '',
                    layerStringDate: '',
                },
            },
            [MAP_LAYER_NAMES.DAMAGE_MAP]: {
                type: LAYER_TYPES.WMS,
                source: DamageMapDataprovider,
                defaultVisibility: false,
                initialLayerData: {
                    isLayerVisible: false,
                    date: new Date(),
                    layerStringCode: '',
                    layerStringDate: '',
                },
            },
            [MAP_LAYER_NAMES.FROST_DAMAGE_CHRONOLOGY]: {
                type: LAYER_TYPES.VECTOR,
                source: FrostDamageChronologyDataprovider,
                defaultVisibility: controllerState[CONTROLLER_KEYS.DAMAGE_CHRONOLOGY].includes(
                    CONTROLLER_KEYS.FROST_DAMAGE_CHRONOLOGY
                ),
                initialLayerData: {
                    isLayerVisible: controllerState[CONTROLLER_KEYS.DAMAGE_CHRONOLOGY].includes(
                        CONTROLLER_KEYS.FROST_DAMAGE_CHRONOLOGY
                    ),
                },
            },
            [MAP_LAYER_NAMES.FROST_RISK]: {
                type: LAYER_TYPES.VECTOR,
                source: FrostMeparDataprovider,
                defaultVisibility: controllerState[CONTROLLER_KEYS.FROST].includes(
                    CONTROLLER_KEYS.FROST_RISK
                ),
                initialLayerData: {
                    isLayerVisible: controllerState[CONTROLLER_KEYS.FROST].includes(
                        CONTROLLER_KEYS.FROST_RISK
                    ),
                },
            },
            [MAP_LAYER_NAMES.CROP]: {
                type: LAYER_TYPES.VECTOR,
                source: CropDataprovider,
                defaultVisibility: controllerState[CONTROLLER_KEYS.CROP]?.length > 0,
                initialLayerData: {
                    selectedCrops:
                        controllerState[CONTROLLER_KEYS.CROP]?.map((e) => crops[e]) || [],
                    isLayerVisible: controllerState[CONTROLLER_KEYS.CROP]?.length > 0,
                },
            },
            [MAP_LAYER_NAMES.DAMAGE_CHRONOLOGY]: {
                type: LAYER_TYPES.VECTOR,
                source: DamageChronologyDataprovider,
                defaultVisibility: controllerState[CONTROLLER_KEYS.DAMAGE_CHRONOLOGY].includes(
                    CONTROLLER_KEYS.DAMAGE_CHRONOLOGY_SUM
                ),
                initialLayerData: {
                    isLayerVisible: controllerState[CONTROLLER_KEYS.DAMAGE_CHRONOLOGY].includes(
                        CONTROLLER_KEYS.DAMAGE_CHRONOLOGY_SUM
                    ),
                },
            },
            [MAP_LAYER_NAMES.YIELD_MAP]: {
                type: LAYER_TYPES.VECTOR,
                source: YieldDataprovider,
                defaultVisibility:
                    controllerState[CONTROLLER_KEYS.YIELD_REFERENCE]?.isLayerVisible ||
                    controllerState[CONTROLLER_KEYS.YIELD_CALCULATED]?.isLayerVisible ||
                    false,
                initialLayerData: {
                    isLayerVisible:
                        !!controllerState[CONTROLLER_KEYS.YIELD_REFERENCE] ||
                        !!controllerState[CONTROLLER_KEYS.YIELD_CALCULATED] ||
                        false,
                    type:
                        controllerState[CONTROLLER_KEYS.YIELD_REFERENCE]?.type ||
                        controllerState[CONTROLLER_KEYS.YIELD_CALCULATED]?.type ||
                        '',
                    plant:
                        YIELD_PLANT_OPTIONS[
                            controllerState[CONTROLLER_KEYS.YIELD_REFERENCE]?.plant
                        ] ||
                        YIELD_PLANT_OPTIONS[
                            controllerState[CONTROLLER_KEYS.YIELD_CALCULATED]?.plant
                        ] ||
                        '',
                },
            },
            [MAP_LAYER_NAMES.CITY]: {
                type: LAYER_TYPES.VECTOR,
                source: CityDataprovider,
                defaultVisibility:
                    controllerState[CONTROLLER_KEYS.CITIES].includes(CONTROLLER_KEYS.CITIES) ||
                    false,
                initialLayerData: {
                    isLayerVisible: controllerState[CONTROLLER_KEYS.CITIES].includes(
                        CONTROLLER_KEYS.CITIES
                    ),
                },
            },
            [MAP_LAYER_NAMES.ZONE]: {
                type: LAYER_TYPES.VECTOR,
                source: ZoneDataprovider,
                defaultVisibility: false,
                initialLayerData: {
                    geoms: [],
                },
            },
            [MAP_LAYER_NAMES.SAMPLING]: {
                type: LAYER_TYPES.VECTOR,
                source: SamplingDataprovider,
                defaultVisibility: false,
                initialLayerData: {
                    geoms: [],
                },
            },
            [MAP_LAYER_NAMES.KARNAVI]: {
                type: LAYER_TYPES.VECTOR,
                source: KarnaviPhotoDataprovider,
                defaultVisibility: controllerState[CONTROLLER_KEYS.KARNAVI_PHOTOS].includes(
                    CONTROLLER_KEYS.KARNAVI_PHOTOS
                ),
                initialLayerData: {
                    geoms: [],
                    isLayerVisible: controllerState[CONTROLLER_KEYS.KARNAVI_PHOTOS].includes(
                        CONTROLLER_KEYS.KARNAVI_PHOTOS
                    ),
                },
            },
            [MAP_LAYER_NAMES.RADAR]: {
                type: LAYER_TYPES.VECTOR,
                source: RadarDataprovider,
                defaultVisibility: controllerState[CONTROLLER_KEYS.RADAR].includes(
                    CONTROLLER_KEYS.RADAR
                ),
                initialLayerData: {
                    isLayerVisible: controllerState[CONTROLLER_KEYS.RADAR].includes(
                        CONTROLLER_KEYS.RADAR
                    ),
                    date: new Date(year, getMonth(today), today.getDate()),
                },
            },
            [MAP_LAYER_NAMES.RADAR_MEPAR]: {
                type: LAYER_TYPES.VECTOR,
                source: RadarMeparDataprovider,
                defaultVisibility: controllerState[CONTROLLER_KEYS.RADAR].includes(
                    CONTROLLER_KEYS.RADAR
                ),
                initialLayerData: {
                    isLayerVisible: controllerState[CONTROLLER_KEYS.RADAR].includes(
                        CONTROLLER_KEYS.RADAR
                    ),
                    date: new Date(),
                },
            },
            [MAP_LAYER_NAMES.MEPAR]: {
                type: LAYER_TYPES.VECTOR,
                source: MeparDataprovider,
                defaultVisibility: false,
                initialLayerData: {
                    codes: [],
                    connecting: true,
                },
            },
            [MAP_LAYER_NAMES.FIELDS]: {
                type: LAYER_TYPES.VECTOR,
                source: FieldDataprovider,
                defaultVisibility: false,
                initialLayerData: {
                    geoms: [],
                },
            },
        };
    }, []);
    return (
        <>
            {legendOpen && <MapLegend bottom={legendFromBottom} />}
            <Map layers={getMapLayerDefinition()} />
            <GeolocationButton />
            <DrawControllerWrapper
                legendOpen={legendOpen}
                onToggleLegend={onToggleLegend}
                legendFromBottom={legendFromBottom}
            />
            <BaseMapController bottom={legendFromBottom} />
        </>
    );
};

export default MapWrapper;
