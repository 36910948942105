import { Style } from 'es-map-widget';
import { useCallback, useEffect } from 'react';
import { cropNumbers } from '../../constants';
import { api } from '../../hooks/useApi';
import { useAppControl } from '../../providers/AppContext';
import { styles } from '../mapwrapper/MapWrapperStyles';

export default function CropDataprovider({ extent, layerData, setSource }) {
    const { year } = useAppControl();

    const getLayer = useCallback(
        async (controller, params) =>
            api.layers
                .getCropMapLayer(params, { signal: controller.signal })
                .then((res) => res.data),
        [api]
    );

    const addStyleToGeoms = (geoms) =>
        geoms.map((e) => ({
            ...e,
            style: new Style({
                ...styles[cropNumbers[e.crop]],
            }),
        }));

    useEffect(() => {
        if (!layerData.isLayerVisible) {
            setSource(null);
            return;
        }

        const controller = new AbortController();

        getLayer(controller, {
            extent,
            types: layerData.selectedCrops,
            year,
        })
            .then((newcropLayer) => setSource(addStyleToGeoms(newcropLayer)))
            .catch((err) => {
                if (err.code !== 'ERR_CANCELED') {
                    console.error(err);
                }
            });

        // eslint-disable-next-line consistent-return
        return () => {
            controller.abort();
        };
    }, [getLayer, extent, layerData, addStyleToGeoms, setSource, year]);
    return null;
}
