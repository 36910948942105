import { useMediaQuery } from '@mui/material';
import React, { createContext, useContext, useMemo, useState } from 'react';

const LayoutContext = createContext();
LayoutContext.displayName = 'LayoutContext';

export function LayoutContextProvider({ children }) {
    const [selectedProduct, setSelectedProduct] = useState(null);
    const matchesLgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    const contextValue = useMemo(
        () => ({
            selectedProduct,
            setSelectedProduct,
            matchesLgUp,
        }),
        [selectedProduct, setSelectedProduct, matchesLgUp]
    );

    return <LayoutContext.Provider value={contextValue}>{children}</LayoutContext.Provider>;
}

export function useLayout() {
    return useContext(LayoutContext);
}
