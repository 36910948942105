import { Map } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import React, { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import LoadingIconButton from '../../../components/atom/LoadingIconButton';
import { MODAL_TYPE, TOOLTIP_TEXTS } from '../../../constants';
import { api } from '../../../hooks/useApi';
import { useModals } from '../../../hooks/useModal';
import useNotify from '../../../hooks/useNotify';
import { useDrawContext } from '../../../providers/DrawContextProvider';

const ZoneGenerationButton = ({ disabled }) => {
    const { notifySuccess, notifyError } = useNotify();
    const { isGeneratingSamples, setIsGeneratingSamples } = useDrawContext();
    const { showModal } = useModals();
    const [searchParams] = useSearchParams();
    const damagePlotId = searchParams.get('damagePlotId');

    const generateZones = useCallback(() => {
        setIsGeneratingSamples(true);
        api.damagePlots
            .generateZones(damagePlotId)
            .then(() => {
                notifySuccess('Sikeres zónagenerálás');
                setIsGeneratingSamples(false);
            })
            .catch((err) => {
                console.log(err);
                notifyError(err);
                setIsGeneratingSamples(false);
            });
    }, [setIsGeneratingSamples, notifySuccess, damagePlotId, api]);

    const handleClick = () =>
        showModal(MODAL_TYPE.CONFIRM_MODAL, {
            onClick: () => generateZones(),
            title: 'Mintavételi zónák generálása',
            body: 'A jelenlegi zónák törlődni fognak! Az új zónák generálása hosszabb ideig is eltarthat a háttérben. Biztosan új mintavételi zónákat szeretne generálni?',
        });

    return (
        <Tooltip title={TOOLTIP_TEXTS.generateZones} placement="right">
            <span>
                <LoadingIconButton
                    icon={<Map />}
                    disabled={disabled}
                    onClick={handleClick}
                    loading={isGeneratingSamples}
                />
            </span>
        </Tooltip>
    );
};

export default ZoneGenerationButton;
