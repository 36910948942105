import { getYear } from 'date-fns';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DRAWER_STATES } from '../constants';
import { DRAWING_STATES } from '../constants/drawingStates';
import { OVERVIEW_STATES } from '../constants/overviewStates';

const AppContext = createContext();
AppContext.displayName = 'AppContext';

export function AppContextProvider({ children }) {
    const [isSearchVisible, setIsSearchVisible] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const getYearFromSearch = useCallback(() => {
        const yearFromSearch = searchParams.get('year');
        if (yearFromSearch !== null && !Number.isNaN(parseInt(yearFromSearch, 10))) {
            return parseInt(yearFromSearch, 10);
        }
        return false;
    }, [searchParams]);

    const [year, setYear] = useState(() => getYearFromSearch() || getYear(new Date()));
    const [overviewState, setOverviewState] = useState(OVERVIEW_STATES.CLOSED);
    const [drawingState, setDrawingState] = useState(DRAWING_STATES.NONE);
    const [adminDrawerState, setAdminDrawerState] = useState(DRAWER_STATES.CLOSED);
    const [samplingData, setSamplingData] = useState({
        points: [],
        zones: [],
    });

    useEffect(() => {
        const params = {};
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of searchParams.entries()) {
            params[key] = value;
        }
        setSearchParams({ ...params, year }, { replace: true });
    }, [year, setSearchParams, searchParams]);

    const contextValue = useMemo(
        () => ({
            year,
            setYear,
            overviewState,
            adminDrawerState,
            setOverviewState,
            drawingState,
            setDrawingState,
            setAdminDrawerState,
            isSearchVisible,
            setIsSearchVisible,
            samplingData,
            setSamplingData,
        }),
        [
            year,
            setYear,
            overviewState,
            setOverviewState,
            drawingState,
            setDrawingState,
            adminDrawerState,
            setAdminDrawerState,
            isSearchVisible,
            setIsSearchVisible,
            samplingData,
            setSamplingData,
        ]
    );

    return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
}

export function useAppControl() {
    return useContext(AppContext);
}
